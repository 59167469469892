export const externalLinks = {
  FeedbackLink: 'https://feedback.mongodb.com/',
  GitHubWebhooks: 'https://docs.github.com/en/developers/webhooks-and-events/webhooks/webhook-events-and-payloads',
  GoogleOpenId: 'https://developers.google.com/identity/protocols/oauth2/openid-connect',
  SetupGoogleSignIn: 'https://developers.google.com/identity/sign-in/web/sign-in',
  StitchSDK: 'https://s3.amazonaws.com/stitch-sdks/js/bundles/4.6.0/stitch.js',
  TwilioDocs: 'https://www.twilio.com/docs/sms/twiml',
};

export const docLinks = {
  Atlas: {
    AutoScaling: 'https://docs.atlas.mongodb.com/cluster-autoscaling/#how-auto-scale-works',
    LoadSampleData: 'https://docs.atlas.mongodb.com/sample-data/',
    UserRoles: 'https://docs.atlas.mongodb.com/reference/user-roles',
  },
  Authentication: {
    Anonymous: 'https://www.mongodb.com/docs/atlas/app-services/authentication/anonymous/',
    Apple: 'https://www.mongodb.com/docs/atlas/app-services/authentication/apple/',
    ConfirmFunction:
      'https://www.mongodb.com/docs/atlas/app-services/authentication/email-password/#run-a-confirmation-function',
    CustomFunctions: 'https://www.mongodb.com/docs/atlas/app-services/authentication/custom-function/#overview',
    Google: 'https://www.mongodb.com/docs/atlas/app-services/authentication/google',
    JWTAudience: 'https://www.mongodb.com/docs/atlas/app-services/authentication/custom-jwt/index.html#audience',
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/authentication/providers/',
    MetadataFields:
      'https://www.mongodb.com/docs/atlas/app-services/authentication/custom-jwt/index.html#metadata-fields',
    ResetPasswordFunction:
      'https://www.mongodb.com/docs/atlas/app-services/authentication/email-password/#run-a-password-reset-function',
  },
  DataAPI: {
    LearnMore: 'https://www.mongodb.com/docs/atlas/api/data-api/',
    Versions: 'https://www.mongodb.com/docs/atlas/app-services/data-api/generated-endpoints/#api-versions',
    CallGeneratedEndpoint:
      'https://www.mongodb.com/docs/atlas/app-services/data-api/generated-endpoints/#call-a-data-api-endpoint',
  },
  Deploy: {
    ApplicationConfig: 'https://www.mongodb.com/docs/atlas/app-services/manage-apps/configure/config/',
    FunctionConfig: 'https://www.mongodb.com/docs/atlas/app-services/manage-apps/configure/config/functions/',
    CLIExport: 'https://www.mongodb.com/docs/atlas/app-services/manage-apps/configure/export/export-with-cli/',
    CLIUsage: 'https://www.mongodb.com/docs/atlas/app-services/cli/',
    CLIDownload: 'https://www.mongodb.com/try/download/app-services-cli',
    History: 'https://www.mongodb.com/docs/atlas/app-services/manage-apps/deploy/#deployment-history',
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/manage-apps/deploy/',
    DeployUI: 'https://www.mongodb.com/docs/atlas/app-services/manage-apps/deploy/manual/deploy-ui/',
    RollBackDeployments:
      'https://www.mongodb.com/docs/atlas/app-services/manage-apps/deploy/manual/rollback-deployment/',
  },
  Edge: {
    Upgrade: 'https://www.mongodb.com/docs/atlas/app-services/edge-server/configure/#upgrade-the-edge-server-version',
    InstallAndConfigure:
      'https://www.mongodb.com/docs/atlas/app-services/edge-server/configure/#install-and-configure-the-edge-server-instance',
    PendingEdgeServer:
      'https://www.mongodb.com/docs/atlas/app-services/edge-server/manage-edge-servers/#pending-edge-server-instances',
    EdgePermissions: 'https://www.mongodb.com/docs/atlas/app-services/edge-server/permissions/',
  },
  Endpoints: {
    Configure:
      'https://www.mongodb.com/docs/atlas/app-services/data-api/custom-endpoints/#create-a-custom-https-endpoint',
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/data-api/custom-endpoints/',
    ReturnType: 'https://www.mongodb.com/docs/atlas/app-services/data-api/generated-endpoints/#response-type',
  },
  Functions: {
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/functions/',
    UploadDependencies: 'https://www.mongodb.com/docs/atlas/app-services/functions/dependencies/',
    Context: 'https://www.mongodb.com/docs/atlas/app-services/functions/context/#context-http',
    NodeLibSupport: 'https://www.mongodb.com/docs/atlas/app-services/functions/javascript-support/',
  },
  General: {
    AdminApi: 'https://www.mongodb.com/docs/atlas/app-services/admin/api/v3/',
    AppLogs: 'https://www.mongodb.com/docs/atlas/app-services/logs',
    AppServices: 'https://www.mongodb.com/docs/atlas/app-services',
    AtlasOverview: 'https://www.mongodb.com/docs/atlas/app-services/mongodb',
    Billing: 'https://www.mongodb.com/docs/atlas/app-services/billing',
    DeploymentModels:
      'https://www.mongodb.com/docs/atlas/app-services/manage-apps/deploy/deployment-models-and-regions/#deployment-models',
    Tutorials: 'https://www.mongodb.com/docs/atlas/app-services/get-started/#tutorials',
    Migrate: 'https://www.mongodb.com/docs/atlas/device-sdks/migrate/',
    Support: 'https://www.mongodb.com/support',
    DeploymentMigration: 'https://www.mongodb.com/docs/atlas/app-services/apps/change-deployment-models/',
    DomainMigration: {
      LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/domain-migration',
      SDKVersions: 'https://www.mongodb.com/docs/atlas/app-services/domain-migration/#relevant-sdk-versions',
    },
    MigrateHostingGQL: 'https://www.mongodb.com/docs/atlas/app-services/migrate-hosting-graphql/',
  },
  Glossary: {
    FilterQueries: 'https://www.mongodb.com/docs/atlas/app-services/mongodb/filter-incoming-queries/',
    JSONExpressions: 'https://www.mongodb.com/docs/atlas/app-services/rules/expressions/#expression-syntax',
    UserExpansion: 'https://www.mongodb.com/docs/atlas/app-services/rules/expressions/#user-expansions',
  },
  GraphQL: {
    CustomResolvers: 'https://www.mongodb.com/docs/atlas/app-services/graphql/custom-resolvers/',
    GetAuthToken: 'https://www.mongodb.com/docs/atlas/app-services/graphql/authenticate/',
    GraphQLOperations: 'https://www.mongodb.com/docs/atlas/app-services/graphql/#graphql-operations',
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/graphql/',
    Relationships:
      'https://www.mongodb.com/docs/atlas/app-services/graphql/expose-data/#3.-define-relationships-to-other-collections',
  },
  Hosting: {
    Custom404: 'https://www.mongodb.com/docs/atlas/app-services/hosting/use-a-custom-404-page/',
    CustomDomain: 'https://www.mongodb.com/docs/atlas/app-services/hosting/use-a-custom-domain-name/',
    CustomDomainCNAME:
      'https://www.mongodb.com/docs/atlas/app-services/hosting/use-a-custom-domain-name/#add-a-validation-cname-record',
    DefaultDomain: 'https://www.mongodb.com/docs/atlas/app-services/hosting/#hosting-domain',
    FlushCache: 'https://www.mongodb.com/docs/atlas/app-services/hosting/flush-the-cdn-cache/',
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/hosting/',
    SPA: 'https://www.mongodb.com/docs/atlas/app-services/hosting/host-a-single-page-application/',
  },
  Legacy: {
    ConvertToPromises: 'http://stitch-docs-old.s3-website-us-east-1.amazonaws.com/convert-to-promises',
  },
  LogForwarder: {
    Configure: 'https://www.mongodb.com/docs/atlas/app-services/logs/forward-logs/',
    Restart: 'https://www.mongodb.com/docs/atlas/app-services/logs/forward-logs/#restart-a-suspended-log-forwarder',
  },
  Metrics: {
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/reference/metrics/#app-services-metrics-reference',
  },
  MongoDB: {
    CRUDOperations: 'https://www.mongodb.com/docs/atlas/app-services/mongodb/crud-and-aggregation-apis/',
    DocumentSchema: 'https://www.mongodb.com/docs/atlas/app-services/schemas/',
    EnforceSchema: 'https://www.mongodb.com/docs/atlas/app-services/schemas/enforce-a-schema/',
    GenerateSchema: 'https://www.mongodb.com/docs/atlas/app-services/schemas/enforce-a-schema/#generate-a-schema',
    SchemaRelationships: 'https://www.mongodb.com/docs/atlas/app-services/schemas/relationships/',
    RolesPermissions: 'https://www.mongodb.com/docs/atlas/app-services/mongodb/define-roles-and-permissions/',
    RuleTemplates:
      'https://www.mongodb.com/docs/atlas/app-services/mongodb/define-roles-and-permissions/#select-a-collection-rule-template',
    Watch: 'https://www.mongodb.com/docs/atlas/device-sdks/web/mongodb/#watch-for-changes',
    WireProtocol: 'https://www.mongodb.com/docs/atlas/app-services/mongodb/wire-protocol/',
  },
  MongoDBCore: {
    ChangeEvents: 'https://www.mongodb.com/docs/manual/reference/change-events',
    ExtendedJSON: 'https://docs.mongodb.com/manual/reference/mongodb-extended-json/',
    MatchAggregation: 'https://docs.mongodb.com/manual/reference/operator/aggregation/match/#pipe._S_match',
    ProjectAggregation: 'https://docs.mongodb.com/manual/reference/operator/aggregation/project/#pipe._S_project',
    Projections: 'https://www.mongodb.com/docs/manual/tutorial/project-fields-from-query-results',
    Query: 'https://www.mongodb.com/docs/manual/tutorial/query-documents',
    ReadPreference: 'https://www.mongodb.com/docs/manual/core/read-preference',
    ReadPreferenceTags: 'https://www.mongodb.com/docs/manual/core/read-preference-tags',
    ResumeChangeStream: 'https://www.mongodb.com/docs/manual/changeStreams/#resume-a-change-stream',
    Index: 'https://www.mongodb.com/docs/manual/indexes/',
  },
  Users: {
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/users',
    Sessions: 'https://www.mongodb.com/docs/atlas/app-services/users/sessions/#manage-user-sessions',
    Create: 'https://www.mongodb.com/docs/atlas/app-services/users/create/#create-an-email-password-user',
    CustomData: 'https://www.mongodb.com/docs/atlas/app-services/users/enable-custom-user-data/',
    ResetPassword: 'https://www.mongodb.com/docs/atlas/app-services/authentication/email-password/#password-resets',
    SystemUser: 'https://www.mongodb.com/docs/atlas/app-services/authentication/#system-user',
    UserObjects: 'https://www.mongodb.com/docs/atlas/app-services/authentication/user-objects',
  },
  CloudProviderPrivateEndpoints: {
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/security/private-endpoints/',
  },
  SDKs: {
    Android: {
      Intro: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/java/',
      QuickStart: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/java/quick-starts/quick-start-local/',
    },
    DotNet: {
      Intro: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/dotnet/',
      QuickStart: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/dotnet/quick-start/',
    },
    CPP: {
      Intro: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/cpp/',
      QuickStart: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/cpp/quick-start/',
    },
    Flutter: {
      Intro: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/flutter/',
      QuickStart: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/flutter/quick-start/',
    },
    iOS: {
      Intro: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/swift/',
      QuickStart: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/swift/quick-start/',
    },
    Node: {
      Intro: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/node/',
      QuickStart: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/node/quick-start/',
    },
    ReactNative: {
      Intro: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/react-native/',
      QuickStart: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/react-native/quick-start/',
    },
    Kotlin: {
      Intro: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/kotlin/',
      QuickStart: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/kotlin/quick-start/',
    },
    Web: {
      CallFunction: 'https://www.mongodb.com/docs/atlas/device-sdks/web/call-a-function/',
      Intro: 'https://www.mongodb.com/docs/atlas/device-sdks/web/',
      MongoDB: 'https://www.mongodb.com/docs/atlas/device-sdks/web/mongodb/',
      QuickStart: 'https://www.mongodb.com/docs/atlas/device-sdks/web/quickstart/',
    },
    LearnMore: 'https://www.mongodb.com/docs/atlas/device-sdks/sdk/',
  },
  Secrets: {
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/values-and-secrets/#secret',
  },
  Security: {
    AccessList: 'https://www.mongodb.com/docs/atlas/app-services/security/#ip-access-list',
  },
  Services: {
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/reference/services/',
  },
  Sync: {
    DataIngest: 'https://www.mongodb.com/docs/atlas/app-services/sync/configure/sync-settings/#data-ingest',
    BestPractices:
      'https://www.mongodb.com/docs/atlas/app-services/sync/go-to-production/production-checklist/#performance-best-practices',
    BreakingChanges: 'https://www.mongodb.com/docs/atlas/app-services/sync/configure/sync-settings/#breaking-changes',
    ClientReset: 'https://www.mongodb.com/docs/atlas/app-services/sync/error-handling/client-resets/',
    ConfigureDataModel: 'https://www.mongodb.com/docs/atlas/app-services/sync/configure-your-data-model/',
    ConfigureSync: 'https://www.mongodb.com/docs/atlas/app-services/sync/configure-device-sync/',
    DefineReadWritePermissions:
      'https://www.mongodb.com/docs/atlas/app-services/rules/roles/#std-label-sync-permissions',
    DeveloperMode: 'https://www.mongodb.com/docs/atlas/app-services/sync/configure/sync-settings/#development-mode',
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/sync/',
    Overview: 'https://www.mongodb.com/docs/atlas/app-services/sync/learn/overview/',
    PartitionKeys: 'https://www.mongodb.com/docs/atlas/app-services/reference/partition-based-sync/#partition-key',
    MaxOfflineTime:
      'https://www.mongodb.com/docs/atlas/app-services/sync/go-to-production/optimize-sync-atlas-usage/#std-label-client-maximum-offline-time',
    QueryableFields: 'https://www.mongodb.com/docs/atlas/app-services/sync/configure/sync-settings/#queryable-fields',
    IndexedQueryableFields:
      'https://www.mongodb.com/docs/atlas/app-services/sync/configure/sync-settings/#indexed-queryable-fields',
    SyncMode: 'https://www.mongodb.com/docs/atlas/app-services/sync/configure/sync-settings/#sync-type',
    Migration: 'https://www.mongodb.com/docs/atlas/app-services/sync/migrate-sync-modes/',
    MappingWithDevMode:
      'https://www.mongodb.com/docs/atlas/app-services/sync/data-model/data-model-map/#mapping-with-development-mode',
  },
  Rules: {
    AdvancedRules: 'https://www.mongodb.com/docs/atlas/app-services/mongodb/configure-advanced-rules/',
    DefaultRule: 'https://www.mongodb.com/docs/atlas/app-services/rules/#default-rules',
    Expressions: 'https://www.mongodb.com/docs/atlas/app-services/rules/expressions/',
    Filters: 'https://www.mongodb.com/docs/atlas/app-services/rules/filters/',
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/rules/',
    FieldPermissions: 'https://www.mongodb.com/docs/atlas/app-services/rules/roles/#field-level-permissions',
    Roles: 'https://www.mongodb.com/docs/atlas/app-services/rules/roles/',
    RolesFlexibleSync: 'https://www.mongodb.com/docs/atlas/app-services/rules/roles/#with-device-sync',
    RolesOrder: 'https://www.mongodb.com/docs/atlas/app-services/rules/roles/#role-order',
    SyncCompatibility: 'https://www.mongodb.com/docs/atlas/app-services/rules/sync-compatibility/',
  },
  Templates: {
    GetStarted: 'https://www.mongodb.com/docs/atlas/app-services/get-started/',
    Swift: 'https://www.mongodb.com/docs/atlas/app-services/tutorial/swiftui/',
    Kotlin: 'https://www.mongodb.com/docs/atlas/app-services/tutorial/kotlin/',
    CSharp: 'https://www.mongodb.com/docs/atlas/app-services/tutorial/dotnet/',
    Javascript: 'https://www.mongodb.com/docs/atlas/app-services/tutorial/react-native/',
    Dart: 'https://www.mongodb.com/docs/atlas/app-services/tutorial/flutter/',
  },
  Triggers: {
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/triggers/',
    DisablingPreimagesLearnMore:
      'https://www.mongodb.com/docs/atlas/app-services/mongodb/preimages/#std-label-disable-collection-preimages',
    DatabaseEvents:
      'https://www.mongodb.com/docs/atlas/app-services/triggers/database-triggers/#database-change-events',
    DatabaseTriggerEventOrdering:
      'https://www.mongodb.com/docs/atlas/app-services/triggers/database-triggers/#trigger-details',
    DatabaseTriggerRecursionWarning:
      'https://www.mongodb.com/docs/atlas/app-services/triggers/database-triggers/#std-label-trigger-recursion',
    EventBridgeCustomErrorHandling:
      'https://www.mongodb.com/docs/atlas/app-services/triggers/aws-eventbridge/#custom-error-handling',
    MaximumThroughput:
      'https://www.mongodb.com/docs/atlas/app-services/triggers/database-triggers/#std-label-triggers-maximum-throughput',
    WatchAgainst: 'https://www.mongodb.com/docs/manual/changeStreams/#watch-a-collection--database--or-deployment',
  },
  Values: {
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/values-and-secrets/',
  },
  Webhooks: {
    ConfigureServiceWebhooks: 'https://www.mongodb.com/docs/atlas/app-services/services/configure/service-webhooks/',
    LearnMore: 'https://www.mongodb.com/docs/atlas/app-services/services/http/#incoming-webhooks',
    PayloadSignature:
      'https://www.mongodb.com/docs/atlas/app-services/services/webhook-requests-and-responses/#payload-signature-verification',
  },
};
