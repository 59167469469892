var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Any, JsonConverter, JsonObject, JsonProperty } from 'json2typescript';
import createStringMapSerializers from 'admin-sdk/api/common/createStringMapSerializers';
import { DateConverter } from 'admin-sdk/Converter';
let FunctionCallLogItem = class FunctionCallLogItem {
    name = '';
    arguments = undefined;
    service = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('name')
], FunctionCallLogItem.prototype, "name", void 0);
__decorate([
    JsonProperty('arguments', Any, true)
], FunctionCallLogItem.prototype, "arguments", void 0);
__decorate([
    JsonProperty('service', String, true)
], FunctionCallLogItem.prototype, "service", void 0);
FunctionCallLogItem = __decorate([
    JsonObject('FunctionCallLogItem')
], FunctionCallLogItem);
export { FunctionCallLogItem };
export var AuthEventType;
(function (AuthEventType) {
    AuthEventType["Login"] = "login";
    AuthEventType["Logout"] = "logout";
    AuthEventType["Other"] = "other";
})(AuthEventType || (AuthEventType = {}));
let AuthEvent = class AuthEvent {
    failed = undefined;
    type = AuthEventType.Other;
    provider = '';
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('failed', Boolean, true)
], AuthEvent.prototype, "failed", void 0);
__decorate([
    JsonProperty('type', Any, true)
], AuthEvent.prototype, "type", void 0);
__decorate([
    JsonProperty('provider')
], AuthEvent.prototype, "provider", void 0);
AuthEvent = __decorate([
    JsonObject('AuthEvent')
], AuthEvent);
export { AuthEvent };
let RoleMetrics = class RoleMetrics {
    matchingDocuments = 0;
    evaluatedFields = 0;
    discardedFields = 0;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('matching_documents')
], RoleMetrics.prototype, "matchingDocuments", void 0);
__decorate([
    JsonProperty('evaluated_fields')
], RoleMetrics.prototype, "evaluatedFields", void 0);
__decorate([
    JsonProperty('discarded_fields')
], RoleMetrics.prototype, "discardedFields", void 0);
RoleMetrics = __decorate([
    JsonObject('RoleMetrics')
], RoleMetrics);
export { RoleMetrics };
const roleMetricsMapSerializers = createStringMapSerializers(RoleMetrics);
let RoleMetricsMapConverter = class RoleMetricsMapConverter {
    serialize = roleMetricsMapSerializers.serialize;
    deserialize = roleMetricsMapSerializers.deserialize;
};
RoleMetricsMapConverter = __decorate([
    JsonConverter
], RoleMetricsMapConverter);
let NamespaceMetrics = class NamespaceMetrics {
    roles = {};
    noMatchingRole = 0;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('roles', RoleMetricsMapConverter, true)
], NamespaceMetrics.prototype, "roles", void 0);
__decorate([
    JsonProperty('no_matching_role')
], NamespaceMetrics.prototype, "noMatchingRole", void 0);
NamespaceMetrics = __decorate([
    JsonObject('NamespaceMetrics')
], NamespaceMetrics);
export { NamespaceMetrics };
const namespaceMetricsMapSerializers = createStringMapSerializers(NamespaceMetrics);
let NamespaceMetricsMapConverter = class NamespaceMetricsMapConverter {
    serialize = namespaceMetricsMapSerializers.serialize;
    deserialize = namespaceMetricsMapSerializers.deserialize;
};
NamespaceMetricsMapConverter = __decorate([
    JsonConverter
], NamespaceMetricsMapConverter);
let RuleMetrics = class RuleMetrics {
    namespacesMetrics = {};
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('namespaces_metrics', NamespaceMetricsMapConverter)
], RuleMetrics.prototype, "namespacesMetrics", void 0);
RuleMetrics = __decorate([
    JsonObject('RuleMetrics')
], RuleMetrics);
export { RuleMetrics };
let SyncSessionMetrics = class SyncSessionMetrics {
    uploads = undefined;
    downloads = undefined;
    errors = undefined;
    changesets = undefined;
    dirtyChangesets = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('uploads', Number, true)
], SyncSessionMetrics.prototype, "uploads", void 0);
__decorate([
    JsonProperty('downloads', Number, true)
], SyncSessionMetrics.prototype, "downloads", void 0);
__decorate([
    JsonProperty('errors', Number, true)
], SyncSessionMetrics.prototype, "errors", void 0);
__decorate([
    JsonProperty('changesets', Number, true)
], SyncSessionMetrics.prototype, "changesets", void 0);
__decorate([
    JsonProperty('dirty_changesets', Number, true)
], SyncSessionMetrics.prototype, "dirtyChangesets", void 0);
SyncSessionMetrics = __decorate([
    JsonObject('SyncSessionMetrics')
], SyncSessionMetrics);
export { SyncSessionMetrics };
let ModifiedDocIDsByOpType = class ModifiedDocIDsByOpType {
    inserted = undefined;
    updated = undefined;
    deleted = undefined;
    replaced = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('inserted', Any, true)
], ModifiedDocIDsByOpType.prototype, "inserted", void 0);
__decorate([
    JsonProperty('updated', Any, true)
], ModifiedDocIDsByOpType.prototype, "updated", void 0);
__decorate([
    JsonProperty('deleted', Any, true)
], ModifiedDocIDsByOpType.prototype, "deleted", void 0);
__decorate([
    JsonProperty('replaced', Any, true)
], ModifiedDocIDsByOpType.prototype, "replaced", void 0);
ModifiedDocIDsByOpType = __decorate([
    JsonObject('ModifiedDocIDsByOpType')
], ModifiedDocIDsByOpType);
export { ModifiedDocIDsByOpType };
const modifiedDocIDsByOpTypeMapSerializers = createStringMapSerializers(ModifiedDocIDsByOpType);
let ModifiedDocIDsByOpTypeMapConverter = class ModifiedDocIDsByOpTypeMapConverter {
    serialize = modifiedDocIDsByOpTypeMapSerializers.serialize;
    deserialize = modifiedDocIDsByOpTypeMapSerializers.deserialize;
};
ModifiedDocIDsByOpTypeMapConverter = __decorate([
    JsonConverter
], ModifiedDocIDsByOpTypeMapConverter);
let RequestLogItem = class RequestLogItem {
    id = '';
    coId = '';
    type = '';
    requestUrl = '';
    requestMethod = '';
    userId = undefined;
    domainId = undefined;
    appId = undefined;
    groupId = undefined;
    serviceId = undefined;
    remoteIpAddress = undefined;
    started = undefined;
    completed = undefined;
    functionCallLocation = undefined;
    functionCallProviderRegion = undefined;
    functionCall = undefined;
    functionId = undefined;
    functionName = undefined;
    incomingWebhookArgs = undefined;
    incomingWebhookId = undefined;
    incomingWebhookName = undefined;
    error = undefined;
    errorCode = undefined;
    errorDetails = undefined;
    parentCoId = '';
    eventSubscriptionId = undefined;
    eventSubscriptionName = undefined;
    status = undefined;
    messages = undefined;
    authEvent = undefined;
    memTimeUsage = undefined;
    ruleMetrics = undefined;
    platform = undefined;
    platformVersion = undefined;
    sdkName = undefined;
    sdkVersion = undefined;
    frameworkName = undefined;
    frameworkVersion = undefined;
    graphqlQuery = undefined;
    syncPartition = undefined;
    syncQuery = undefined;
    syncSessionMetrics = undefined;
    syncWriteSummary = undefined;
    syncIsFromMdb = undefined;
    documentKey = undefined;
    endpointArgs = undefined;
    endpointId = undefined;
    endpointRoute = undefined;
    logForwarderID = undefined;
    logForwarderName = undefined;
    failedToForwardLogIDs = undefined;
    dataSourceID = undefined;
    dataSourceName = undefined;
    dataSourceNamespace = undefined;
    authMethod = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('_id')
], RequestLogItem.prototype, "id", void 0);
__decorate([
    JsonProperty('co_id')
], RequestLogItem.prototype, "coId", void 0);
__decorate([
    JsonProperty('type')
], RequestLogItem.prototype, "type", void 0);
__decorate([
    JsonProperty('request_url')
], RequestLogItem.prototype, "requestUrl", void 0);
__decorate([
    JsonProperty('request_method')
], RequestLogItem.prototype, "requestMethod", void 0);
__decorate([
    JsonProperty('user_id', String, true)
], RequestLogItem.prototype, "userId", void 0);
__decorate([
    JsonProperty('domain_id', String, true)
], RequestLogItem.prototype, "domainId", void 0);
__decorate([
    JsonProperty('app_id', String, true)
], RequestLogItem.prototype, "appId", void 0);
__decorate([
    JsonProperty('group_id', String, true)
], RequestLogItem.prototype, "groupId", void 0);
__decorate([
    JsonProperty('service_id', String, true)
], RequestLogItem.prototype, "serviceId", void 0);
__decorate([
    JsonProperty('remote_ip_address', String, true)
], RequestLogItem.prototype, "remoteIpAddress", void 0);
__decorate([
    JsonProperty('started', String, true)
], RequestLogItem.prototype, "started", void 0);
__decorate([
    JsonProperty('completed', String, true)
], RequestLogItem.prototype, "completed", void 0);
__decorate([
    JsonProperty('function_call_location', String, true)
], RequestLogItem.prototype, "functionCallLocation", void 0);
__decorate([
    JsonProperty('function_call_provider_region', String, true)
], RequestLogItem.prototype, "functionCallProviderRegion", void 0);
__decorate([
    JsonProperty('function_call', FunctionCallLogItem, true)
], RequestLogItem.prototype, "functionCall", void 0);
__decorate([
    JsonProperty('function_id', String, true)
], RequestLogItem.prototype, "functionId", void 0);
__decorate([
    JsonProperty('function_name', String, true)
], RequestLogItem.prototype, "functionName", void 0);
__decorate([
    JsonProperty('incoming_webhook_args', Any, true)
], RequestLogItem.prototype, "incomingWebhookArgs", void 0);
__decorate([
    JsonProperty('incoming_webhook_id', String, true)
], RequestLogItem.prototype, "incomingWebhookId", void 0);
__decorate([
    JsonProperty('incoming_webhook_name', String, true)
], RequestLogItem.prototype, "incomingWebhookName", void 0);
__decorate([
    JsonProperty('error', String, true)
], RequestLogItem.prototype, "error", void 0);
__decorate([
    JsonProperty('error_code', String, true)
], RequestLogItem.prototype, "errorCode", void 0);
__decorate([
    JsonProperty('error_details', Any, true)
], RequestLogItem.prototype, "errorDetails", void 0);
__decorate([
    JsonProperty('parent_co_id', String, true)
], RequestLogItem.prototype, "parentCoId", void 0);
__decorate([
    JsonProperty('event_subscription_id', String, true)
], RequestLogItem.prototype, "eventSubscriptionId", void 0);
__decorate([
    JsonProperty('event_subscription_name', String, true)
], RequestLogItem.prototype, "eventSubscriptionName", void 0);
__decorate([
    JsonProperty('status', Number, true)
], RequestLogItem.prototype, "status", void 0);
__decorate([
    JsonProperty('messages', Any, true)
], RequestLogItem.prototype, "messages", void 0);
__decorate([
    JsonProperty('auth_event', AuthEvent, true)
], RequestLogItem.prototype, "authEvent", void 0);
__decorate([
    JsonProperty('mem_time_usage', Number, true)
], RequestLogItem.prototype, "memTimeUsage", void 0);
__decorate([
    JsonProperty('rule_metrics', RuleMetrics, true)
], RequestLogItem.prototype, "ruleMetrics", void 0);
__decorate([
    JsonProperty('platform', String, true)
], RequestLogItem.prototype, "platform", void 0);
__decorate([
    JsonProperty('platform_version', String, true)
], RequestLogItem.prototype, "platformVersion", void 0);
__decorate([
    JsonProperty('sdk_name', String, true)
], RequestLogItem.prototype, "sdkName", void 0);
__decorate([
    JsonProperty('sdk_version', String, true)
], RequestLogItem.prototype, "sdkVersion", void 0);
__decorate([
    JsonProperty('framework_name', String, true)
], RequestLogItem.prototype, "frameworkName", void 0);
__decorate([
    JsonProperty('framework_version', String, true)
], RequestLogItem.prototype, "frameworkVersion", void 0);
__decorate([
    JsonProperty('graphql_query', String, true)
], RequestLogItem.prototype, "graphqlQuery", void 0);
__decorate([
    JsonProperty('sync_partition', String, true)
], RequestLogItem.prototype, "syncPartition", void 0);
__decorate([
    JsonProperty('sync_query', Any, true)
], RequestLogItem.prototype, "syncQuery", void 0);
__decorate([
    JsonProperty('sync_session_metrics', SyncSessionMetrics, true)
], RequestLogItem.prototype, "syncSessionMetrics", void 0);
__decorate([
    JsonProperty('sync_write_summary', ModifiedDocIDsByOpTypeMapConverter, true)
], RequestLogItem.prototype, "syncWriteSummary", void 0);
__decorate([
    JsonProperty('sync_is_from_mdb', Boolean, true)
], RequestLogItem.prototype, "syncIsFromMdb", void 0);
__decorate([
    JsonProperty('document_key', String, true)
], RequestLogItem.prototype, "documentKey", void 0);
__decorate([
    JsonProperty('endpoint_args', Any, true)
], RequestLogItem.prototype, "endpointArgs", void 0);
__decorate([
    JsonProperty('endpoint_id', String, true)
], RequestLogItem.prototype, "endpointId", void 0);
__decorate([
    JsonProperty('endpoint_route', String, true)
], RequestLogItem.prototype, "endpointRoute", void 0);
__decorate([
    JsonProperty('log_forwarder_id', String, true)
], RequestLogItem.prototype, "logForwarderID", void 0);
__decorate([
    JsonProperty('log_forwarder_name', String, true)
], RequestLogItem.prototype, "logForwarderName", void 0);
__decorate([
    JsonProperty('failed_to_forward_log_ids', [String], true)
], RequestLogItem.prototype, "failedToForwardLogIDs", void 0);
__decorate([
    JsonProperty('data_source_id', String, true)
], RequestLogItem.prototype, "dataSourceID", void 0);
__decorate([
    JsonProperty('data_source_name', String, true)
], RequestLogItem.prototype, "dataSourceName", void 0);
__decorate([
    JsonProperty('data_source_namespace', String, true)
], RequestLogItem.prototype, "dataSourceNamespace", void 0);
__decorate([
    JsonProperty('auth_method', String, true)
], RequestLogItem.prototype, "authMethod", void 0);
RequestLogItem = __decorate([
    JsonObject('RequestLogItem')
], RequestLogItem);
export { RequestLogItem };
let AppLogResponse = class AppLogResponse {
    logs = [];
    nextEndDate = undefined;
    nextSkip = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('logs', [RequestLogItem])
], AppLogResponse.prototype, "logs", void 0);
__decorate([
    JsonProperty('nextEndDate', DateConverter, true)
], AppLogResponse.prototype, "nextEndDate", void 0);
__decorate([
    JsonProperty('nextSkip', Number, true)
], AppLogResponse.prototype, "nextSkip", void 0);
AppLogResponse = __decorate([
    JsonObject('AppLogResponse')
], AppLogResponse);
export { AppLogResponse };
export function getLogFilter(request) {
    let filter;
    if (request) {
        filter = {};
        if (request.endDate) {
            filter.end_date = request.endDate.toISOString();
        }
        if (request.startDate) {
            filter.start_date = request.startDate.toISOString();
        }
        if (request.type) {
            filter.type = request.type;
        }
        if (request.userId) {
            filter.user_id = request.userId;
        }
        if (request.errorsOnly) {
            filter.errors_only = request.errorsOnly;
        }
        if (request.coId) {
            filter.co_id = request.coId;
        }
        if (request.limit) {
            filter.limit = request.limit;
        }
        if (request.skip) {
            filter.skip = request.skip;
        }
    }
    return filter;
}
