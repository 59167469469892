import React from 'react';
import Banner from '@leafygreen-ui/banner';

import DocLink from 'baas-ui/common/components/doc-link';
import { SERVICE_DEPRECATION_MESSAGE } from 'baas-ui/common/constants';
import { docLinks } from 'baas-ui/common/links';

import './service-deprecation-banner.less';

const ServiceDeprecationBanner = () => {
  return (
    <Banner
      variant="warning"
      data-cy="deprecation-banner"
      className="service-deprecation-banner"
      aria-labelledby="deprecation-banner"
    >
      {SERVICE_DEPRECATION_MESSAGE}
      <DocLink href={docLinks.Services.LearnMore} showExternalIcon>
        Learn more
      </DocLink>
    </Banner>
  );
};

export default ServiceDeprecationBanner;
