import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Banner, { Variant } from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import { css } from '@leafygreen-ui/emotion';
import { Option, Select } from '@leafygreen-ui/select';
import { Description, H3 } from '@leafygreen-ui/typography';

import Footer from 'baas-ui/app/footer';
import { Spinner } from 'baas-ui/common/components/spinner';
import { ZIndex } from 'baas-ui/common/styles/zIndex';
import * as actions from 'baas-ui/home/actions';
import { AppProduct } from 'baas-ui/home/types';
import { TopNav } from 'baas-ui/nav';
import { getAdminClientState, getHomeState, getUserProfileState } from 'baas-ui/selectors';
import urls from 'baas-ui/urls';
import { BaasAdminClient, PartialApp, UserProfile } from 'admin-sdk';

import CreateAppModal from './CreateAppModal';
import HomeAppDisplay from './HomeAppDisplay';

import 'baas-ui/common/styles/banner.less';

const styles = {
  groupIdSelect: css`
    display: flex;
    margin: auto;
    max-width: 300px;
  `,
};

export interface ReduxDispatchProps {
  createApp(): Promise<PartialApp>;
  deleteApp(appName: string, appId: string): Promise<void>;
  loadApps(groupId: string): Promise<PartialApp[]>;
  showNewAppModal(): void;
  setCreateAppError(): void;
  setGroupId(groupId: string): void;
  hideNewAppModal(): void;
}

export interface ReduxStateProps {
  apps: PartialApp[];
  client?: BaasAdminClient;
  error?: string;
  groupId?: string;
  loading?: boolean;
  createAppError?: string;
  userProfile?: UserProfile;
}

export interface OwnProps {
  isNewAppModalOpen?: boolean;
}

export type Props = ReduxDispatchProps & ReduxStateProps & OwnProps;

const HomePage = ({
  apps = [],
  client,
  createApp,
  createAppError = '',
  deleteApp,
  error,
  groupId,
  hideNewAppModal,
  isNewAppModalOpen = false,
  loadApps,
  loading = false,
  setCreateAppError,
  setGroupId,
  showNewAppModal,
  userProfile,
}: Props) => {
  const history = useHistory();

  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [appToDelete, setAppToDelete] = useState('');
  const [deleteAppModalOpen, setDeleteAppModalOpen] = useState(false);

  useEffect(() => {
    if (!client?.authedId()) {
      history.push(urls.login());
      return;
    }

    if (!userProfile) {
      return;
    }

    const tempGroupIds: string[] = [];
    const roles = userProfile.roles;

    if (!roles?.length) {
      return;
    }

    for (let i = 0; i < roles.length; i += 1) {
      const role = roles[i];
      if (role.groupId) {
        tempGroupIds.push(role.groupId);
      }
    }

    setGroupIds(tempGroupIds);

    if (!groupIds.length) {
      return;
    }

    if (groupId && groupIds.includes(groupId)) {
      loadApps(groupId);
      return;
    }

    setGroupId(groupIds[0]);
    loadApps(groupIds[0]);
  }, [client, userProfile, history]);

  return groupIds ? (
    <>
      <TopNav />
      <div className="home main-content">
        <div className="home-banner">
          <H3 className="home-banner-header">Welcome to App Services</H3>
          <div className="home-banner-controls">
            <Button variant="primary" size="large" onClick={showNewAppModal}>
              + Create a new app
            </Button>
          </div>
        </div>
        {error && (
          <Banner className="banner-margin-vertical" variant={Variant.Danger}>
            {error}
          </Banner>
        )}
        <Select
          id="group-id-select"
          label="Select Group ID"
          data-cy="select-group-dropdown"
          onChange={(selectedGroupId) => {
            setGroupId(selectedGroupId);
            loadApps(selectedGroupId);
          }}
          disabled={!groupIds.length}
          allowDeselect={false}
          className={styles.groupIdSelect}
        >
          {[...new Set(groupIds)].map((grpId: string) => (
            <Option data-cy="select-group-item" key={grpId} value={grpId}>
              {grpId}
            </Option>
          ))}
        </Select>
        {groupId && (
          <div className="home-projects">
            <div className="home-projects-listing">
              <div className="home-projects-listing-label">All Projects</div>
              <Spinner open={loading} xlarge />
              {apps.map((app) => {
                return (
                  <HomeAppDisplay
                    key={app.clientAppId}
                    // @ts-expect-error error: "Unused '@ts-expect-error' directive.(2578)"
                    app={app}
                    deleteApp={() => {
                      setAppToDelete(app.id);
                      setDeleteAppModalOpen(true);
                    }}
                  />
                );
              })}
              <div className="home-projects-listing-new" onClick={showNewAppModal}>
                + Create a new app
              </div>
            </div>
          </div>
        )}
      </div>
      {groupId && (
        <CreateAppModal
          groupId={groupId}
          createApp={createApp}
          open={isNewAppModalOpen}
          setCreateAppError={setCreateAppError}
          createAppError={createAppError}
          onClose={hideNewAppModal}
        />
      )}
      <ConfirmationModal
        open={deleteAppModalOpen}
        variant={Variant.Danger}
        title={`Delete App: ${appToDelete}`}
        confirmButtonProps={{
          children: 'Delete App',
          onClick: () => {
            if (!groupId || !appToDelete) {
              return;
            }
            deleteApp(groupId, appToDelete).then(() => loadApps(groupId));
            setDeleteAppModalOpen(false);
          },
        }}
        cancelButtonProps={{
          onClick: () => {
            setDeleteAppModalOpen(false);
            setAppToDelete('');
          },
        }}
        style={{ zIndex: ZIndex.Modal }}
      >
        <Description className="tb-view-modal-body-confirm">
          Are you sure you want to delete app:
          <span className="tb-view-modal-body-confirm-bold">
            &nbsp;
            {appToDelete}
          </span>
          ?
        </Description>
      </ConfirmationModal>
      <Footer />
    </>
  ) : (
    <>
      <TopNav />
      <div className="home main-content">
        <div className="home-banner">
          <H3 className="home-banner-header">Welcome to App Services</H3>
        </div>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  const { apps, loading, error, createAppError, groupId, showNewAppModal: isNewAppModalOpen } = getHomeState(state);
  const client = getAdminClientState(state);
  const userProfile = getUserProfileState(state);
  return {
    apps,
    loading,
    error,
    createAppError,
    client,
    isNewAppModalOpen,
    groupId,
    userProfile,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadApps: (groupId, products = [AppProduct.Standard]) => dispatch(actions.loadApps({ groupId, products })),
  createApp: (groupId, request) => dispatch(actions.createApp({ groupId, request })),
  deleteApp: (groupId, appId) => dispatch(actions.deleteApp({ groupId, appId })),
  showNewAppModal: () => dispatch(actions.showNewAppModal()),
  hideNewAppModal: () => dispatch(actions.hideNewAppModal()),
  setGroupId: (id) => dispatch(actions.setGroupId(id)),
  setCreateAppError: (error) => dispatch(actions.setCreateAppError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
