const VALID_APP_NAME_REGEXP = /^[a-zA-Z0-9][a-zA-Z0-9-_]*$/;

export const validateAppName = (appName: string): string => {
  if (appName.length === 0) {
    return 'App name must not be empty';
  }
  if (appName.length > 32) {
    return 'App name is too long';
  }
  if (!appName.match(VALID_APP_NAME_REGEXP)) {
    return 'App name must be alphanumeric';
  }
  return '';
};

const oneDay = 1000 * 60 * 60 * 24; // one day in milliseconds

export const validateStoredRequestId = (formattedRequestId: string): string => {
  const parts = formattedRequestId.split('-');
  if (parts.length !== 2) {
    return '';
  }
  const requestTime = new Date(parseInt(parts[1], 10));
  const now = new Date();
  return requestTime.getTime() + oneDay > now.getTime() ? parts[0] : '';
};
