var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { JsonObject, JsonProperty } from 'json2typescript';
let NearestAppRegion = class NearestAppRegion {
    nearestAppRegion = null;
    constructor(required) {
        Object.assign(this, required);
    }
};
__decorate([
    JsonProperty('nearest_app_region')
], NearestAppRegion.prototype, "nearestAppRegion", void 0);
NearestAppRegion = __decorate([
    JsonObject('NearestAppRegion')
], NearestAppRegion);
export { NearestAppRegion };
let ServiceSupportedRegions = class ServiceSupportedRegions {
    label = '';
    value = '';
    constructor(required) {
        Object.assign(this, required);
    }
};
__decorate([
    JsonProperty('label')
], ServiceSupportedRegions.prototype, "label", void 0);
__decorate([
    JsonProperty('value')
], ServiceSupportedRegions.prototype, "value", void 0);
ServiceSupportedRegions = __decorate([
    JsonObject('ServiceSupportedRegions')
], ServiceSupportedRegions);
export { ServiceSupportedRegions };
