import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ThroughProvider } from 'react-through';
import classNames from 'classnames';

import { redirectTo as redirectToAction } from 'baas-ui/actions';
import { unsetFirstAppSaveAction, unsetFirstDraftSaveAction } from 'baas-ui/actions';
import { clearAll } from 'baas-ui/actions';
import * as actions from 'baas-ui/app/actions';
import Footer from 'baas-ui/app/footer';
import Toasts from 'baas-ui/app/toasts';
import DarkModeMarketingModal from 'baas-ui/common/components/dark-mode-marketing-modal/DarkModeMarketingModal';
import loadable from 'baas-ui/common/components/loadable';
import { buildStatusContainer } from 'baas-ui/common/components/status';
import { StatusBanner, Variant } from 'baas-ui/common/components/status';
import { TitleArea } from 'baas-ui/common/components/title';
import { SnippetsContextProvider } from 'baas-ui/common/context/SnippetsContext';
import { FeatureFlag } from 'baas-ui/common/featureSettings';
import { useLocalStorage } from 'baas-ui/common/hooks/use-local-storage';
import usePoller from 'baas-ui/common/hooks/use-poller';
import { docLinks } from 'baas-ui/common/links';
import { activeGuideKey } from 'baas-ui/common/local-storage-keys';
import { thirdPartyServicesKey } from 'baas-ui/common/local-storage-keys';
import { showFrontendTemplateModalOnNewAppKey, showGuidesOnStartKey } from 'baas-ui/common/local-storage-keys';
import {
  GROUP_OWNER,
  STATUS_TYPE_LOCAL_STORAGE,
  STATUS_TYPE_SERVICE,
  STATUS_TYPE_USER_PERMISSIONS,
} from 'baas-ui/constants';
import { STATUS_TYPE_DEPLOYMENT } from 'baas-ui/deploy/constants';
import { DeployContextProvider } from 'baas-ui/deploy/deploy-context';
import DeployStatusBanner from 'baas-ui/deploy/deploy-status-banner';
import UnderstandingDraftsModal from 'baas-ui/deploy/understanding-drafts-modal';
import UnderstandingSaveAndDeployModal from 'baas-ui/deploy/understanding-save-and-deploy-modal';
import { warningHandlerNotify } from 'baas-ui/error_util';
import { TriggersContextProvider } from 'baas-ui/events/context/TriggersContext';
import * as depsActions from 'baas-ui/functions/dependencies/actions';
import GuideRunner from 'baas-ui/guides/runner';
import * as homeActions from 'baas-ui/home/actions';
import { localStorageDisabledErrorMessage } from 'baas-ui/home/constants';
import NotFound from 'baas-ui/home/not-found';
import { Breadcrumbs, ErrorBoundaryRoute, SideNav, TopNav } from 'baas-ui/nav';
import { AsyncDispatch, AsyncDispatchPayload } from 'baas-ui/redux_util';
import {
  getAppState,
  getFirstAppSaveState,
  getFirstDraftSaveState,
  getHomeState,
  getServiceState,
  getSyncState,
  getUserProfileState,
} from 'baas-ui/selectors';
import { getSettingsState } from 'baas-ui/selectors';
import * as svcActions from 'baas-ui/services/actions';
import { isDataLakeServiceType, isSelfHostedOrAtlasMongoService } from 'baas-ui/services/registry';
import MigrationStatusBanner from 'baas-ui/settings/migration-status-banner';
import { featureSettings } from 'baas-ui/stitch_ui';
import * as syncActions from 'baas-ui/sync/actions';
import { STATUS_TYPE_DEV_MODE } from 'baas-ui/sync/constants';
import InitialSyncStatusBanner from 'baas-ui/sync/initial-sync-status-banner';
import SyncEventSubscriptionErrorBanner from 'baas-ui/sync/sync-event-subscription-error-banner';
import SyncMigrationBanner from 'baas-ui/sync/sync-migration-banner';
import { RootState } from 'baas-ui/types';
import rootUrl from 'baas-ui/urls';
import { AppDependencies, FeatureSettings, PartialApp, PartialServiceDesc, SyncConfig } from 'admin-sdk';

import './app.less';

const STICKY_BANNER_STATUS_TYPES = [STATUS_TYPE_DEPLOYMENT, STATUS_TYPE_DEV_MODE];

export const BannerStatusContainer = buildStatusContainer({
  filter: (item) => STICKY_BANNER_STATUS_TYPES.includes(item.statusType ?? ''),
});

export const StatusContainer = buildStatusContainer({
  filter: (item) => !STICKY_BANNER_STATUS_TYPES.includes(item.statusType ?? ''),
});

const Dashboard = loadable(() => import(/* webpackChunkName: 'Dashboard' */ '../dashboard'));
const Authentication = loadable(
  () => import(/* webpackChunkName: 'Authentication' */ '../../auth/components/Authentication')
);
const Clusters = loadable(() => import(/* webpackChunkName: 'Clusters' */ '../../services/mongodb/clusters/Clusters'));
const Deploy = loadable(() => import(/* webpackChunkName: 'Deploy' */ '../../deploy/deploy-page'));
const Events = loadable(() => import(/* webpackChunkName: 'Events' */ '../../events/components/Events'));
const Functions = loadable(() => import(/* webpackChunkName: 'Functions' */ '../../functions/components/Functions'));
// TODO(BAAS-31888): remove the graphql page
const GraphQLPage = loadable(() => import(/* webpackChunkName: 'GraphQLPage' */ '../../graphql'));
const RulesPage = loadable(() => import(/* webpackChunkName: 'RulesPage' */ '../../rules'));
const SchemaPage = loadable(() => import(/* webpackChunkName: 'SchemaPage' */ '../../schema'));
const Hosting = loadable(() => import(/* webpackChunkName: 'Hosting' */ '../../hosting/components/Hosting'));
const LogsPage = loadable(() => import(/* webpackChunkName: 'LogsPage' */ '../../logs'));
const Push = loadable(() => import(/* webpackChunkName: 'Push' */ '../../push/PushNotifications'));
const Services = loadable(() => import(/* webpackChunkName: 'Services' */ './Services'));
const Settings = loadable(() => import(/* webpackChunkName: 'Settings' */ '../../settings/settings-page/Settings'));
const ValuesPage = loadable(() => import(/* webpackChunkName: 'Values' */ '../../values'));
const SDKsPage = loadable(() => import(/* webpackChunkName: 'SDKsPage' */ '../../sdks/SDKsPage'));
const SyncPage = loadable(() => import(/* webpackChunkName: 'SyncPage' */ '../../sync/sync-page'));
const EdgePage = loadable(() => import(/* webpackChunkName: 'SyncPage' */ '../../edge/edge-page/EdgePage'));
const EndpointsPage = loadable(() => import(/* webpackChunkName: 'EndpointsPage' */ '../../endpoints/EndpointsPage'));

export const DarkModeModalTestId = 'dark-mode-modal';

interface StateProps {
  app: PartialApp;
  error?: string;
  groupId: string;
  isFullscreen: boolean;
  userIsProjectOwner: boolean;
  loadingApp: boolean;
  loadingFeatureFlags: boolean;
  enabledFeatureFlags: FeatureFlag[];
  loadServicesError?: string;
  loadSyncConfigError?: string;
  resourceNotFoundError?: string;
  services: PartialServiceDesc[];
  deploymentConfigUrl: string;
  firstAppSave: boolean;
  firstDraftSave: boolean;
  hasAcknowledgedDarkMode: boolean;
}

interface DispatchProps {
  clearAppState(): void;
  clearDataSourceErrors(): void;
  clearClusterProvisionToastState(): void;
  load(groupId: string, appId: string): AsyncDispatchPayload<PartialApp>;
  loadServices(groupId: string, appId: string): AsyncDispatchPayload<PartialServiceDesc[]>;
  getEnabledFeatureFlags(groupId: string, appId: string): AsyncDispatchPayload<FeatureSettings>;
  loadDependencies(groupId: string, appId: string): AsyncDispatchPayload<AppDependencies>;
  setResourceNotFoundError(error?: string): void;
  loadSyncConfig(groupId: string, appId: string): AsyncDispatchPayload<SyncConfig>;
  setGroupId(groupId: string): void;
  unsetFirstAppSave(): void;
  unsetFirstDraftSave(): void;
  redirectTo(url: string): void;
}

type RouteProps = RouteComponentProps<{ groupId: string; appId: string }>;

export interface OwnProps {
  clusterStatePoller: ReturnType<typeof usePoller>;
  children: React.ReactNode;
}

export type Props = StateProps & DispatchProps & RouteProps & OwnProps;

const App = ({
  app,
  clearAppState,
  clearClusterProvisionToastState,
  clearDataSourceErrors,
  clusterStatePoller,
  error,
  groupId,
  isFullscreen = false,
  load,
  getEnabledFeatureFlags,
  loadingFeatureFlags = false,
  enabledFeatureFlags = [],
  loadingApp = false,
  loadServices,
  loadDependencies,
  loadServicesError,
  loadSyncConfig,
  loadSyncConfigError,
  location,
  match,
  redirectTo,
  resourceNotFoundError,
  services,
  setGroupId,
  setResourceNotFoundError,
  userIsProjectOwner,
  deploymentConfigUrl,
  firstAppSave = false,
  unsetFirstAppSave,
  firstDraftSave = false,
  unsetFirstDraftSave,
  hasAcknowledgedDarkMode = true,
}: Props) => {
  // TODO(BAAS-7260): Investigate why last used app redirect breaks react.
  // const [lastUsedAppId, setLastUsedAppId] = useLocalStorage(lastUsedAppForGroupIdKey(match.params.groupId), '');

  React.useEffect(() => {
    const matchedGroupId = match.params.groupId;
    if (matchedGroupId && matchedGroupId !== groupId) {
      setGroupId(matchedGroupId);
    }

    // TODO(BAAS-7260): Investigate why last used app redirect breaks react.
    //
    // const matchedAppId = match.params.appId;
    // if (matchedGroupId && matchedAppId && matchedAppId !== lastUsedAppId) {
    //   setLastUsedAppId(matchedAppId);
    // }
  }, [match.params.groupId]);

  React.useEffect(() => {
    if (resourceNotFoundError) {
      // unset resourceNotFoundError
      setResourceNotFoundError(undefined);
    }
  }, [location]);

  React.useEffect(() => {
    if (!loadingFeatureFlags) {
      featureSettings.setAppFeatures(match.params.appId, enabledFeatureFlags || []);
    }
  }, [loadingFeatureFlags, enabledFeatureFlags]);

  React.useEffect(() => {
    const matchedGroupId = match.params.groupId;
    const appId = match.params.appId;

    try {
      load(matchedGroupId, appId);
      loadServices(matchedGroupId, appId);
      loadDependencies(matchedGroupId, appId);
      loadSyncConfig(matchedGroupId, appId);
      getEnabledFeatureFlags(match.params.groupId, match.params.appId);
    } catch (err) {
      warningHandlerNotify(err);
    }

    return () => {
      clearAppState();
      clearClusterProvisionToastState();
      clearDataSourceErrors();
      featureSettings.clearAppFeatures();
    };
  }, [match.params.groupId, match.params.appId]);

  const [activeGuide, setActiveGuide] = useLocalStorage(activeGuideKey(match.params.appId), {});
  const [localStorageDisabledBanner, setLocalStorageDisabledBanner] = React.useState(window.localStorage == null);
  const [guidePopoverExpanded, setGuidePopoverExpanded] = React.useState(true);

  // ensure that dark mode marketing modal only displays if no other start modals should be shown
  const [showFrontendTemplateModalOnNewApp] = useLocalStorage(
    showFrontendTemplateModalOnNewAppKey(match.params.appId),
    false
  );
  const [showGuidesOnStart] = useLocalStorage(showGuidesOnStartKey(match.params.appId), false);
  const showDarkModeMarketingModal =
    !hasAcknowledgedDarkMode && !showFrontendTemplateModalOnNewApp && !showGuidesOnStart;

  const [thirdPartyServicesEnabled, setThirdPartyServicesActive] = useLocalStorage<boolean | undefined>(
    thirdPartyServicesKey(match.params.appId),
    undefined
  );
  const thirdPartyServices = services.filter(
    (svc) => !isSelfHostedOrAtlasMongoService(svc.type) && !isDataLakeServiceType(svc.type)
  );
  if (thirdPartyServices.length > 0 && thirdPartyServicesEnabled === undefined) {
    setThirdPartyServicesActive(true);
  }

  if (!app && error) {
    return <NotFound />;
  }

  if (!app) {
    return null;
  }

  const appUrl = rootUrl.groups().group(app.groupId).apps().app(app.id);

  return (
    <DeployContextProvider>
      <SnippetsContextProvider>
        <TriggersContextProvider>
          <ThroughProvider>
            <div className="through-provider">
              {/* Through Provider Managers */}
              <TitleArea />
              <DeployStatusBanner />
              <InitialSyncStatusBanner />
              <SyncMigrationBanner />
              <MigrationStatusBanner />
              <Toasts
                clusterStatePoller={clusterStatePoller}
                activeGuide={activeGuide}
                guidePopoverExpanded={guidePopoverExpanded}
              />
              <UnderstandingDraftsModal open={firstDraftSave} onClose={unsetFirstDraftSave} />
              <UnderstandingSaveAndDeployModal
                deploymentConfigUrl={deploymentConfigUrl}
                open={firstAppSave}
                onClose={unsetFirstAppSave}
              />
              {loadSyncConfigError && (
                <StatusBanner
                  variant={Variant.Error}
                  message={`Error loading state. Please try again. ${loadSyncConfigError}`}
                  statusId="sync-config-load-error-banner"
                  statusType={STATUS_TYPE_DEV_MODE}
                />
              )}
              <SyncEventSubscriptionErrorBanner />
              {loadServicesError && (
                <StatusBanner
                  actionLabel="Refresh"
                  action={() => window.location.reload()}
                  variant={Variant.Error}
                  message="Your MongoDB Atlas cluster(s) failed to re-load and you may not have access to certain features. Try refreshing this page."
                  statusId="service-status-banner"
                  statusType={STATUS_TYPE_SERVICE}
                />
              )}
              {!userIsProjectOwner && (
                <StatusBanner
                  actionLabel="More Info"
                  action={() => window.open(docLinks.Atlas.UserRoles, '_blank')}
                  variant={Variant.Error}
                  message="You do not have the required permissions to edit this application. Editing an application requires the Project Owner role."
                  statusId="user-permissions-banner"
                  statusType={STATUS_TYPE_USER_PERMISSIONS}
                />
              )}
              {localStorageDisabledBanner && (
                <StatusBanner
                  variant={Variant.Warning}
                  clearable
                  message={localStorageDisabledErrorMessage}
                  statusId="localstorage-disabled-banner"
                  statusType={STATUS_TYPE_LOCAL_STORAGE}
                  onClear={() => setLocalStorageDisabledBanner(false)}
                />
              )}
              {/* Through Provider Managers */}

              {!isFullscreen && <TopNav />}
              <DarkModeMarketingModal
                open={showDarkModeMarketingModal}
                onRedirect={redirectTo}
                data-testid={DarkModeModalTestId}
              />
              <div
                className={classNames('app-container', {
                  'app-container-fullscreen': isFullscreen,
                })}
              >
                {!isFullscreen && <SideNav />}
                <div
                  className={classNames('page-content', {
                    'page-content-is-fullscreen': isFullscreen,
                  })}
                >
                  <div className="sticky-banner-status">
                    <BannerStatusContainer />
                  </div>
                  {!isFullscreen && (
                    <>
                      <StatusContainer />
                      <Breadcrumbs />
                    </>
                  )}
                  <Switch>
                    <Redirect exact from={appUrl.get()} to={appUrl.dashboard()} />
                    {resourceNotFoundError && <Route component={NotFound} />}
                    <ErrorBoundaryRoute
                      path={appUrl.dashboard()}
                      render={(routeProps) => (
                        <Dashboard
                          {...routeProps}
                          setActiveGuide={setActiveGuide}
                          setGuidePopoverExpanded={setGuidePopoverExpanded}
                        />
                      )}
                    />
                    <ErrorBoundaryRoute
                      path={appUrl.auth().list()}
                      render={(routeProps) => <Authentication app={app} {...routeProps} />}
                    />
                    <ErrorBoundaryRoute
                      path={appUrl.push().list()}
                      render={(routeProps) => <Push app={app} {...routeProps} />}
                    />
                    <ErrorBoundaryRoute path={appUrl.sdks().list()} render={() => <SDKsPage />} />
                    <ErrorBoundaryRoute path={appUrl.logs().list()} render={() => <LogsPage appUrl={appUrl} />} />
                    <ErrorBoundaryRoute
                      path={[appUrl.values().list(), appUrl.secrets().list(), appUrl.environmentValues().list()]}
                      render={(routeProps) => <ValuesPage {...routeProps} />}
                    />
                    <ErrorBoundaryRoute
                      path={appUrl.services().list()}
                      render={(routeProps) => <Services app={app} {...routeProps} />}
                    />
                    <ErrorBoundaryRoute
                      path={`(${appUrl.functions().list()}|${appUrl.dependencies().list()})`}
                      render={(routeProps) => <Functions app={app} {...routeProps} />}
                    />
                    <ErrorBoundaryRoute
                      path={appUrl.endpoints().list()}
                      render={(routeProps) => <EndpointsPage {...routeProps} />}
                    />
                    <ErrorBoundaryRoute
                      path={appUrl.triggers().list()}
                      render={(routeProps) => <Events {...routeProps} />}
                    />
                    <ErrorBoundaryRoute
                      path={appUrl.settings().list()}
                      render={() => (
                        <Settings
                          providerRegion={app.providerRegion}
                          deploymentModel={app.deploymentModel}
                          clientAppId={app.clientAppId}
                          appId={app.id}
                          groupId={app.groupId}
                        />
                      )}
                    />
                    <ErrorBoundaryRoute
                      path={appUrl.hosting().list()}
                      render={(routeProps) => <Hosting app={app} {...routeProps} />}
                    />
                    <ErrorBoundaryRoute
                      path={appUrl.deploy().list()}
                      render={() => (
                        <Deploy appId={app.id} clientAppId={app.clientAppId} groupId={app.groupId} appName={app.name} />
                      )}
                    />
                    <ErrorBoundaryRoute
                      path={appUrl.clusters().list()}
                      render={(routeProps) => <Clusters app={app} {...routeProps} />}
                    />
                    <ErrorBoundaryRoute
                      path={[
                        appUrl.rules().default(':dataSourceName'),
                        appUrl.rules().new(':dataSourceName'),
                        appUrl.rules().rule({ dataSourceName: ':dataSourceName', ruleId: ':ruleId' }).get(),
                        appUrl.rules().list(),
                      ]}
                      component={RulesPage}
                    />
                    <ErrorBoundaryRoute
                      path={[appUrl.schemas().schema(':schemaId').get(), appUrl.schemas().root()]}
                      component={SchemaPage}
                    />
                    {
                      // TODO(BAAS-31888): remove this route
                      <ErrorBoundaryRoute
                        path={appUrl.graphql().root()}
                        render={() => <GraphQLPage appUrl={appUrl} />}
                      />
                    }

                    <ErrorBoundaryRoute
                      path={appUrl.sync().root()}
                      render={(routeProps) => (
                        <SyncPage
                          {...routeProps}
                          setActiveGuide={setActiveGuide}
                          setGuidePopoverExpanded={setGuidePopoverExpanded}
                          productType={app.product}
                        />
                      )}
                    />

                    <ErrorBoundaryRoute
                      path={appUrl.edge().root()}
                      render={(routeProps) => <EdgePage {...routeProps} productType={app.product} />}
                    />

                    {!loadingApp && <Route component={NotFound} />}
                  </Switch>
                  <Footer />
                </div>
                <GuideRunner
                  activeGuide={activeGuide}
                  location={location}
                  setActiveGuide={setActiveGuide}
                  isExpanded={guidePopoverExpanded}
                  setIsExpanded={setGuidePopoverExpanded}
                />
              </div>
            </div>
          </ThroughProvider>
        </TriggersContextProvider>
      </SnippetsContextProvider>
    </DeployContextProvider>
  );
};

const mapStateToProps = (state: RootState) => {
  const { app, error, loadingApp, loadingFeatureFlags, enabledFeatureFlags, isFullscreen, resourceNotFoundError } =
    getAppState(state);
  const { svcsById, error: loadServicesError } = getServiceState(state);
  const {
    config: { loadError: loadSyncConfigError },
  } = getSyncState(state);
  const { groupId } = getHomeState(state);
  const userProfile = getUserProfileState(state);
  const { hasAcknowledgedDarkMode } = getSettingsState(state);
  const userIsProjectOwner =
    !!userProfile &&
    userProfile.roles &&
    userProfile.roles.some((role) => role.groupId === groupId && role.roleName === GROUP_OWNER);

  let deploymentConfigUrl = '';
  if (app) {
    deploymentConfigUrl = rootUrl.groups().group(groupId).apps().app(app.id).deploy().config();
  }

  const firstAppSave = getFirstAppSaveState(state);
  const firstDraftSave = getFirstDraftSaveState(state);

  return {
    app,
    error,
    groupId,
    isFullscreen,
    userIsProjectOwner,
    loadingApp,
    loadingFeatureFlags,
    enabledFeatureFlags,
    loadServicesError,
    loadSyncConfigError,
    resourceNotFoundError,
    services: Object.values(svcsById),
    deploymentConfigUrl,
    firstAppSave,
    firstDraftSave,
    hasAcknowledgedDarkMode,
  };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  dispatch,
  clearAppState: () => dispatch(clearAll()),
  clearDataSourceErrors: () => dispatch(homeActions.clearDataSourceErrors()),
  clearClusterProvisionToastState: () => dispatch(homeActions.clearClusterProvisionToastState()),
  load: (groupId: string, appId: string) => dispatch(actions.loadApp({ groupId, appId })),
  loadServices: (groupId: string, appId: string) => dispatch(svcActions.loadServices({ groupId, appId })),
  getEnabledFeatureFlags: (groupId: string, appId: string) =>
    dispatch(actions.getAppEnabledFeatureFlags({ groupId, appId })),
  loadDependencies: (groupId: string, appId: string) => dispatch(depsActions.loadDependencies({ groupId, appId })),
  setResourceNotFoundError: (error?: string) => dispatch(actions.setResourceNotFoundError(error)),
  loadSyncConfig: (groupId: string, appId: string) => dispatch(syncActions.loadConfig({ appId, groupId })),
  setGroupId: (groupId: string) => dispatch(homeActions.setGroupId(groupId)),
  unsetFirstAppSave: () => dispatch(unsetFirstAppSaveAction()),
  unsetFirstDraftSave: () => dispatch(unsetFirstDraftSaveAction()),
  redirectTo: (url: string) => dispatch(redirectToAction(url)),
});

export { App as AppComponent };

export default connect(mapStateToProps, mapDispatchToProps)(App);
