import { JsonObject, JsonProperty } from 'json2typescript';

import { DeploymentModel, ProviderRegion } from 'admin-sdk/api/common';

@JsonObject('PutDeploymentMigrationRequest')
export class PutDeploymentMigrationRequest {
  @JsonProperty('deployment_model', String)
  public deploymentModel: DeploymentModel = DeploymentModel.Global;

  @JsonProperty('provider_region', String)
  public providerRegion: ProviderRegion = ProviderRegion.AWSProviderRegionUSEast1;

  constructor(required?: Required<PutDeploymentMigrationRequest>) {
    Object.assign(this, required);
  }
}

@JsonObject('AppDeploymentSettings')
export class AppDeploymentSettings {
  @JsonProperty('deployment_model', String)
  public deploymentModel: DeploymentModel = DeploymentModel.Global;

  @JsonProperty('provider_region', String)
  public providerRegion: ProviderRegion = ProviderRegion.AWSProviderRegionUSEast1;

  constructor(required?: Required<AppDeploymentSettings>) {
    Object.assign(this, required);
  }
}

@JsonObject('DeploymentMigrationStatus')
export class DeploymentMigrationStatus {
  @JsonProperty('status', String, true)
  public status = '';

  @JsonProperty('message', String, true)
  public message = '';

  @JsonProperty('updated_at', String, true)
  public updatedAt = '';

  @JsonProperty('to', AppDeploymentSettings, true)
  public to = new AppDeploymentSettings();

  @JsonProperty('from', AppDeploymentSettings, true)
  public from = new AppDeploymentSettings();

  constructor(required?: Required<DeploymentMigrationStatus>) {
    Object.assign(this, required);
  }
}
