/* eslint-disable @typescript-eslint/ban-types,camelcase */

import { AsyncDataState } from 'baas-ui/redux_util';
import { MongoDataSourceType } from 'baas-ui/services/registry';
import { RawSyncConfig } from 'baas-ui/sync/types';

export enum ReadPreference {
  Primary = 'primary',
  PrimaryPreferred = 'primaryPreferred',
  Secondary = 'secondary',
  SecondaryPreferred = 'secondaryPreferred',
  Nearest = 'nearest',
}

export enum MongoServiceStatus {
  Unlinked = 'UNLINKED',
  Linking = 'LINKING',
  Linked = 'LINKED',
  Error = 'ERROR',
}

interface ServiceConfigBase {
  groupName?: string;
  orgName?: string;
  wireProtocolEnabled?: boolean;
}

export enum ClusterType {
  Atlas = 'atlas',
  Serverless = 'serverless',
}

export interface ClusterServiceConfig extends ServiceConfigBase {
  serviceType: MongoDataSourceType.Atlas;

  // provided by privateAPI/groups/:id/atlas_clusters/:id and pulling the data into this object
  // (this is not available everywhere)
  clusterInstanceSize?: string;
  clusterName: string;
  clusterId?: string;
  readPreference?: ReadPreference;
  sync?: RawSyncConfig;
  flexible_sync?: RawSyncConfig; // eslint-disable-line camelcase
  clusterType?: string;
  dbVersion?: string;
}

export interface DataLakeServiceConfig extends ServiceConfigBase {
  serviceType: MongoDataSourceType.DataFederation;
  dataLakeName: string;
}

export interface SelfHostedMongoServiceConfig extends ServiceConfigBase {
  serviceType: MongoDataSourceType.SelfHosted;
}

// Avoid using the LegacyServiceConfig interface when possible, it's only purpose
// is to ensure backwards compatibility with configs in mms and baas-ui that predate
// the more strongly typed service configs above
interface LegacyServiceConfig {
  serviceType?: string;
  clusterName?: string;
  dataLakeName?: string;
}

export type MongoServiceConfig =
  | ClusterServiceConfig
  | DataLakeServiceConfig
  | SelfHostedMongoServiceConfig
  | LegacyServiceConfig;

export interface MDBServiceConfigField {
  config: ClusterServiceConfig | DataLakeServiceConfig | ServiceConfigBase;
}

export interface MDBServiceConfigById {
  [key: string]: MDBServiceConfigField;
}

export interface MDBService {
  id: string;
  lastModified?: number;
  name: string;
  type?: string;
  version?: number;
  config?: MongoServiceConfig;
  status?: MongoServiceStatus;
  svcError?: string;
}

export interface MDBServiceById {
  [key: string]: MDBService;
}

export interface Warnings {
  authWarning?: SecurityWarning;
  permissionsWarning?: SecurityWarning;
}

export interface SecurityWarning {
  message: string;
  link: string;
}

export interface Namespace {
  database: string;
  collections: string[];
}
export type Namespaces = Namespace[];

export interface NamespacePreimageConfig {
  dbName: string;
  collectionName: string;
  recordPreimages: boolean;
}
export type NamespacePreimageConfigsMap = Record<string, NamespacePreimageConfig>;
export type NamespacePreimageConfigs = NamespacePreimageConfig[];

export interface GeneratedSchemaState {
  isGenerating: boolean;
  error?: string;
  schema: string | null;
}

export type DataSource = {
  name: string;
  type: string;
};

export interface DataSourceState {
  collValidationResultsById: {
    [serviceId: string]: ValidateCollectionResults | undefined;
  };
  createCollectionError?: string;
  namespaces: Namespaces;
  namespacesByClusterId: {
    [serviceId: string]: AsyncDataState<Namespaces>;
  };
  estimatedDocumentCountByNamespace: {
    [namespace: string]: AsyncDataState<number>;
  };
  relationshipsError?: string;
}

export interface RawValidateCollectionErr {
  error_code: string;
  error_count: number;
  field: string;
  failed_ids: any[];
  failed_documents_query: string;
}

export interface RawValidateCollectionResults {
  errors: RawValidateCollectionErr[];
  total_error_count: number;
  total_processed_count: number;
}

export interface ValidateCollectionErr {
  errorCode: string;
  errorCount: number;
  field: string;
  failedIds: string[];
  failedDocumentsQuery: string;
}

export interface ValidateCollectionResults {
  errors: ValidateCollectionErr[];
  totalErrorCount: number;
  totalProcessedCount: number;
}

export interface RawCollQuery {
  database_name: string;
  collection_name: string;
  from_schema?: Record<string, any>;
  query?: Object;
  sort?: Object;
  skip?: number;
  limit?: number;
}

export interface Relationship {
  foreignKey: string;
  ref: string;
  isList: boolean;
}
