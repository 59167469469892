import React from 'react';
import { connect } from 'react-redux';
import MongoNav from '@lg-private/mongo-nav';
import { DataInterface } from '@lg-private/mongo-nav/src/types';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { redirectTo as redirectToAction } from 'baas-ui/actions';
import { PRODUCT_SELF_HOSTED } from 'baas-ui/common/constants';
import * as actions from 'baas-ui/nav/actions';
import { AsyncDispatch } from 'baas-ui/redux_util';
import { getAppState, getHomeState, getSettingsState, getUserProfileState } from 'baas-ui/selectors';
import { RootState } from 'baas-ui/types';
import { rootUrl } from 'baas-ui/urls';

import './top-nav.less';

export enum TestSelector {
  LGMongoNav = 'leafygreen-ui-mongo-nav',
}

interface ReduxStateProps {
  accountUIBaseUrl?: string;
  baseUrl?: string;
  chartsUIBaseUrl?: string;
  cloudUIBaseUrl?: string;
  groupId: string;
  isSelfHosted: boolean;
  setNavigation(res: DataInterface): void;
}

interface ReduxDispatchProps {
  redirectTo(url: string): void;
}

export interface OwnProps {
  isHidden?: boolean;
}

export type Props = ReduxStateProps & ReduxDispatchProps & OwnProps;

declare global {
  interface Window {
    Intercom: any;
  }
}

const TopNav = ({
  accountUIBaseUrl,
  baseUrl,
  chartsUIBaseUrl,
  cloudUIBaseUrl,
  groupId,
  isHidden,
  isSelfHosted,
  redirectTo,
  setNavigation,
}: Props) => {
  return (
    <MongoNav
      loadData
      mode={isSelfHosted ? 'dev' : 'production'}
      className={classNames('top-nav-mongo-nav', { 'top-nav-mongo-nav-hidden': isHidden })}
      activeProduct="realm"
      activeProjectId={groupId}
      onChatWithSupportClick={() => window.Intercom('show')}
      onSuccess={setNavigation}
      onElementClick={(navElement, e) => {
        switch (navElement) {
          case 'logout':
            if (isSelfHosted) {
              redirectTo(rootUrl.logout());
            }
            break;
          case 'projectNavRealm':
            e.preventDefault();
            redirectTo(`${rootUrl.groups().group(groupId).apps().list(true)}`);
            break;
          case 'projectNavCloud':
          case 'projectNavCharts':
            if (isSelfHosted) {
              e.preventDefault();
              redirectTo(rootUrl.get());
            }
            break;
          default:
            break;
        }
      }}
      constructProjectURL={({ projectId }) => {
        if (isSelfHosted) {
          return rootUrl.groups().group(groupId).apps().list();
        }
        return rootUrl.groups().group(projectId).apps().list();
      }}
      hosts={{ account: accountUIBaseUrl, cloud: cloudUIBaseUrl, charts: chartsUIBaseUrl, realm: baseUrl }}
      useAppServicesBranding
      data-testid={TestSelector.LGMongoNav}
    />
  );
};

TopNav.defaultProps = {
  accountUIBaseUrl: '',
  baseUrl: '',
  chartsUIBaseUrl: '',
  cloudUIBaseUrl: '',
};

TopNav.propTypes = {
  isSelfHosted: PropTypes.bool.isRequired,
  accountUIBaseUrl: PropTypes.string,
  baseUrl: PropTypes.string,
  chartsUIBaseUrl: PropTypes.string,
  cloudUIBaseUrl: PropTypes.string,
};

const mapStateToProps = (state: RootState) => {
  const { accountUIBaseUrl, baseUrl, cloudUIBaseUrl, chartsUIBaseUrl, product } = getSettingsState(state);
  const userProfile = getUserProfileState(state);
  const { groupId } = getHomeState(state);
  const { app } = getAppState(state);
  const isSelfHosted = product === PRODUCT_SELF_HOSTED;
  return {
    appId: app ? app.id : '',
    accountUIBaseUrl,
    baseUrl,
    chartsUIBaseUrl,
    cloudUIBaseUrl,
    groupId: groupId || (app && app.groupId) || '',
    isSelfHosted,
    userProfile,
  };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  redirectTo: (url: string) => dispatch(redirectToAction(url)),
  setNavigation: (res: DataInterface) => dispatch(actions.setNavigation(res)),
});

export { TopNav as TopNavComponent };

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
