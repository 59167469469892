import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { Title } from 'baas-ui/common/components/title';
import { BreadcrumbsItem, CloudBreadcrumbs, ErrorBoundaryRoute } from 'baas-ui/nav';
import RulesPage from 'baas-ui/rules';
import { EditConfig } from 'baas-ui/service';
import DataSourceList from 'baas-ui/services/mongodb/data-source-list';
import { rootUrl } from 'baas-ui/urls';
import './clusters.less';
export var TestSelector;
(function (TestSelector) {
    TestSelector["RulesPageRoute"] = "rules-page-route";
    TestSelector["RulesPageRedirect"] = "rules-page-redirect";
})(TestSelector || (TestSelector = {}));
const baseClassName = 'clusters';
function Clusters(props) {
    const { match, app } = props;
    const { groupId, id: appId } = app;
    const clustersRootUrl = rootUrl.groups().group(groupId).apps().app(appId).clusters();
    const appUrl = rootUrl.groups().group(app.groupId).apps().app(app.id);
    return (_jsxs("div", { "data-cy": "clusters-page", className: baseClassName, id: "clusters", children: [_jsx(Title, { children: "Data Sources" }), _jsxs(Switch, { children: [_jsx(ErrorBoundaryRoute, { exact: true, path: `${match.url}`, render: () => _jsx(DataSourceList, {}) }), _jsx(ErrorBoundaryRoute, { exact: true, path: `${match.url}/new`, render: (routeProps) => (_jsxs(_Fragment, { children: [_jsx(EditConfig, { ...props, ...routeProps, clusterLink: true }, "new"), _jsx(CloudBreadcrumbs, {}), _jsx(BreadcrumbsItem, { to: match.url, children: "Data Sources" })] })) }), _jsxs(_Fragment, { children: [[
                                clustersRootUrl.cluster(':svcId').get(),
                                clustersRootUrl.cluster(':svcId').rules().list(),
                                clustersRootUrl.cluster(':svcId').rules().new(),
                                clustersRootUrl.cluster(':svcId').rules().permissions(),
                                clustersRootUrl.cluster(':svcId').rules().rule(':ruleId').permissions(),
                                clustersRootUrl.cluster(':svcId').rules().filters(),
                                clustersRootUrl.cluster(':svcId').rules().rule(':ruleId').filters(),
                                clustersRootUrl.cluster(':svcId').rules().rule(':ruleId').get(),
                            ].map((oldUrl) => (_jsx(Redirect, { exact: true, from: oldUrl, to: appUrl.rules().list(), "data-test-selector": TestSelector.RulesPageRedirect }, oldUrl))), _jsx(ErrorBoundaryRoute, { path: appUrl.rules().list(), component: RulesPage, "data-test-selector": TestSelector.RulesPageRoute })] })] })] }));
}
export default Clusters;
