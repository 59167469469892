import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import Box from '@leafygreen-ui/box';
import Button from '@leafygreen-ui/button';
import Card from '@leafygreen-ui/card';
import Icon from '@leafygreen-ui/icon';
import { PortalContextProvider } from '@leafygreen-ui/leafygreen-provider';
import { Menu, MenuItem } from '@leafygreen-ui/menu';
import { Body, Subtitle } from '@leafygreen-ui/typography';

import GaugeChart, { MINI_DIMENSIONS } from 'baas-ui/app/metrics-card/charts/GaugeChart';
import { styledMetricsColor } from 'baas-ui/app/metrics-card/colors';
import EnvironmentBadge from 'baas-ui/common/components/environment-badge';
import ShimmerFill from 'baas-ui/common/components/shimmer-fill';
import { FeatureFlag } from 'baas-ui/common/featureSettings';
import { getFormattedNumber } from 'baas-ui/home/apps/util';
import Wave from 'baas-ui/home/apps/Wave';
import { AppDataResult, AppProduct } from 'baas-ui/home/types';
import { DEFAULT_MEASUREMENTS, MeasurementsByName } from 'baas-ui/measurements/types';
import { legacyMeasurementUsage } from 'baas-ui/measurements/utils';
import { BilledMetric, MetricUsagesByName } from 'baas-ui/metrics/types';
import { BILLED_METRICS_DETAILS, scaledMetricUsage } from 'baas-ui/metrics/utils';
import { featureSettings } from 'baas-ui/stitch_ui';
import { eventTimeDisplayExtended } from 'baas-ui/sync/util';
import { MeasurementName, PartialApp } from 'admin-sdk';

import MetricsRow from './metrics-row';

import './app-card.less';

export enum TestSelector {
  AppCard = 'app-card',
  AppLink = 'app-link',
  ChangeEnvironmentLink = 'change-environment-link',
  Header = 'header',
  AtlasTriggers = 'atlas-triggers',
  DataAPI = 'data-api',
  DeviceSync = 'device-sync',
  EdgeServer = 'edge-server',
  Badges = 'badges',
  Users = 'users',
  Requests = 'requests',
  Chart = 'chart',
  Metrics = 'metrics',
  Data = 'datax',
  Compute = 'compute',
  Sync = 'sync',
  Total = 'total',
  LastModified = 'last-modified',
  AppBox = 'box',
  DeleteApp = 'delete-app',
}

const StyledLink = styled(Link)`
  margin-right: 48px;
  margin-bottom: 48px;
`;

const StyledTimeframeText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const StyledTotalUsers = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
`;

const StyledDivider = styled.hr`
  height: 1px;
  background-color: ${styledMetricsColor('light-gray-2')};
  border: none;
  margin: 10px 0;
`;

export interface Props {
  app: PartialApp;
  appUrl: string;
  isLoadingMetrics: boolean;
  environmentUrl: string;
  onClearAppState(): void;
  onClick(): void;
  onDeleteApp(): void;
  getData(app: PartialApp, isPricingChangeEnabled: boolean): Promise<AppDataResult>;
  onGetDataComplete(measurementsByName: MeasurementsByName): void;
  appMetrics: MetricUsagesByName;
}

export const getWaveValue = (appId: string) =>
  appId.split('').reduce((total: number, c: string) => total + c.charCodeAt(0), 0);

const appCardClassname = 'app-card';
const appCardSplitClassname = `${appCardClassname}-split-info`;
const appCardSplitSectionClassname = `${appCardSplitClassname}-section`;

const appCardDataTarget = 'app-card';

export function legacyMetricDescription(measurementName: MeasurementName, isPricingChangeEnabled: boolean) {
  switch (measurementName) {
    case MeasurementName.RequestCount:
      return 'Requests';
    case MeasurementName.DataOut:
      return 'Data Transfer (GB)';
    case MeasurementName.ComputeTime:
      return 'Compute Runtime (Hr)';
    case MeasurementName.SyncTime:
      return isPricingChangeEnabled ? 'Sync Runtime (min)' : 'Sync Runtime (Hr)';
    default:
      return '';
  }
}

const NEW_METRIC_ROWS: [BilledMetric, TestSelector][] = [
  [BilledMetric.RequestCount, TestSelector.Total],
  [BilledMetric.DataOut, TestSelector.Data],
  [BilledMetric.ComputeTime, TestSelector.Compute],
  [BilledMetric.SyncTime, TestSelector.Sync],
];

export const formatUsage = (appMetrics: MetricUsagesByName, metric: BilledMetric, isPricingChangeEnabled: boolean) => {
  const value = BILLED_METRICS_DETAILS[metric].converter(appMetrics[metric], isPricingChangeEnabled);
  return scaledMetricUsage(value);
};

const LEGACY_METRIC_ROWS: [MeasurementName, TestSelector][] = [
  [MeasurementName.RequestCount, TestSelector.Total],
  [MeasurementName.DataOut, TestSelector.Data],
  [MeasurementName.ComputeTime, TestSelector.Compute],
  [MeasurementName.SyncTime, TestSelector.Sync],
];

export const formatLegacyUsage = (
  measurementsByName: MeasurementsByName,
  measurementName: MeasurementName,
  isPricingChangeEnabled: boolean
) => {
  const measurement = measurementsByName[measurementName];
  // only relevant when PricingChange FF is on, and BillingMigration FF is off
  const isPricingChangeEnabledAndSyncMetric = isPricingChangeEnabled && measurementName === MeasurementName.SyncTime;

  return legacyMeasurementUsage(
    isPricingChangeEnabledAndSyncMetric ? measurement.usage * 60 : measurement.usage,
    measurement.units
  );
};

const AppCard = ({
  app,
  appUrl,
  environmentUrl,
  getData,
  onClearAppState,
  onClick,
  onDeleteApp,
  onGetDataComplete,
  isLoadingMetrics,
  appMetrics,
}: Props) => {
  const isBillingMigrationEnabled = featureSettings.useFeatureSetting(FeatureFlag.BillingMigration);
  const isPricingChangeEnabled = featureSettings.useFeatureSetting(FeatureFlag.PricingChange);

  const [isLoading, setIsLoading] = useState(false);
  const [modalRef, setModalRef] = useState<null | HTMLDivElement>(null);
  const [appData, setAppData] = useState<AppDataResult>({
    userCount: -1,
    lastHourSuccessfulRequests: -1,
    lastHourFailedRequests: -1,
    measurementsByName: DEFAULT_MEASUREMENTS,
  });

  React.useEffect(() => {
    const getDataAsync = async () => {
      setIsLoading(true);
      try {
        const appDataP = await getData(app, isPricingChangeEnabled);
        setAppData(appDataP);
        onGetDataComplete(appDataP.measurementsByName);
      } catch {
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    // Remove stale services from redux which are added when making service calls in getData
    getDataAsync().finally(onClearAppState);
  }, [app]);

  let usageFrequency = 'This Month';
  if (isPricingChangeEnabled) {
    usageFrequency = 'Daily Usage';
  }

  const lastModifiedTimeAgo = eventTimeDisplayExtended(
    Date.now() - app.lastModified * 1_000, // lastModified is in seconds since epoch
    { largest: 1 }
  );

  return (
    <StyledLink to={appUrl} data-testid={TestSelector.AppLink}>
      <Card data-testid={TestSelector.AppCard} data-cy={appCardDataTarget} className={appCardClassname}>
        <div className={`${appCardClassname}-wave-container`}>
          <Wave value={getWaveValue(app.id)} />
        </div>
        <Box
          onClick={onClick}
          className={`${appCardClassname}-box`}
          data-test-selector={TestSelector.AppBox}
          data-testid={TestSelector.AppBox}
          ref={(el) => setModalRef(el)}
        >
          <PortalContextProvider
            popover={{
              portalContainer: modalRef,
              scrollContainer: modalRef,
            }}
          >
            <div
              data-cy={`${appCardDataTarget}-header`}
              className={`${appCardClassname}-header`}
              data-test-selector={TestSelector.Header}
              data-testid={TestSelector.Header}
              title={app.name}
            >
              <Subtitle darkMode className={`${appCardClassname}-header-text`}>
                {app.name}
              </Subtitle>
            </div>
            <div className={`${appCardDataTarget}-subheader`}>
              {app.product === AppProduct.Atlas && (
                <Body className={`${appCardDataTarget}-atlas-product`} data-test-selector={TestSelector.AtlasTriggers}>
                  Contains Atlas Triggers
                </Body>
              )}
              {app.product === AppProduct.DataAPI && (
                <Body className={`${appCardDataTarget}-atlas-product`} data-test-selector={TestSelector.DataAPI}>
                  Contains Atlas Data API
                </Body>
              )}
              {app.product === AppProduct.DeviceSync && (
                <Body className={`${appCardDataTarget}-atlas-product`} data-testid={TestSelector.DeviceSync}>
                  Contains Atlas Device Sync
                </Body>
              )}
              {app.product === AppProduct.EdgeServer && (
                <Body className={`${appCardDataTarget}-atlas-product`} data-testid={TestSelector.EdgeServer}>
                  Contains Atlas Edge Servers
                </Body>
              )}
              <div className={`${appCardClassname}-badges`} data-test-selector={TestSelector.Badges}>
                <EnvironmentBadge environment={app.environment} className={`${appCardClassname}-environment-badge`} />
              </div>
            </div>
            <div className={appCardSplitClassname}>
              <div
                className={appCardSplitSectionClassname}
                data-test-selector={TestSelector.Users}
                data-testid={TestSelector.Users}
              >
                <ShimmerFill className={`${appCardClassname}-shimmer`} visible={isLoading}>
                  <StyledTotalUsers>
                    <Body className={`${appCardSplitSectionClassname}-text`}>Total Users</Body>
                    <Body className={`${appCardSplitSectionClassname}-bold`}>
                      {getFormattedNumber(appData.userCount)}
                    </Body>
                  </StyledTotalUsers>
                </ShimmerFill>
              </div>
              <div
                className={appCardSplitSectionClassname}
                data-test-selector={TestSelector.Requests}
                data-testid={TestSelector.Requests}
              >
                <ShimmerFill className={`${appCardClassname}-shimmer`} visible={isLoading}>
                  <GaugeChart
                    title="Requests (1hr)"
                    success={appData.lastHourSuccessfulRequests}
                    fail={appData.lastHourFailedRequests}
                    dims={MINI_DIMENSIONS}
                    data-test-selector={TestSelector.Chart}
                  />
                </ShimmerFill>
              </div>
            </div>
            <StyledDivider />
            <StyledTimeframeText data-test-selector={TestSelector.LastModified}>
              <Subtitle className={`${appCardSplitSectionClassname}-subtitle`}>{usageFrequency}</Subtitle>
              <Body className={`${appCardClassname}-footer-text`}>{`Last modified: ${lastModifiedTimeAgo} ago`}</Body>
            </StyledTimeframeText>
            <div
              className={`${appCardClassname}-metrics`}
              data-test-selector={TestSelector.Metrics}
              data-testid={TestSelector.Metrics}
            >
              <ShimmerFill className={`${appCardClassname}-shimmer`} visible={isLoadingMetrics}>
                {isBillingMigrationEnabled
                  ? NEW_METRIC_ROWS.map(([metric, testSelector]) => (
                      <MetricsRow
                        key={metric}
                        usage={formatUsage(appMetrics, metric, isPricingChangeEnabled)}
                        title={BILLED_METRICS_DETAILS[metric].titleWithUnits}
                        selector={testSelector}
                      />
                    ))
                  : LEGACY_METRIC_ROWS.map(([measurementName, testSelector]) => (
                      <MetricsRow
                        key={measurementName}
                        usage={formatLegacyUsage(appData.measurementsByName, measurementName, isPricingChangeEnabled)}
                        title={legacyMetricDescription(measurementName, isPricingChangeEnabled)}
                        selector={testSelector}
                      />
                    ))}
              </ShimmerFill>
            </div>
          </PortalContextProvider>
        </Box>
        <Menu
          trigger={
            <Button
              size="xsmall"
              onClick={(e) => e.preventDefault()}
              name="options"
              className={`${appCardClassname}-dropdown-trigger`}
            >
              <Icon glyph="Ellipsis" />
            </Button>
          }
        >
          <Link to={environmentUrl} data-testid={TestSelector.ChangeEnvironmentLink}>
            <MenuItem className={`${appCardClassname}-dropdown-list-item`}>Change Environment</MenuItem>
          </Link>
          <MenuItem
            data-testid={TestSelector.DeleteApp}
            data-cy={`${appCardDataTarget}-dropdown-delete`}
            className={`${appCardClassname}-dropdown-list-item dd-list-item-danger`}
            onClick={(e) => {
              e.preventDefault();
              onDeleteApp();
            }}
          >
            Delete App
          </MenuItem>
        </Menu>
      </Card>
    </StyledLink>
  );
};

export default AppCard;
