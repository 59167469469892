import React from 'react';
import styled from '@emotion/styled';
import Banner from '@leafygreen-ui/banner';
import { Body, Link } from '@leafygreen-ui/typography';

import { FeatureFlag } from 'baas-ui/common/featureSettings';
import { useLocalStorage } from 'baas-ui/common/hooks/use-local-storage';
import { featureSettings } from 'baas-ui/stitch_ui';

export interface Props {
  groupId: string;
}

export function localStorageKey(groupId: string) {
  return `_baas.pricing-change-banner-dismissed-${groupId}`;
}

export enum TestSelector {
  BannerContainer = 'banner-container',
}

const StyledBanner = styled(Banner)(({ theme }) => ({
  alignSelf: 'flex-start',
  width: theme.banner.width,
  margin: `${theme.banner.verticalMargin} 0`,
}));

function PricingChangeBanner({ groupId }: Props) {
  const isPricingChangeEnabled = featureSettings.useFeatureSetting(FeatureFlag.PricingChange);
  const [bannerDismissed, setBannerDismissed] = useLocalStorage(localStorageKey(groupId), false);
  if (bannerDismissed) {
    return null;
  }

  if (isPricingChangeEnabled) {
    return (
      <StyledBanner dismissible onClose={() => setBannerDismissed(true)} data-testid={TestSelector.BannerContainer}>
        <Body>
          App Services has moved to a daily free tier model. Free tier usage now resets daily instead of monthly,
          simplifying usage estimates.{' '}
          <Link
            href="https://www.mongodb.com/docs/atlas/app-services/billing/#daily-free-tier"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more
          </Link>
        </Body>
      </StyledBanner>
    );
  }

  return (
    <StyledBanner dismissible onClose={() => setBannerDismissed(true)} data-testid={TestSelector.BannerContainer}>
      <Body>App Services are free until you exceed the free tier.</Body>
      <Body>
        {`You can now see the state of your project's total usage as well as the specific usage of each of your apps.`}
      </Body>
    </StyledBanner>
  );
}

export default PricingChangeBanner;
