import { JsonObject, JsonProperty } from 'json2typescript';

import { DateConverter } from 'admin-sdk/Converter';

export enum EdgeServerStatus {
  Deleted = 'deleted',
  Unregistered = 'unregistered',
  Registered = 'registered',
  Active = 'active',
  Failed = 'failed',
}

export enum EdgePlatform {
  Unknown = '',
  DockerCompose = 'docker-compose',
  Kubernetes = 'kubernetes',
}

@JsonObject('EdgeServerInfo')
export class EdgeServerInfo {
  @JsonProperty('id')
  public id = '';

  @JsonProperty('name')
  public name = '';

  @JsonProperty('created')
  public created = new Date(0);

  @JsonProperty('user_id')
  public user_id = '';

  @JsonProperty('status')
  public status = EdgeServerStatus.Unregistered;

  @JsonProperty('hostname', String, true)
  public hostname?: string = undefined;

  @JsonProperty('last_connection', DateConverter)
  public last_connection = new Date(0);

  @JsonProperty('platform', EdgePlatform, true)
  public platform?: EdgePlatform = undefined;

  @JsonProperty('platform_details', String, true)
  public platform_details?: string = undefined;

  constructor(partial?: Partial<EdgeServerInfo>) {
    Object.assign(this, partial);
  }
}

@JsonObject('EdgeServerInfos')
export class EdgeServerInfos {
  @JsonProperty('edge_servers')
  public edge_servers: EdgeServerInfo[] = [];

  constructor(partial?: Partial<EdgeServerInfos>) {
    Object.assign(this, partial);
  }
}

@JsonObject('Metrics')
export class Metrics {
  @JsonProperty('key')
  public key = '';

  @JsonProperty('name')
  public name = '';

  @JsonProperty('category')
  public category = '';

  @JsonProperty('detail')
  public detail = '';

  @JsonProperty('value')
  public value = 0;

  @JsonProperty('unit')
  public unit = '';

  constructor(partial?: Partial<Metrics>) {
    Object.assign(this, partial);
  }
}

@JsonObject('DetailedEdgeServerInfo')
export class DetailedEdgeServerInfo extends EdgeServerInfo {
  @JsonProperty('query')
  public query: Record<string, string> | null;

  @JsonProperty('package_version')
  public package_version = '';

  @JsonProperty('latest_package_version')
  public latest_package_version = '';

  @JsonProperty('metrics')
  public metrics: Metrics[] = [];

  constructor(partial?: Partial<DetailedEdgeServerInfo>) {
    super(partial);
    this.query = null;
    Object.assign(this, partial);
  }
}

@JsonObject('CreateEdgeServerRequest')
export class CreateEdgeServerRequest {
  @JsonProperty('name')
  public name = '';

  constructor(partial?: Partial<CreateEdgeServerRequest>) {
    Object.assign(this, partial);
  }
}

@JsonObject('EdgeRegistrationKeyResponse')
export class EdgeRegistrationKeyResponse {
  @JsonProperty('id')
  public id = '';

  @JsonProperty('user_id')
  public user_id = '';

  @JsonProperty('registration_key')
  public registration_key = '';

  constructor(partial?: Partial<EdgeRegistrationKeyResponse>) {
    Object.assign(this, partial);
  }
}

@JsonObject('RenameEdgeServerRequest')
export class RenameEdgeServerRequest {
  @JsonProperty('name')
  public name = '';

  constructor(partial?: Partial<RenameEdgeServerRequest>) {
    Object.assign(this, partial);
  }
}
