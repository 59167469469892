import React, { useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from '@emotion/styled';
import Banner, { Variant } from '@leafygreen-ui/banner';
import Button from '@leafygreen-ui/button';
import { SegmentedControl, SegmentedControlOption } from '@leafygreen-ui/segmented-control';
import { Body, H2, Link as LGLink } from '@leafygreen-ui/typography';
import moment from 'moment';
import { compose } from 'redux';

import { clearAll, redirectTo as redirectToAction } from 'baas-ui/actions';
import DarkModeMarketingModal from 'baas-ui/common/components/dark-mode-marketing-modal/DarkModeMarketingModal';
import { LoadingWrapper } from 'baas-ui/common/components/loading-wrapper';
import { Title, TitleArea } from 'baas-ui/common/components/title';
import { FeatureFlag } from 'baas-ui/common/featureSettings';
import { useLocalStorage } from 'baas-ui/common/hooks/use-local-storage';
import usePoller from 'baas-ui/common/hooks/use-poller';
import useSessionStorage from 'baas-ui/common/hooks/use-session-storage';
import { docLinks } from 'baas-ui/common/links';
import { domainDeprecationBannerDismissed } from 'baas-ui/common/local-storage-keys';
import { selectedTemplateIdKey } from 'baas-ui/common/session-storage-keys';
import { GROUP_OWNER } from 'baas-ui/constants';
import * as actions from 'baas-ui/home/actions';
import {
  clearClusterProvisionToastState as clearClusterProvisionToastStateAction,
  clearDataSourceErrors as clearDataSourceErrorsAction,
} from 'baas-ui/home/actions';
import TemplateStarterAppMenu from 'baas-ui/home/apps/template-starter-apps';
import AppsUserPermissionsBanner from 'baas-ui/home/apps-user-permissions-banner';
import ActivateAppCard from 'baas-ui/home/create-app/activate-app-card';
import { getAppData } from 'baas-ui/home/sagas';
import { AppDataResult, AppProduct, appServicesVisibleProductTypes, RequestError } from 'baas-ui/home/types';
import { MeasurementsByName, UsageByMeasurement } from 'baas-ui/measurements/types';
import { metricNames, MetricUsagesByName } from 'baas-ui/metrics/types';
import { calculateTotalUsageForGroupAndAppMetrics, DEFAULT_METRICS } from 'baas-ui/metrics/utils';
import { Breadcrumbs, TopNav } from 'baas-ui/nav';
import { AsyncDispatch, AsyncDispatchPayload } from 'baas-ui/redux_util';
import { getHomeState, getSettingsState, getUserProfileState } from 'baas-ui/selectors';
import { featureSettings } from 'baas-ui/stitch_ui';
import { useDarkMode } from 'baas-ui/theme';
import { Track, track, TrackOnRender } from 'baas-ui/tracking';
import { RootState } from 'baas-ui/types';
import urls, { replaceBaseUrl } from 'baas-ui/urls';
import { GroupMetrics, MeasurementName, MetricsRequestOptions, PartialApp, RoleAssignment } from 'admin-sdk';

import AppCard from './app-card';
import AppsUsage from './apps-usage';
import DeleteAppConfirmation from './DeleteAppConfirmation';

import './apps.less';
import 'baas-ui/common/styles/banner.less';

export enum TestSelector {
  AppContainer = 'app-container',
  CreateAppButton = 'create-app',
  TemplateStarterAppsMenu = 'template-starter-apps-menu',
  ErrorBannerApps = 'error-banner-apps',
  ErrorBannerMetrics = 'error-banner-metrics',
  DarkModeModal = 'dark-mode-modal',
  DomainDeprecationBanner = 'domain-deprecation-banner',
  CloudServicesLink = 'cloud-services-link',
  DomainDeprecationDocsLink = 'domain-deprecation-docs-link',

  SortOptionAlphabetical = 'sort-option-alphabetical',
  SortOptionLastModified = 'sort-option-lastModified',
}

interface StateProps {
  apps: PartialApp[];
  groupId: string;
  recaptchaSiteKey: string;
  userIsProjectOwner: boolean;
  hasAcknowledgedDarkMode: boolean;
}

interface DispatchProps {
  deleteApp(appId: string): AsyncDispatchPayload<void>;
  loadGroupMetrics(groupId: string, options: MetricsRequestOptions): AsyncDispatchPayload<void | GroupMetrics>;
  loadApps(groupId: string, products: AppProduct[]): AsyncDispatchPayload<PartialApp[]>;
  setGroupId(groupId: string): void;
  getData(app: PartialApp, isPricingChangeEnabled: boolean): Promise<AppDataResult>;
  redirectTo(url: string, options?: { replace: boolean }): void;
  clearAppState(): void;
  clearDataSourceErrors(): void;
  clearClusterProvisionToastState(): void;
}

// this should be removed in favor of `useParams` hook later when we update react-dom
type RouteProps = RouteComponentProps<{
  groupId: string;
}>;

interface OwnProps {
  clusterStatePoller: ReturnType<typeof usePoller>;
}

export type Props = StateProps & DispatchProps & RouteProps & OwnProps;

const sectionHeaderClassname = 'section-header';
const appCardClassname = 'app-card';

export const updateAppMeasurements = (
  app: PartialApp,
  measurementsByName: MeasurementsByName,
  usageByMeasurement: UsageByMeasurement
): UsageByMeasurement => {
  return {
    [MeasurementName.ComputeTime]: {
      appMeasurements: [
        ...usageByMeasurement[MeasurementName.ComputeTime].appMeasurements,
        {
          app,
          measurement: { ...measurementsByName.compute_time },
        },
      ],
      totalUsage: usageByMeasurement[MeasurementName.ComputeTime].totalUsage + measurementsByName.compute_time.usage,
    },
    [MeasurementName.DataOut]: {
      appMeasurements: [
        ...usageByMeasurement[MeasurementName.DataOut].appMeasurements,
        {
          app,
          measurement: { ...measurementsByName.data_out },
        },
      ],
      totalUsage: usageByMeasurement[MeasurementName.DataOut].totalUsage + measurementsByName.data_out.usage,
    },
    [MeasurementName.RequestCount]: {
      appMeasurements: [
        ...usageByMeasurement[MeasurementName.RequestCount].appMeasurements,
        {
          app,
          measurement: { ...measurementsByName.request_count },
        },
      ],
      totalUsage: usageByMeasurement[MeasurementName.RequestCount].totalUsage + measurementsByName.request_count.usage,
    },
    [MeasurementName.SyncTime]: {
      appMeasurements: [
        ...usageByMeasurement[MeasurementName.SyncTime].appMeasurements,
        {
          app,
          measurement: { ...measurementsByName.sync_time },
        },
      ],
      totalUsage: usageByMeasurement[MeasurementName.SyncTime].totalUsage + measurementsByName.sync_time.usage,
    },
  };
};

enum SortCriteria {
  LastModified = 'lastModified',
  Alphabetical = 'alphabetical',
}

export const sortByLastModified = (partialApps: PartialApp[]): PartialApp[] => {
  return partialApps.sort((first, second) => second.lastModified - first.lastModified);
};

export const sortByAlphabeticalOrder = (partialApps: PartialApp[]): PartialApp[] => {
  return partialApps.sort((first, second) => first.name.localeCompare(second.name, undefined, { caseFirst: 'upper' }));
};

export const sortBySortCriteria = (partialApps: PartialApp[], sortCriteria: SortCriteria): PartialApp[] => {
  switch (sortCriteria) {
    case SortCriteria.LastModified:
      return sortByLastModified(partialApps);
    default:
      return sortByAlphabeticalOrder(partialApps);
  }
};

const StyledDomainDeprecationBanner = styled(Banner)(({ theme }) => ({
  width: theme.banner.width,
  margin: `${theme.banner.verticalMargin} 0`,
}));

const StyledDomainDeprecationHeader = styled(Body)`
  color: inherit;
  font-weight: bold;
`;

const Apps = ({
  apps,
  clearDataSourceErrors,
  clearClusterProvisionToastState,
  deleteApp,
  getData,
  groupId,
  loadApps,
  loadGroupMetrics,
  match,
  redirectTo,
  clearAppState,
  setGroupId,
  recaptchaSiteKey,
  userIsProjectOwner,
  clusterStatePoller,
  hasAcknowledgedDarkMode,
}: Props) => {
  // TODO(BAAS-7260): investigate why last used app redirect crashes react
  // const [lastUsedAppId, setLastUsedAppId] = useLocalStorage(lastUsedAppForGroupIdKey(match.params.groupId), '');
  const isBillingMigrationEnabled = featureSettings.useFeatureSetting(FeatureFlag.BillingMigration);
  const isPricingChangeEnabled = featureSettings.useFeatureSetting(FeatureFlag.PricingChange);
  const [loadAppsError, setLoadAppsError] = useState(RequestError.NoError);
  const [isLoading, setIsLoading] = useState(true);
  const [, setSelectedTemplateId] = useSessionStorage(selectedTemplateIdKey(groupId), '');
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [appToDelete, setAppToDelete] = useState({ name: '', id: '' });
  const [sortCriteria, setSortCriteria] = useState(SortCriteria.Alphabetical);

  const [usageByMeasurement, setUsageByMeasurement] = React.useState<Readonly<UsageByMeasurement>>({
    [MeasurementName.ComputeTime]: { appMeasurements: [], totalUsage: 0 },
    [MeasurementName.DataOut]: { appMeasurements: [], totalUsage: 0 },
    [MeasurementName.RequestCount]: { appMeasurements: [], totalUsage: 0 },
    [MeasurementName.SyncTime]: { appMeasurements: [], totalUsage: 0 },
  });

  const [groupTotalMetrics, setGroupTotalMetrics] = React.useState<MetricUsagesByName>({ ...DEFAULT_METRICS });
  const [appTotalMetrics, setAppTotalMetrics] = React.useState<Record<string, MetricUsagesByName>>({});
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(false);
  const [loadGroupMetricsError, setLoadGroupMetricsError] = useState(RequestError.NoError);

  const [isDomainDeprecationBannerDismissed, setIsDomainDeprecationBannerDismissed] = useLocalStorage(
    domainDeprecationBannerDismissed(match.params.groupId),
    false
  );

  const darkMode = useDarkMode();

  React.useEffect(() => {
    clearDataSourceErrors();
    clearClusterProvisionToastState();
    clearAppState();
  }, []);

  React.useEffect(() => {
    const loadDataAsync = async () => {
      // TODO use `useParam` hook after upgrading @types/react-router-dom
      if (match.params.groupId) {
        const matchedGroupId = match.params.groupId;
        setIsLoading(true);
        setLoadAppsError(RequestError.NoError);
        try {
          if (matchedGroupId !== groupId) {
            setGroupId(matchedGroupId);
          }
          await loadApps(matchedGroupId, appServicesVisibleProductTypes);
        } catch (e) {
          setLoadAppsError(e);
        } finally {
          setIsLoading(false);
        }
      }
    };
    loadDataAsync();
  }, [match.params.groupId]);

  React.useEffect(() => {
    const loadDataAsync = async () => {
      setIsLoadingMetrics(true);
      setLoadGroupMetricsError(RequestError.NoError);

      let start = moment().utc().startOf('month').toDate();
      let granularity = 'PT1H';
      if (isPricingChangeEnabled) {
        start = moment().utc().startOf('day').toDate();
        granularity = 'PT1M';
      }
      try {
        const groupMetrics = await loadGroupMetrics(match.params.groupId, {
          start,
          end: moment().utc().toDate(),
          granularity,
          metrics: metricNames,
        });
        const { groupUsages, appUsages } = calculateTotalUsageForGroupAndAppMetrics(groupMetrics);
        setGroupTotalMetrics(groupUsages);
        setAppTotalMetrics(appUsages);
      } catch (e) {
        setLoadGroupMetricsError(e);
      } finally {
        setIsLoadingMetrics(false);
      }
    };
    if (isBillingMigrationEnabled) {
      loadDataAsync();
    }
  }, [apps]);

  // TODO(BAAS-7260): Figure out why this is breaking React. Halfway through the redirect, it
  // causes React to unmount and makes a blank white page
  //
  // React.useEffect(() => {
  //   // Redirect to last used app
  //   const shouldRedirect = location.search.indexOf('redirect=false') === -1;
  //   if (!isLoading && shouldRedirect && match.params.groupId && lastUsedAppId && apps.length > 0) {
  //     // When app id is an all numeric string (happens in tests and possibly objectid), the
  //     // lastUsedAppId is converted into a number due to calling JSON.parse in `useLocalStorage`.
  //     // Use .toString to remedy this
  //     const lastUsedAppIdStr = lastUsedAppId.toString();
  //     const appExists = !!apps.find((app) => app.id === lastUsedAppIdStr);
  //     if (appExists) {
  // redirectTo(urls.groups().group(match.params.groupId).apps().app(lastUsedAppId).dashboard(), { replace: true });
  //     } else {
  //       setLastUsedAppId('');
  //     }
  //   }
  // }, [apps, isLoading]);

  const handleDeleteApp = (appId: string) => {
    setLoadAppsError(RequestError.NoError);
    deleteApp(appId)
      .then(() => {
        // reset the app and group metrics
        setGroupTotalMetrics({ ...DEFAULT_METRICS });
        setAppTotalMetrics({});
        setUsageByMeasurement({
          [MeasurementName.ComputeTime]: { appMeasurements: [], totalUsage: 0 },
          [MeasurementName.DataOut]: { appMeasurements: [], totalUsage: 0 },
          [MeasurementName.RequestCount]: { appMeasurements: [], totalUsage: 0 },
          [MeasurementName.SyncTime]: { appMeasurements: [], totalUsage: 0 },
        });
        return loadApps(groupId, appServicesVisibleProductTypes);
      })
      .catch((e) => {
        setLoadAppsError(e);
      })
      .finally(() => setIsDeleteConfirmationOpen(false));
  };

  const createAppUrl = urls.groups().group(groupId).apps().create();

  const hasApps = apps.length > 0;

  return (
    <div data-cy="apps" className="apps-wrapper">
      {hasApps && <TrackOnRender event="APPLICATION.LIST_VIEWED" />}
      <TitleArea />
      <TopNav />
      <DarkModeMarketingModal
        open={!hasAcknowledgedDarkMode}
        onRedirect={redirectTo}
        data-testid={TestSelector.DarkModeModal}
      />
      <LoadingWrapper className="apps-loading-wrapper" isLoading={isLoading || !groupId}>
        {!isLoading &&
          groupId &&
          (!hasApps && loadAppsError === RequestError.NoError ? (
            <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
              <ActivateAppCard
                showUserPermissionsBanner={!userIsProjectOwner}
                clusterStatePoller={clusterStatePoller}
              />
            </GoogleReCaptchaProvider>
          ) : (
            <div className="apps-container" data-test-selector={TestSelector.AppContainer}>
              <div className="apps">
                <Title>Apps</Title>
                <div className={sectionHeaderClassname}>
                  <Breadcrumbs />
                  <div className={`${sectionHeaderClassname} ${sectionHeaderClassname}-title`}>
                    <H2>Applications</H2>
                    <div className={`${sectionHeaderClassname}-title-controls`}>
                      <TemplateStarterAppMenu
                        data-test-selector={TestSelector.TemplateStarterAppsMenu}
                        onClickTemplate={(selectedTemplate) => {
                          setSelectedTemplateId(selectedTemplate);
                          redirectTo(createAppUrl);
                        }}
                      />
                      <Track event="APPLICATION.CREATE_NEW_CLICKED" properties={{ context: 'APPS_LIST' }}>
                        <Link to={createAppUrl}>
                          <Button
                            data-cy="create-app-button"
                            variant="primary"
                            disabled={isLoading}
                            data-test-selector={TestSelector.CreateAppButton}
                          >
                            Create a New App
                          </Button>
                        </Link>
                      </Track>
                    </div>
                  </div>
                </div>
                {!userIsProjectOwner && (
                  <AppsUserPermissionsBanner className="apps-permissions-banner banner-margin-vertical" />
                )}
                {loadAppsError !== RequestError.NoError && (
                  <Banner
                    variant="danger"
                    className="apps-permissions-banner"
                    data-testid={TestSelector.ErrorBannerApps}
                  >
                    <Body>The request to fetch all apps has failed. Please try again later.</Body>
                  </Banner>
                )}
                {loadGroupMetricsError !== RequestError.NoError && (
                  <Banner
                    variant="danger"
                    className="apps-permissions-banner"
                    data-testid={TestSelector.ErrorBannerMetrics}
                  >
                    <Body>The request to fetch metrics has failed. Please try again later.</Body>
                  </Banner>
                )}
                {!isDomainDeprecationBannerDismissed && (
                  <StyledDomainDeprecationBanner
                    dismissible
                    variant={Variant.Warning}
                    onClose={() => setIsDomainDeprecationBannerDismissed(true)}
                    data-testid={TestSelector.DomainDeprecationBanner}
                  >
                    <StyledDomainDeprecationHeader>Realm Domain Deprecation</StyledDomainDeprecationHeader>
                    realm.mongodb.com is deprecated in favor of{' '}
                    <LGLink
                      data-testid={TestSelector.CloudServicesLink}
                      href={replaceBaseUrl(window.settings.adminUrl, window.location.href)}
                      hideExternalIcon
                    >
                      services.cloud.mongodb.com
                    </LGLink>
                    . While realm.mongodb.com still continues to work, we recommend updating your bookmarks and issuing
                    API requests to services.cloud.mongodb.com for a seamless transition in the future.{' '}
                    <LGLink
                      data-testid={TestSelector.DomainDeprecationDocsLink}
                      href={docLinks.General.DomainMigration.LearnMore}
                    >
                      Learn More
                    </LGLink>
                  </StyledDomainDeprecationBanner>
                )}
                {hasApps && (
                  <>
                    <AppsUsage
                      usageByMeasurement={usageByMeasurement}
                      apps={apps}
                      groupTotalMetrics={groupTotalMetrics}
                      appTotalMetrics={appTotalMetrics}
                      isLoadingMetrics={isLoadingMetrics}
                    />
                    <SegmentedControl
                      name={'sortCriteria'}
                      aria-controls="app-sort"
                      label={'Sort By'}
                      darkMode={darkMode}
                      size={'default'}
                      onChange={(value: SortCriteria) => setSortCriteria(value)}
                    >
                      <SegmentedControlOption
                        value={SortCriteria.Alphabetical}
                        data-testid={TestSelector.SortOptionAlphabetical}
                      >
                        A-Z
                      </SegmentedControlOption>
                      <SegmentedControlOption
                        value={SortCriteria.LastModified}
                        data-testid={TestSelector.SortOptionLastModified}
                      >
                        Last Updated
                      </SegmentedControlOption>
                    </SegmentedControl>
                    <div className={`${appCardClassname}-loading-container`}>
                      <div data-cy="app-card-container" className={`${appCardClassname}-container`}>
                        {sortBySortCriteria(apps, sortCriteria)
                          .filter((app) => app.product !== 'charts')
                          .map((app: PartialApp) => (
                            <AppCard
                              app={app}
                              isLoadingMetrics={isLoadingMetrics}
                              appMetrics={appTotalMetrics[app.id] ?? { ...DEFAULT_METRICS }}
                              key={app.id}
                              onDeleteApp={() => {
                                setAppToDelete(app);
                                setIsDeleteConfirmationOpen(true);
                              }}
                              getData={getData}
                              onClearAppState={clearAppState}
                              appUrl={urls.groups().group(app.groupId).apps().app(app.id).get()}
                              environmentUrl={urls
                                .groups()
                                .group(app.groupId)
                                .apps()
                                .app(app.id)
                                .deploy()
                                .environment()}
                              onClick={() => {
                                track('APPLICATION.APPLICATION_CLICKED', {
                                  app_id: app.id,
                                  app_name: app.name,
                                  client_app_id: app.clientAppId,
                                });
                              }}
                              onGetDataComplete={(data) =>
                                setUsageByMeasurement((prev) => updateAppMeasurements(app, data, prev))
                              }
                            />
                          ))}
                      </div>
                    </div>
                    <DeleteAppConfirmation
                      appName={appToDelete.name}
                      isOpen={isDeleteConfirmationOpen}
                      onConfirmDelete={() => handleDeleteApp(appToDelete.id)}
                      onCancel={() => setIsDeleteConfirmationOpen(false)}
                    />
                  </>
                )}
              </div>
            </div>
          ))}
      </LoadingWrapper>
    </div>
  );
};

export const mapStateToProps = (state: RootState) => {
  const { apps, groupId } = getHomeState(state);
  const userProfile = getUserProfileState(state);
  const { recaptchaSiteKey, hasAcknowledgedDarkMode } = getSettingsState(state);
  const userIsProjectOwner = !!userProfile?.roles?.some(
    (role: RoleAssignment) => role.groupId === groupId && role.roleName === GROUP_OWNER
  );

  return {
    apps,
    groupId,
    recaptchaSiteKey,
    userIsProjectOwner,
    hasAcknowledgedDarkMode,
  };
};

const mapDispatchToProps = (dispatch: AsyncDispatch) => ({
  loadGroupMetrics: (groupId: string, options: MetricsRequestOptions) =>
    dispatch(actions.loadGroupMeasurements({ groupId, options })),
  loadApps: (groupId: string, products: AppProduct[]) => dispatch(actions.loadApps({ groupId, products })),
  deleteApp: (groupId: string) => (appId: string) => dispatch(actions.deleteApp({ groupId, appId })),
  setGroupId: (groupId: string) => dispatch(actions.setGroupId(groupId)),
  getData: getAppData(dispatch),
  redirectTo: (url: string, options?: { replace: boolean }) => dispatch(redirectToAction(url, options)),
  clearAppState: () => dispatch(clearAll()),
  clearDataSourceErrors: () => dispatch(clearDataSourceErrorsAction()),
  clearClusterProvisionToastState: () => dispatch(clearClusterProvisionToastStateAction()),
});

const mergeProps = (
  { groupId, ...otherStateProps }: ReturnType<typeof mapStateToProps>,
  { deleteApp, ...otherDispatchProps }: ReturnType<typeof mapDispatchToProps>,
  ownProps: RouteProps
) => ({
  ...otherStateProps,
  ...otherDispatchProps,
  ...ownProps,
  deleteApp: deleteApp(groupId),
  groupId,
});

export { Apps as AppsComponent };

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps, mergeProps))(Apps);
