import humanizeDuration from 'humanize-duration';
import { deserializeMongoDBSyncIncompatibleRoles, } from 'admin-sdk';
import { COLLECTION_SECTION_LEADING_WHITESPACE_LENGTH, COLLECTION_SECTION_LENGTH, MAX_INCOMPATIBLE_COLLECTION_NAME_LENGTH, MAX_INCOMPATIBLE_ROLE_NAME_LENGTH, MAX_ROLE_CHARS_PER_LINE, } from './constants';
import { SchemaModificationType, SchemaModificationTypeToInt, SyncNoteType, SyncSchemaAction, } from './types';
export const serviceName = (mongoSvcsById, id) => {
    const service = mongoSvcsById[id];
    if (!service) {
        return '';
    }
    if (!service.config?.clusterName) {
        return service.name;
    }
    return `${service.config.clusterName} - (Service: ${service.name})`;
};
// eventTimeDisplay converts a time in milliseconds to a human-readable form
export const eventTimeDisplay = (ms, opts = {}) => {
    opts = {
        language: 'shortEn',
        languages: {
            shortEn: {
                y: () => 'yr',
                mo: () => 'mo',
                w: () => 'wk',
                d: () => 'd',
                h: () => 'hr',
                m: () => 'min',
                s: () => 'sec',
                ms: () => 'ms',
            },
        },
        round: true,
        largest: 2,
        delimiter: ' ',
        ...opts, // allow user to override some options
    };
    const shortEnglishHumanizer = humanizeDuration.humanizer(opts);
    return shortEnglishHumanizer(ms);
};
export const eventTimeDisplayExtended = (ms, opts = {}) => {
    opts = {
        language: 'en',
        round: true,
        largest: 2,
        delimiter: ' ',
        ...opts, // allow user to override some options
    };
    const englishHumanizer = humanizeDuration.humanizer(opts);
    return englishHumanizer(ms);
};
// addIncompatibleRole adds an entry to the incompatible roles list
// corresponding to the namespace provided by the given database and collection
export const addIncompatibleRole = (incompatibleRolesByDatabase, database, collection, incompatibleRole) => {
    if (database in incompatibleRolesByDatabase) {
        const incompatibleRolesByCollection = incompatibleRolesByDatabase[database];
        if (collection in incompatibleRolesByCollection) {
            incompatibleRolesByCollection[collection].push(incompatibleRole);
        }
        else {
            incompatibleRolesByCollection[collection] = [incompatibleRole];
        }
        incompatibleRolesByDatabase[database] = incompatibleRolesByCollection;
    }
    else {
        incompatibleRolesByDatabase[database] = {
            [collection]: [incompatibleRole],
        };
    }
};
// convertToIncompatibleRolesByDatabase outputs the incompatible roles by collection
// mapping keyed by database name based on the given MongoDBSyncIncompatibleRoles
// and the list of namespaces using the default rule
export const convertToIncompatibleRolesByDatabase = (incompatibleRoles) => {
    const incompatibleRolesByDatabase = {};
    // Namespace roles
    const incompatibleNamespaceRoles = incompatibleRoles.namespaceRoles;
    if (incompatibleNamespaceRoles) {
        incompatibleNamespaceRoles.forEach((incompatibleNamespaceRole) => {
            const database = incompatibleNamespaceRole.database;
            const collection = incompatibleNamespaceRole.collection;
            const incompatibleRole = incompatibleNamespaceRole;
            // Only include incompatible roles that appear in schema
            if (incompatibleNamespaceRole.existsInSchema) {
                addIncompatibleRole(incompatibleRolesByDatabase, database, collection, incompatibleRole);
            }
        });
    }
    // Default roles
    const incompatibleDefaultRoles = incompatibleRoles.defaultRoles;
    if (incompatibleDefaultRoles) {
        incompatibleDefaultRoles.forEach((incompatibleRole) => {
            const namespacesUsingDefaultRule = incompatibleRoles.namespacesUsingDefaultRule;
            if (namespacesUsingDefaultRule) {
                Object.keys(namespacesUsingDefaultRule).forEach((database) => {
                    const collections = namespacesUsingDefaultRule[database];
                    collections.forEach((collection) => {
                        addIncompatibleRole(incompatibleRolesByDatabase, database, collection, incompatibleRole);
                    });
                });
            }
        });
    }
    return incompatibleRolesByDatabase;
};
// deserializeIncompatibleRolesJSON returns the incompatible roles by collection
// mapping keyed by database name based on the raw incompatible roles JSON for all incompatibilities
// that is provided as part of an incompatible role service change response
export const deserializeIncompatibleRolesJSON = (incompatibleRolesJSON) => {
    const syncIncompatibleRoles = deserializeMongoDBSyncIncompatibleRoles(incompatibleRolesJSON);
    return convertToIncompatibleRolesByDatabase(syncIncompatibleRoles);
};
export const parseJSONToSyncSchemaChange = (jsonData) => {
    if (!jsonData.updates || !jsonData.combined_type) {
        return null;
    }
    if (typeof jsonData.combined_type !== 'string' ||
        !Object.values(SchemaModificationType).includes(jsonData.combined_type)) {
        return null;
    }
    const schemaUpdates = [];
    jsonData.updates.forEach((item) => {
        const { action, type, table, field, description, note, note_type: noteType } = item;
        if (typeof action === 'string' &&
            Object.values(SyncSchemaAction).includes(action) &&
            typeof type === 'string' &&
            Object.values(SchemaModificationType).includes(type) &&
            typeof table === 'string' &&
            (!field || typeof field === 'string') &&
            typeof description === 'string' &&
            (!note || typeof note === 'string') &&
            (!noteType || Object.values(SyncNoteType).includes(noteType))) {
            schemaUpdates.push({
                action: action,
                type: type,
                table,
                field,
                description,
                note,
                noteType: noteType,
            });
        }
    });
    return {
        combinedType: jsonData.combined_type,
        updates: schemaUpdates,
    };
};
// truncateStr checks the given string's length, and if it exceeds the limit
// will shorten it to be the first (limit/2) characters and the last (limit/2)
// chars separated by an ellipses. If the string's length does not exceed the limit,
// then the string itself is returned
const truncateStr = (str, limit) => {
    if (str.length <= limit || limit <= 1) {
        return str;
    }
    return `${str.substring(0, limit / 2)}...${str.substring(str.length - limit / 2)}`;
};
const truncateIncompatibleCollectionName = (collectionName) => {
    return truncateStr(collectionName, MAX_INCOMPATIBLE_COLLECTION_NAME_LENGTH);
};
const truncateIncompatibleRoleName = (roleName) => {
    return truncateStr(roleName, MAX_INCOMPATIBLE_ROLE_NAME_LENGTH);
};
// getRoleNameBatches splits up the given roleNames list into batches of
// role names such that each batch contains at most a sum of
// MAX_ROLE_CHARS_PER_LINE characters
const getRoleNameBatches = (roleNames) => {
    const roleNameBatches = [];
    let currentBatchRoleNameChars = 0;
    let currentBatchStartIdx = 0;
    let currentBatch = [];
    for (let i = 0; i < roleNames.length; i++) {
        const truncatedRoleName = truncateIncompatibleRoleName(roleNames[i]);
        // Each batch must be non-empty to allow for progress to be made
        if (i !== currentBatchStartIdx && currentBatchRoleNameChars + truncatedRoleName.length > MAX_ROLE_CHARS_PER_LINE) {
            roleNameBatches.push(currentBatch);
            currentBatchRoleNameChars = 0;
            currentBatchStartIdx = i;
            currentBatch = [];
        }
        currentBatchRoleNameChars += truncatedRoleName.length;
        currentBatch.push(truncatedRoleName);
    }
    // Push the last batch if it contains data
    if (currentBatch.length > 0) {
        roleNameBatches.push(currentBatch);
    }
    return roleNameBatches;
};
// stringifyIncompatibleRolesByDatabase converts the provided incompatibleRolesByDatabase
// into a string representation of the structure, such that each entry corresponds to the string:
// Database: <database-name>
//   Collection: <collection-name> <whitespace> <comma-delimited-list-of-incompatible-role-names>
export const stringifyIncompatibleRolesByDatabase = (incompatibleRolesByDatabase) => {
    const out = [];
    const sortedDatabaseKeys = Object.keys(incompatibleRolesByDatabase).sort();
    sortedDatabaseKeys.forEach((database, idx) => {
        out.push(`Database: ${database}`);
        const incompatibleRolesByCollection = incompatibleRolesByDatabase[database];
        const sortedCollectionKeys = Object.keys(incompatibleRolesByCollection).sort();
        sortedCollectionKeys.forEach((collection) => {
            // Get role names sorted alphabetically
            const sortedRoleNames = incompatibleRolesByCollection[collection].map((role) => role.name).sort();
            let prefixCollectionStr = true;
            const roleNameBatches = getRoleNameBatches(sortedRoleNames);
            roleNameBatches.forEach((batch, batchIdx) => {
                const isLastBatch = batchIdx === roleNameBatches.length - 1;
                // No need to include the collection name if it was already done earlier
                if (!prefixCollectionStr) {
                    const leadingWhitespace = ' '.repeat(COLLECTION_SECTION_LENGTH);
                    out.push(`${leadingWhitespace}${batch.join(', ')}${isLastBatch ? '' : ','}`);
                    return;
                }
                const truncatedCollectionName = truncateIncompatibleCollectionName(collection);
                const truncatedCollectionNamePadded = truncatedCollectionName
                    .padStart(truncatedCollectionName.length + COLLECTION_SECTION_LEADING_WHITESPACE_LENGTH)
                    .padEnd(COLLECTION_SECTION_LENGTH);
                out.push(`${truncatedCollectionNamePadded}${batch.join(', ')}${isLastBatch ? '' : ','}`);
                prefixCollectionStr = false;
            });
        });
        // Add an empty line in between each database section
        if (idx < sortedDatabaseKeys.length - 1) {
            out.push('');
        }
    });
    return out.join('\n');
};
// getNumIncompatibleCollections returns the number of total collections across all
// databases in the provided incompatibleRolesByDatabase
export const getNumIncompatibleCollections = (incompatibleRolesByDatabase) => {
    let numCollections = 0;
    const databaseKeys = Object.keys(incompatibleRolesByDatabase);
    databaseKeys.forEach((database) => {
        numCollections += Object.keys(incompatibleRolesByDatabase[database]).length;
    });
    return numCollections;
};
// Returns true if the global or collection queryable fields have changed between 2 configs
export const haveQueryableFieldsChanged = (prevQBSConfig, newQBSConfig) => {
    // Check if global queryable fields have changed
    const prevGlobalQueryableFields = new Set(prevQBSConfig.globalQueryableFieldsNames || []);
    const newGlobalQueryableFields = new Set(newQBSConfig.globalQueryableFieldsNames || []);
    const setsEq = (s1, s2) => s1.size === s2.size && [...s1].every((x) => s2.has(x));
    if (!setsEq(prevGlobalQueryableFields, newGlobalQueryableFields)) {
        return true;
    }
    // Check if collection queryable fields have changed
    const prevCollectionQueryableFields = prevQBSConfig.collectionQueryableFieldsNames || {};
    const newCollectionQueryableFields = newQBSConfig.collectionQueryableFieldsNames || {};
    const prevQFCollections = Object.keys(prevCollectionQueryableFields) || [];
    const newQFCollections = Object.keys(newCollectionQueryableFields) || [];
    // If there are added/removed collection entries for collection queryable fields
    if (!setsEq(new Set(prevQFCollections), new Set(newQFCollections))) {
        return true;
    }
    // Check if the QFs are the same for each collection
    for (let i = 0; i < prevQFCollections.length; i++) {
        const coll = prevQFCollections[i];
        if (!setsEq(new Set(prevCollectionQueryableFields[coll] || []), new Set(newCollectionQueryableFields[coll] || []))) {
            return true;
        }
    }
    return false;
};
export const deepCopyQueryableFields = (qf) => {
    const globalQueryableFields = [...qf.globalQueryableFields];
    const indexedQueryableFields = [...qf.indexedQueryableFields];
    const collectionQueryableFields = {};
    Object.keys(qf.collectionQueryableFields).forEach((coll) => {
        collectionQueryableFields[coll] = [...qf.collectionQueryableFields[coll]];
    });
    return {
        globalQueryableFields,
        collectionQueryableFields,
        indexedQueryableFields,
    };
};
export const sortSchemaChanges = (a, b) => {
    // Primary sort by SchemaModificationType
    if (a.type !== b.type) {
        return SchemaModificationTypeToInt(b.type) - SchemaModificationTypeToInt(a.type);
    }
    // Secondary sort by table
    if (a.table !== b.table) {
        return a.table.localeCompare(b.table);
    }
    if (!a.field && !b.field) {
        return 0;
    }
    if (!b.field) {
        return 1;
    }
    if (!a.field) {
        return -1;
    }
    // Tertiary sort by field
    return a.field.localeCompare(b.field);
};
// getErroredOrFirstSubscription takes a list of subscriptions and returns the failed one if it exists or
// the first one with an id
export const getErroredOrFirstSubscription = (subscriptions) => {
    if (!subscriptions || subscriptions.length === 0) {
        return null;
    }
    // Find a subscription with an error if one exists
    for (let i = 0; i < subscriptions.length; i++) {
        const es = subscriptions[i];
        if (es?.id && es.error) {
            return es;
        }
    }
    // Select the first ES with an ID otherwise
    for (let i = 0; i < subscriptions.length; i++) {
        const es = subscriptions[i];
        if (es?.id) {
            return es;
        }
    }
    return null;
};
