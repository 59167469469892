import { JsonConvert } from 'json2typescript';

const jsonConvert: JsonConvert = new JsonConvert();

// createStringMapSerializers creates the serialization and deserialization functions needed for
// a @JsonConverter for a map of string to @JsonObject given a @JsonObject annotated class. We cannot
// programatically create a @JsonConverter class declaration, so this is the next best thing.
//
// Example usage:
//
// const thingMapSerializers = createStringMapSerializers(Thing);
// @JsonConverter
// class ThingMapConverter implements JsonCustomConvert<Record<string, Thing>> {
//   public serialize = thingMapSerializers.serialize;
//
//   public deserialize = thingMapSerializers.deserialize;
// }

export default function createStringMapSerializers<T>(jsonObjectClass: { new (): T }) {
  const serialize = (map: Record<string, T>): Record<string, any> =>
    Object.entries(map).reduce(
      (prev, [key, value]) => ({ ...prev, [key]: jsonConvert.serialize(value, jsonObjectClass) }),
      {}
    );
  const deserialize = (mapData: Record<string, any>): Record<string, T> =>
    Object.entries(mapData).reduce(
      (prev, [key, value]) => ({ ...prev, [key]: jsonConvert.deserialize(value, jsonObjectClass) }),
      {}
    );
  return { serialize, deserialize };
}
