import React from 'react';
import Box from '@leafygreen-ui/box';
import Button from '@leafygreen-ui/button';
import { Menu, MenuItem, MenuProps } from '@leafygreen-ui/menu';
import { Justify } from '@leafygreen-ui/popover';
import Tooltip from '@leafygreen-ui/tooltip';
import BrandIcon from '@mongodb-js/brand-icons';

import useClickOutsideListener from 'baas-ui/common/hooks/use-click-outside-handler';
import useTemplates from 'baas-ui/home/common/hooks/use-templates';
import { templatesNotAvailableErrorMessage } from 'baas-ui/home/constants';
import { TemplateAppNames } from 'baas-ui/home/create-app/constants';
import { TemplateIdentifier } from 'baas-ui/home/create-app/types';
import { useDarkMode } from 'baas-ui/theme';
import { track } from 'baas-ui/tracking';

import './tsa-advanced-menu.less';

export enum TestSelector {
  TemplateAppMenu = 'template-app-menu',
  WebSDKTodo = 'web-sdk-todo',
  SyncTodo = 'sync-todo',
  MenuButton = 'menu-button',
  DisabledTooltip = 'disabled-tooltip',
}

const templateAppsClassName = 'template-apps';
const itemClassName = `${templateAppsClassName}-item`;
const buttonDisplay = 'Create App from Template';

export interface Props {
  justify: MenuProps['justify'];
  onClickTemplate?(template: TemplateIdentifier): void;
}

export const TemplateStarterAppMenu = ({ onClickTemplate = () => {}, justify }: Props) => {
  const darkMode = useDarkMode();
  const [disabled, setDisabled] = React.useState(false);
  const [templates, templatesLoading, templatesError, loadTemplates] = useTemplates();

  const [isOpen, setOpen] = React.useState(false);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  useClickOutsideListener(wrapperRef, () => setOpen(false));

  React.useEffect(() => {
    loadTemplates();
  }, []);

  React.useEffect(() => {
    setDisabled(false);

    if (templatesError || templates.length === 0) {
      setDisabled(true);
    }
  }, [templates, templatesError]);

  return (
    <div data-cy="template-app-menu" className={`${templateAppsClassName}-container`}>
      <div ref={wrapperRef}>
        <Menu
          data-test-selector={TestSelector.TemplateAppMenu}
          data-cy={TestSelector.TemplateAppMenu}
          open={isOpen}
          justify={justify}
          usePortal={false}
          className={`${templateAppsClassName}-menu`}
          trigger={
            <Box
              onMouseEnter={() => setTooltipOpen(templatesLoading || disabled)}
              onMouseLeave={() => setTooltipOpen(false)}
            >
              <Button
                variant="primaryOutline"
                disabled={templatesLoading || disabled}
                data-test-selector={TestSelector.MenuButton}
                data-cy={TestSelector.MenuButton}
                onClick={() => {
                  track('APPLICATION.TEMPLATE_STARTER_MENU_CLICKED');
                  setOpen(!isOpen);
                }}
              >
                {buttonDisplay}
              </Button>
              <Tooltip
                open={tooltipOpen}
                align="bottom"
                justify="middle"
                data-test-selector={TestSelector.DisabledTooltip}
              >
                {templatesNotAvailableErrorMessage}
              </Tooltip>
            </Box>
          }
        >
          <MenuItem
            data-test-selector={TestSelector.SyncTodo}
            data-cy="sync-todo"
            onClick={() => {
              track('APPLICATION.TEMPLATE_STARTER_APP_CLICKED', { templateId: TemplateIdentifier.SyncTodo });
              onClickTemplate(TemplateIdentifier.SyncTodo);
            }}
            size="large"
            className={itemClassName}
            glyph={<BrandIcon iconName="Technical_REALM_Sync" height={40} width={40} isDarkMode={!darkMode} />}
            description="Mobile app using an Atlas Device SDK to sync data to your backend"
          >
            {TemplateAppNames[TemplateIdentifier.SyncTodo]}
          </MenuItem>
          <MenuItem
            data-test-selector={TestSelector.WebSDKTodo}
            data-cy="web-sdk-todo"
            onClick={() => {
              track('APPLICATION.TEMPLATE_STARTER_APP_CLICKED', { templateId: TemplateIdentifier.WebMQLTodo });
              onClickTemplate(TemplateIdentifier.WebMQLTodo);
            }}
            size="large"
            className={itemClassName}
            glyph={<BrandIcon iconName="Technical_REALM_SDK" height={40} width={40} isDarkMode={!darkMode} />}
            description="Todo web app using the Realm Web SDK"
          >
            {TemplateAppNames[TemplateIdentifier.WebMQLTodo]}
          </MenuItem>
          <MenuItem
            data-cy="triggers-database"
            onClick={() => {
              track('APPLICATION.TEMPLATE_STARTER_APP_CLICKED', { templateId: TemplateIdentifier.TriggersDatabase });
              onClickTemplate(TemplateIdentifier.TriggersDatabase);
            }}
            size="large"
            className={itemClassName}
            glyph={<BrandIcon iconName="Technical_ATLAS_Triggers" height={40} width={40} isDarkMode={!darkMode} />}
            description="Event-driven Database Trigger template to update a view in a separate collection"
          >
            {TemplateAppNames[TemplateIdentifier.TriggersDatabase]}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

TemplateStarterAppMenu.defaultProps = {
  justify: Justify.End,
};

export default TemplateStarterAppMenu;
