import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { connect } from 'react-redux';
import Toast, { Variant } from '@leafygreen-ui/toast';
import * as baseActions from 'baas-ui/actions';
import * as dataAPIActions from 'baas-ui/endpoints/data-api/actions';
import { getAppState } from 'baas-ui/selectors';
import rootUrl from 'baas-ui/urls';
import './data-api-enabled-toast.less';
const baseClassName = 'data-api-enabled-toast';
export var TestSelector;
(function (TestSelector) {
    TestSelector["RulesLink"] = "rules-link";
    TestSelector["ToastBody"] = "toast-body";
})(TestSelector || (TestSelector = {}));
export const DataAPIEnabledToast = ({ open, rulesURL, hideDataAPIEnabledToast, redirectTo }) => {
    return (_jsx(Toast, { "data-cy": "data-api-enabled-toast", className: baseClassName, variant: Variant.Success, title: "Data API Enabled", body: _jsxs("span", { className: `${baseClassName}-body`, "data-testid": TestSelector.ToastBody, children: [_jsx("span", { className: `${baseClassName}-body-description`, children: "Continue building your app by setting up Rules" }), _jsx("span", { "data-testid": TestSelector.RulesLink, className: `${baseClassName}-body-link`, onClick: () => redirectTo(rulesURL), children: "GO TO RULES" })] }), open: open, close: hideDataAPIEnabledToast }));
};
const mapStateToProps = (state) => {
    const { app: { groupId, id: appId }, } = getAppState(state);
    const open = state.dataAPI.isDataAPIEnabledToastOpen;
    const appUrl = rootUrl.groups().group(groupId).apps().app(appId);
    const rulesURL = appUrl.rules().list();
    return { open, rulesURL };
};
const mapDispatchToProps = (dispatch) => ({
    redirectTo: (url) => dispatch(baseActions.redirectTo(url)),
    hideDataAPIEnabledToast: () => dispatch(dataAPIActions.hideDataAPIEnabledToast()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DataAPIEnabledToast);
