import { createAction } from 'redux-act';
import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { AllowedAsymmetricTables, AllowedQueryableFields, PatchSyncSchemasRequest, PutSyncMigrationRequest, } from 'admin-sdk';
const NAME = 'sync ';
export const setDevelopmentModeEnabled = createAction(`${NAME}set development mode enabled`);
export const setDataServiceId = createAction(`${NAME}set data serviceId`);
export const setConfigServiceId = createAction(`${NAME}set config serviceId`);
export const setPermissionsError = createAction(`${NAME}set permissions error`);
export const setFlexiblePermissionsError = createAction(`${NAME}set flexible permissions error`);
export const setPauseSyncInProgress = createAction(`${NAME}set pause sync in progress`);
export const setTerminateSyncInProgress = createAction(`${NAME}set terminate sync in progress`);
export const setPauseSyncError = createAction(`${NAME}set pause sync error`);
export const setTerminateSyncError = createAction(`${NAME}set terminate sync error`);
export const setSyncEventSubscriptionError = createAction(`${NAME}set sync event subscription error`);
export const setSyncEnabledToastState = createAction(`${NAME}set sync enabled status`);
export const [loadConfigActions, loadConfig] = createActionsAndExecutor(`${NAME}load config`, (client, { groupId, appId }) => () => client.apps(groupId).app(appId).sync().config().get());
export const [updateConfigActions, updateConfig] = createActionsAndExecutor(`${NAME}update config`, (client, { groupId, appId, config }) => () => {
    return client.apps(groupId).app(appId).sync().config().update(config);
});
export const [loadClientSchemasActions, loadClientSchemas] = createActionsAndExecutor(`${NAME}load client schemas`, (client, { groupId, appId, language, filter, schemaVersion }) => () => {
    return client.apps(groupId).app(appId).sync().clientSchemas().get(language, filter, schemaVersion);
});
export const setProgressPollCount = createAction(`${NAME}set progress poll count`);
export const dismissProgress = createAction(`${NAME}dismiss progress`);
export const [loadProgressActions, loadProgress] = createActionsAndExecutor(`${NAME}load progress`, (client, { appId, groupId }) => () => client.apps(groupId).app(appId).sync().progress());
export const [loadClusterStorageMetrics, getClusterStorageMetrics] = createActionsAndExecutor(`${NAME}load cluster storage metrics`, (client, { groupId, clusterName }) => () => client.private().group(groupId).atlasClusters().cluster(clusterName).storageMetrics().get());
export const [loadDataActions, loadData] = createActionsAndExecutor(`${NAME}load data`, (client, { groupId, appId, serviceId }) => () => client.apps(groupId).app(appId).sync().data(serviceId));
export const [patchSchemasActions, patchSchemas] = createActionsAndExecutor(`${NAME}patch schemas`, (client, { groupId, appId, serviceId, partitionKey, partitionKeyType, partitionKeyRequired }) => () => client.apps(groupId).app(appId).sync().schemas().patch(new PatchSyncSchemasRequest({
    serviceId,
    partitionKey,
    partitionKeyType,
    partitionKeyRequired,
})));
export const [loadQueryableFieldsAction, loadQueryableFields] = createActionsAndExecutor(`${NAME}get queryable fields`, (client, { groupId, appId, serviceId }) => () => {
    if (!serviceId) {
        return Promise.resolve(new AllowedQueryableFields());
    }
    return client.apps(groupId).app(appId).sync().allowedQueryableFields(serviceId);
});
export const [loadAsymmetricTablesAction, loadAsymmetricTables] = createActionsAndExecutor(`${NAME}get asymmetric tables`, (client, { groupId, appId, serviceId }) => () => {
    if (!serviceId) {
        return Promise.resolve(new AllowedAsymmetricTables());
    }
    return client.apps(groupId).app(appId).sync().allowedAsymmetricTables(serviceId);
});
export const [getSyncStateAction, getSyncState] = createActionsAndExecutor(`${NAME}get sync state`, (client, { appId, groupId, syncType }) => () => client.apps(groupId).app(appId).sync().state().get(syncType));
export const setMigrationStatusPollCount = createAction(`${NAME}set migration status poll count`);
export const [loadSyncMigrationPrecheckAction, loadSyncMigrationPrecheck] = createActionsAndExecutor(`${NAME}load migration precheck`, (client, { appId, groupId }) => () => client.apps(groupId).app(appId).sync().migration().precheck());
export const [loadSyncMigrationStatusAction, loadSyncMigrationStatus] = createActionsAndExecutor(`${NAME}load migration status`, (client, { appId, groupId }) => () => client.apps(groupId).app(appId).sync().migration().status());
export const [putSyncMigrationAction, putSyncMigration] = createActionsAndExecutor(`${NAME}put migration`, (client, { groupId, appId, serviceId, action }) => () => client.apps(groupId).app(appId).sync().migration().put(new PutSyncMigrationRequest({ serviceId, action })));
export const [loadSchemaVersionsAction, loadSchemaVersions] = createActionsAndExecutor(`${NAME}load schema versions`, (client, { appId, groupId }) => () => client.apps(groupId).app(appId).sync().schemas().versions().get());
export const [getSyncAlertsAction, getSyncAlerts] = createActionsAndExecutor(`${NAME}get sync alerts`, (client, req) => () => client.apps(req.groupId).app(req.appId).sync().alerts().get());
