import React, { useState } from 'react';
import Button from '@leafygreen-ui/button';
import { palette } from '@leafygreen-ui/palette';
import TextInput from '@leafygreen-ui/text-input';
import { Body, Disclaimer, H3, Link, Subtitle } from '@leafygreen-ui/typography';

import Collapsible from 'baas-ui/common/components/collapsible';
import DataSourceSelect from 'baas-ui/common/components/data-source-select';
import { DataSourceSelectOptionType, DataSourceType } from 'baas-ui/common/components/data-source-select';
import DocLink from 'baas-ui/common/components/doc-link';
import { FormRow } from 'baas-ui/common/components/form-row';
import IndexFormRowLabelGroup from 'baas-ui/common/components/index-form-row-label-group';
import LeafygreenRouterLink from 'baas-ui/common/components/leafygreen-router-link';
import { LoadingWrapper } from 'baas-ui/common/components/loading-wrapper';
import { SpinnerCircle } from 'baas-ui/common/components/spinner';
import { PRODUCT_SELF_HOSTED } from 'baas-ui/common/constants';
import { docLinks } from 'baas-ui/common/links';
import EnvironmentSelect from 'baas-ui/deploy/environment-page/environment-select/EnvironmentSelect';
import GithubAuthorizationButton from 'baas-ui/deploy/github-authorization-button/';
import GithubSelector from 'baas-ui/deploy/github-selector';
import { CreateAppProps } from 'baas-ui/home/common/withCreateApp';
import { providerRegionToLocation } from 'baas-ui/home/constants';
import ClusterSelectionRadio from 'baas-ui/home/create-app/cluster-selection-radio';
import ApplicationRegionSelection from 'baas-ui/home/create-app/form/application-region-selection';
import {
  dataSourceOptionIsEnabled,
  dataSourceOptionSupportsSync,
  processDataSourceOpts,
  setDefaultDataSourceConfig,
} from 'baas-ui/home/create-app/form/util';
import { TemplateIdentifier } from 'baas-ui/home/create-app/types';
import { DataSourceLinkMethod, DefaultDataSourceServiceName } from 'baas-ui/home/types';
import { validateAppName } from 'baas-ui/home/validation';
import { Track } from 'baas-ui/tracking';
import { GITHUB_APP_URL } from 'baas-ui/urls';
import { DeploymentModel } from 'admin-sdk';

import './create-default-app-form.less';

export enum TestSelector {
  AdvancedConfiguration = 'advanced-configuration',
  AppNameInput = 'app-name-input',
  AppNameInputError = 'app-name-input-error',
  DataSourcesFormRow = 'data-sources-form-row',
  DataSourceSelectLoadingWrapper = 'data-source-select-loading-wrapper',
  DataSourceInfo = 'clusters-info',
  NoDataSourceWarning = 'no-clusters-warning',
  ClusterRecommendation = 'cluster-recommendation',
  GithubConnect = 'connect-to-github',
  InstallAppServices = 'install-app-services-button',
  DataSourceLinkMethodRadio = 'data-source-link-method-radio',
  DataSources = 'data-sources',
}

const formClassName = `create-app-form`;
const formRowClassName = `${formClassName}-row`;
const titleTextClassName = `${formClassName}-title-text`;

interface PublicProps {
  onChangeDeploymentModel(): void;
  onConfirmProviderRegion(): void;
  onChangeProviderRegion(): void;
  onCancelProviderRegion(): void;
}

export type Props = CreateAppProps & PublicProps;

export const CreateDefaultAppFormComponent = ({
  isLoadingApps,
  defaultAppName,
  appName,
  setAppName,
  appNameError,
  setAppNameError,
  setTemplateId,
  appEnvironment,
  setAppEnvironment,
  deploymentModel,
  setDeploymentModel,
  setLocation,
  defaultProviderRegion,
  providerRegion,
  setProviderRegion,
  isCreatingApp,
  createAppError,
  product,
  loadingDataSources,
  m0Version,
  clusterOpts,
  atlasClustersUrl,
  dataLakeOpts,
  onlineArchiveOpts,
  serverlessInstanceOpts,
  dataSourceLinkMethod,
  setDataSourceLinkMethod,
  selectedDataSource,
  setSelectedDataSource,
  hasAuthorizationError,
  onGithubAuth,
  isGithubLinked,
  onUnlinkClick,
  isLoadingInstallations,
  deployInstallations,
  repositoryOptions,
  isLoadingBranches,
  branchOptions,
  repository,
  setRepository,
  branch,
  setBranch,
  directory,
  setDirectory,
  onChangeDeploymentModel,
  onCancelProviderRegion,
  onConfirmProviderRegion,
  onChangeProviderRegion,
}: Props) => {
  const [isAdvancedConfigOpen, setAdvancedConfigOpen] = useState(false);
  const [isGithubLinkOpen, setGithubLinkOpen] = useState(isGithubLinked);
  const [isAuthEnabled, setAuthEnabled] = useState(deployInstallations.length > 0);
  const [defaultDataSourceName, setDefaultDataSourceName] = React.useState<string>('');
  const [clusterWithOutdatedVersionSelected, setClusterWithOutdatedVersionSelected] = React.useState<boolean>(false);
  const [showRegionRecommendationInfo, setShowRegionRecommendationInfo] = useState(true);

  const {
    m0ClusterOpt,
    hasM0Cluster,
    enabledClusterOpts,
    enabledDataLakeOpts,
    enabledOnlineArchiveOpts,
    enabledServerlessInstanceOpts,
    numEnabledDataSources,
  } = processDataSourceOpts({
    clusterOpts,
    dataLakeOpts,
    onlineArchiveOpts,
    serverlessInstanceOpts,
    templateId: TemplateIdentifier.Default,
  });

  React.useEffect(() => {
    if (isGithubLinked) {
      setGithubLinkOpen(isGithubLinked);
    }
  }, [isGithubLinked]);

  React.useEffect(() => {
    setAuthEnabled(deployInstallations.length > 0);
  }, [deployInstallations]);

  React.useEffect(() => {
    setTemplateId(TemplateIdentifier.Default);
  }, []);

  React.useEffect(() => {
    setDefaultDataSourceName(
      selectedDataSource?.type === DataSourceType.DATA_FEDERATION
        ? DefaultDataSourceServiceName.DataLake
        : DefaultDataSourceServiceName.Cluster
    );

    setClusterWithOutdatedVersionSelected(
      selectedDataSource?.type === DataSourceType.CLUSTER && !dataSourceOptionSupportsSync(selectedDataSource)
    );
  }, [selectedDataSource]);

  React.useEffect(() => {
    setDefaultDataSourceConfig({
      m0ClusterOpt,
      enabledClusterOpts,
      enabledDataLakeOpts,
      enabledOnlineArchiveOpts,
      enabledServerlessInstanceOpts,
      setSelectedDataSource,
      dataSourceLinkMethod,
      setDataSourceLinkMethod,
    });
  }, [clusterOpts, dataLakeOpts, serverlessInstanceOpts, createAppError]);

  const onGithubAuthClick = (isAuthorized: boolean) => (isAuthorized ? onUnlinkClick() : onGithubAuth());

  const handleGithubInstallClick = () => {
    window.open(GITHUB_APP_URL, '_blank');
    setAuthEnabled(true);
  };

  return (
    <>
      <FormRow data-cy="create-default-app-form" className={`${formRowClassName}-content`} noHeader noBorder noWrap>
        <IndexFormRowLabelGroup index={1} wide>
          <H3 className={`${formClassName}-header`}>Name</H3>
          <TextInput
            className={`${formClassName}-input`}
            data-cy={'create-app-name-field'}
            aria-labelledby="app-title"
            data-test-selector={TestSelector.AppNameInput}
            placeholder={defaultAppName}
            errorMessage={appNameError}
            state={appNameError ? 'error' : 'none'}
            value={appName}
            onChange={({ target: { value } }) => {
              setAppName(value);
              setAppNameError(validateAppName(value));
            }}
            disabled={isCreatingApp || isLoadingApps}
            description="This name will be used internally and cannot be changed later."
          />
          <Disclaimer className={`${formClassName}-bottom-disclaimer`}>
            Names must only include: ASCII letters, numbers, _ -
          </Disclaimer>
        </IndexFormRowLabelGroup>
      </FormRow>
      {product !== PRODUCT_SELF_HOSTED && (
        <FormRow
          data-test-selector={TestSelector.DataSourcesFormRow}
          className={`${formRowClassName}-content`}
          noHeader
          noBorder
          noWrap
        >
          <IndexFormRowLabelGroup index={2} wide>
            <H3 className={`${formClassName}-header`}>Link your Database</H3>
            <LoadingWrapper
              data-test-selector={TestSelector.DataSourceSelectLoadingWrapper}
              isLoading={loadingDataSources}
            >
              <>
                <Body className={`${formClassName}-description-text`}>
                  App Services securely stores your application data in your linked MongoDB Atlas cluster(s).
                </Body>
                <>
                  {loadingDataSources ? (
                    <SpinnerCircle />
                  ) : (
                    <ClusterSelectionRadio
                      setDataSourceLinkMethod={setDataSourceLinkMethod}
                      dataSourceLinkMethod={dataSourceLinkMethod}
                      createClusterDisabled={hasM0Cluster}
                      useExistingDisabled={!numEnabledDataSources}
                      data-test-selector={TestSelector.DataSourceLinkMethodRadio}
                      m0Version={m0Version}
                    />
                  )}
                  {clusterWithOutdatedVersionSelected && dataSourceLinkMethod === DataSourceLinkMethod.UseExisting && (
                    <Body
                      className={`${formClassName}-input-description`}
                      data-test-selector={TestSelector.ClusterRecommendation}
                    >
                      You will need to run MongoDB 4.4+ or&nbsp;
                      <Link href={`${atlasClustersUrl}/pathSelector`}>build a new Atlas cluster</Link>
                      &nbsp;to use Device Sync.
                    </Body>
                  )}
                  <div className={`${formClassName}-select`}>
                    <DataSourceSelect
                      id="createAppDataSourceSelect"
                      value={selectedDataSource}
                      onChange={(opt: DataSourceSelectOptionType) => setSelectedDataSource(opt)}
                      isDisabled={
                        isCreatingApp ||
                        [clusterOpts, dataLakeOpts, onlineArchiveOpts, serverlessInstanceOpts].every(
                          (dataSourceOpt) => dataSourceOpt.length === 0
                        )
                      }
                      data-test-selector={TestSelector.DataSources}
                      options={[...clusterOpts, ...dataLakeOpts, ...onlineArchiveOpts, ...serverlessInstanceOpts]}
                      enabledOptions={[
                        ...enabledClusterOpts,
                        ...enabledDataLakeOpts,
                        ...enabledOnlineArchiveOpts,
                        ...enabledServerlessInstanceOpts,
                      ]}
                    />
                  </div>
                  {!loadingDataSources && selectedDataSource && (
                    <Disclaimer
                      className={`${formClassName}-bottom-disclaimer`}
                      data-test-selector={TestSelector.DataSourceInfo}
                    >
                      Use <b>{defaultDataSourceName}</b> as the service name when referring to this data source in your
                      app&apos;s Functions or SDKs.
                    </Disclaimer>
                  )}
                  {!numEnabledDataSources && hasM0Cluster && !dataSourceOptionIsEnabled(m0ClusterOpt) && (
                    <Disclaimer
                      className={`${formClassName}-bottom-disclaimer`}
                      style={{ color: palette.yellow.dark2 }}
                      data-test-selector={TestSelector.NoDataSourceWarning}
                    >
                      No eligible data sources are available at this time. Please create a new data source in{' '}
                      <LeafygreenRouterLink target="_blank" to={atlasClustersUrl}>
                        Atlas
                      </LeafygreenRouterLink>
                      , or create the app now and link a data source later.
                    </Disclaimer>
                  )}
                </>
              </>
            </LoadingWrapper>
          </IndexFormRowLabelGroup>
        </FormRow>
      )}
      <Collapsible
        data-test-selector={TestSelector.GithubConnect}
        className={`${formRowClassName} ${formClassName}--collapsible`}
        open={isGithubLinkOpen}
        toggle={() => setGithubLinkOpen(!isGithubLinkOpen)}
        wide
        accordion
        headerContent={
          <>
            <span>Connect to GitHub</span>
            <div className={`${formClassName}-optional-tag`}>(Optional)</div>
          </>
        }
      >
        <Body className={`${formClassName}--collapsible-content`}>
          {`Allow automatic deployments to your preferred GitHub branch. 
              You'll need to install App Services on GitHub to utilize this functionality.`}
        </Body>
        <div className={`${formClassName}--collapsible-section`}>
          <FormRow noHeader noBorder>
            <IndexFormRowLabelGroup index={1}>
              <Track event="DEPLOY_CONFIGURATION.GITHUB_INSTALL_CLICKED">
                <Button
                  data-test-selector={TestSelector.InstallAppServices}
                  size="xsmall"
                  onClick={handleGithubInstallClick}
                >
                  Install App Services on Github
                </Button>
              </Track>
            </IndexFormRowLabelGroup>
          </FormRow>
          <FormRow noHeader noBorder>
            <IndexFormRowLabelGroup index={2}>
              <GithubAuthorizationButton
                isAuthorized={isGithubLinked}
                onGithubAuthClick={() => onGithubAuthClick(isGithubLinked)}
                hasAuthorizationError={hasAuthorizationError}
                disabled={!isAuthEnabled}
              />
            </IndexFormRowLabelGroup>
          </FormRow>
          <FormRow noHeader noBorder noWrap>
            <IndexFormRowLabelGroup index={3} wide>
              <GithubSelector
                isGithubLinked={isGithubLinked}
                onRepositorySelect={setRepository}
                onBranchSelect={setBranch}
                onDirectoryChange={setDirectory}
                repository={repository}
                branch={branch}
                directory={directory}
                branchOptions={branchOptions}
                repositoryOptions={repositoryOptions}
                isLoadingInstallations={isLoadingInstallations}
                isLoadingBranches={isLoadingBranches}
              />
            </IndexFormRowLabelGroup>
          </FormRow>
        </div>
      </Collapsible>
      <Collapsible
        data-test-selector={TestSelector.AdvancedConfiguration}
        className={`${formRowClassName} ${formClassName}--collapsible`}
        open={isAdvancedConfigOpen}
        toggle={() => setAdvancedConfigOpen(!isAdvancedConfigOpen)}
        headerContent={
          <>
            <span>Advanced Configuration</span>
            <div className={`${formClassName}-optional-tag`}>(Optional)</div>
          </>
        }
        wide
        accordion
      >
        <div className={`${formClassName}--collapsible-content`}>
          <div className={`${formClassName}--collapsible-section`}>
            <Subtitle className={titleTextClassName}>Application Region</Subtitle>
            <Body className={`${formClassName}--collapsible-section-description`}>
              You can change this later in your Application Settings.{' '}
              <DocLink href={docLinks.General.DeploymentModels}>View Docs</DocLink>
            </Body>
            <ApplicationRegionSelection
              deploymentModel={deploymentModel}
              onChangeDeploymentModel={(depModel: DeploymentModel) => {
                setDeploymentModel(depModel);

                // Reset to the closest provider region so that users know the
                // suggested provider region
                setProviderRegion(defaultProviderRegion);
                setShowRegionRecommendationInfo(true);
                onChangeDeploymentModel();
              }}
              onConfirm={onConfirmProviderRegion}
              onChange={onChangeProviderRegion}
              onCancel={onCancelProviderRegion}
              providerRegion={providerRegion}
              setProviderRegion={(selectedProviderRegion) => {
                setProviderRegion(selectedProviderRegion);
                setLocation(providerRegionToLocation[selectedProviderRegion]);
              }}
              showRegionRecommendationInfo={showRegionRecommendationInfo}
              setShowRegionRecommendationInfo={setShowRegionRecommendationInfo}
              disabled={isCreatingApp}
            />
          </div>
          <div className={`${formClassName}--collapsible-section`}>
            <Subtitle className={titleTextClassName}>Select an Environment</Subtitle>
            <EnvironmentSelect
              selectedEnvironment={appEnvironment}
              setSelectedEnvironment={setAppEnvironment}
              isDisabled={isCreatingApp}
            />
          </div>
        </div>
      </Collapsible>
    </>
  );
};

export default CreateDefaultAppFormComponent;
