import { composeWithDevTools } from '@redux-devtools/extension';
import heliotrope from 'heliotrope';
import { createBrowserHistory, createMemoryHistory, History } from 'history';
import { Map } from 'immutable';
import { applyMiddleware, compose, createStore, Middleware, Store } from 'redux';
import { mergePersistedState } from 'redux-localstorage';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import deployMiddleware from 'baas-ui/common/middleware/deployMiddleware';
import saveMiddleware from 'baas-ui/common/middleware/saveMiddleware';
import { BaasAdminClient } from 'admin-sdk';

import FeatureSettings from './common/featureSettings';
import { browserAuthNEnabled } from './common/local-storage-keys';
import { setHistory } from './actions';
import { preventNavHistoryArgs } from './nav';
import rootReducer from './reducers';
import { performUserProfileInitActions } from './sagas';
import { hasServicesInUrl } from './urls';
import { getHeliotropeEnvironment } from './util';

// This is a mutable export because this value should be a global singleton
// available to the stitch UI application. Whenever code would like to check
// feature flags, they should import this singleton to perform lookups.
// eslint-disable-next-line import/no-mutable-exports
export let featureSettings: FeatureSettings;

const ENV_PROD = 'production';

export default class StitchUI {
  middlewares: Middleware[];

  adminClient: BaasAdminClient;

  unlisteners: { (): void }[];

  history: History;

  env: string;

  settings: Record<string, any>;

  store: Store;

  setupLoggerMiddleware() {
    const logger = createLogger({
      collapsed: (_getState, _action, logEntry) => !logEntry?.error,
    });
    this.middlewares.push(logger);
  }

  handleIntercom(adminClient: BaasAdminClient) {
    let bootOnce = false;
    const intercomHandler = () => {
      if (!bootOnce && this.adminClient.authedId()) {
        bootOnce = true;

        const { environmentDesc = '' } = window.settings;
        // TODO: Refactor into common way to store global settings
        heliotrope
          .getAuid({
            environment: getHeliotropeEnvironment(environmentDesc),
          })
          .then((auid) =>
            adminClient
              .private()
              .spa()
              .settings()
              .global(auid ?? undefined)
              .then((globalSettings) => {
                const intercomSettings: Record<string, any> = {
                  app_id: globalSettings.intercomAppId,
                  user_id: auid,
                  widget: {
                    activator: '#IntercomDefaultWidget',
                  },
                };
                if (globalSettings.intercomUserHash !== undefined) {
                  intercomSettings.user_hash = globalSettings.intercomUserHash;
                }
                window.Intercom('boot', intercomSettings);
              })
              .catch(() => {})
          );
      }
      if (bootOnce) {
        window.Intercom('update');
      }
    };
    intercomHandler();

    this.unlisteners.push(
      this.history.listen(() => {
        intercomHandler();
      })
    );
  }

  constructor(adminClient: BaasAdminClient, env = ENV_PROD, settings = {}) {
    this.env = env;
    this.settings = settings;
    this.unlisteners = [];
    this.adminClient = adminClient;

    featureSettings = new FeatureSettings();
    this.middlewares = [thunk, deployMiddleware, saveMiddleware];

    let composeEnhancers;
    if (this.env !== ENV_PROD) {
      this.setupLoggerMiddleware();
      composeEnhancers = composeWithDevTools || compose;
    } else {
      composeEnhancers = compose;
    }

    const reducers = compose(
      mergePersistedState((is, ps) => {
        return {
          ...is,
          hosting: {
            assets: is.hosting.assets,
            config: is.hosting.config.merge(
              Map({ hostingEnabledAt: ps.hosting && ps.hosting.config.hostingEnabledAt })
            ),
          },
          service: {
            ...is.service,
            serviceTypes: {
              ...is.service.serviceTypes,
              mongodb: {
                ...is.service.serviceTypes.mongodb,
              },
            },
          },
        };
      })
    )(rootReducer);

    this.store = createStore(
      reducers,
      { base: { adminClient: this.adminClient, settings: this.settings } },
      composeEnhancers(applyMiddleware(...this.middlewares))
    );

    const isLegacyAuthed = this.adminClient.authedId();
    const isBrowserAuthN =
      hasServicesInUrl(window.location.href) &&
      (localStorage.getItem(browserAuthNEnabled()) || window.settings?.isBrowserAuthNEnabled);
    if (isLegacyAuthed || isBrowserAuthN) {
      this.store.dispatch(performUserProfileInitActions()).catch(() => {});
    }

    this.history =
      typeof window !== 'undefined'
        ? createBrowserHistory(preventNavHistoryArgs)
        : createMemoryHistory(preventNavHistoryArgs);
    this.store.dispatch(setHistory(this.history));
  }

  close() {
    this.unlisteners.forEach((unlistener) => unlistener());
  }
}
