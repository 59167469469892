import React from 'react';
import Banner from '@leafygreen-ui/banner';
import Button, { Variant } from '@leafygreen-ui/button';
import Card from '@leafygreen-ui/card';
import { Body, H3 } from '@leafygreen-ui/typography';
import classNames from 'classnames';

import DocLink from 'baas-ui/common/components/doc-link';
import { useLocalStorage } from 'baas-ui/common/hooks/use-local-storage';
import usePoller from 'baas-ui/common/hooks/use-poller';
import { docLinks } from 'baas-ui/common/links';
import { isFirstAppCreatedOnGroup } from 'baas-ui/common/local-storage-keys';
import AppsUserPermissionsBanner from 'baas-ui/home/apps-user-permissions-banner';
import withCreateApp, { WithCreateAppProps } from 'baas-ui/home/common/withCreateApp';
import { CreateAppErrors } from 'baas-ui/home/constants';
import CreateAppFooter, { Props as FooterProps } from 'baas-ui/home/create-app/footer';
import CreateAppForm from 'baas-ui/home/create-app/form';
import { processDataSourceOpts, setDefaultDataSourceConfig } from 'baas-ui/home/create-app/form/util';
import { CreateAppFormState, TemplateIdentifier } from 'baas-ui/home/create-app/types';
import { useDarkMode } from 'baas-ui/theme';
import { track, usePageLoadTracker } from 'baas-ui/tracking';
import newAppDarkPNG from 'baas-static/images/newapp-dark.png';
import newAppLightPNG from 'baas-static/images/newapp-light.png';

import 'baas-ui/common/styles/banner.less';
import './activate-app-card.less';

const baseClassName = 'activate-app-card';
const createAppClassName = `${baseClassName}-create-app`;

type FooterPropsStates = {
  [key in CreateAppFormState]?: FooterProps;
};

export enum TestSelector {
  ActivateAppCardForm = 'activate-app-card-form',
  ActivateAppCardSubtitle = 'activate-app-card-subtitle',
  ActivateAppCardTitle = 'activate-app-card-title',
  CreateAppButton = 'create-app-button',
  NextButton = 'next-button',
  CreateAppErrorBanner = 'create-app-error-banner',
  LinkAndGetStartedButton = 'link-and-get-started-button',
}

interface PublicProps {
  showUserPermissionsBanner?: boolean;
  // needed so the poller is available in withCreateApp when creating an app from this component
  clusterStatePoller: ReturnType<typeof usePoller>;
}
export type Props = PublicProps & WithCreateAppProps;

export const ActivateAppCardComponent = ({ createAppProps, showUserPermissionsBanner }: Props) => {
  const {
    appNameError,
    clusterOpts,
    createAppError,
    dataLakeOpts,
    dataSourceError,
    dataSourceLinkMethod,
    groupId,
    handleCreateApp,
    isCreatingApp,
    onlineArchiveOpts,
    serverlessInstanceOpts,
    setDataSourceLinkMethod,
    setIsUserSelectedProviderRegion,
    setSelectedDataSource,
    templateId,
  } = createAppProps;

  usePageLoadTracker('APPLICATION.LIST_EMPTY_STATE_VIEWED');
  const darkMode = useDarkMode();
  const [isProviderRegionConfirmed, setIsProviderRegionConfirmed] = React.useState(true);
  const [isFirstGroupApp, setIsFirstGroupApp] = useLocalStorage(isFirstAppCreatedOnGroup(groupId), true);

  const [createAppFormState, setCreateAppFormState] = React.useState<CreateAppFormState>(CreateAppFormState.Templates);

  const {
    m0ClusterOpt,
    enabledClusterOpts,
    enabledDataLakeOpts,
    enabledOnlineArchiveOpts,
    enabledServerlessInstanceOpts,
    numEnabledDataSources,
  } = processDataSourceOpts({ clusterOpts, dataLakeOpts, onlineArchiveOpts, serverlessInstanceOpts, templateId });

  React.useEffect(() => {
    setDefaultDataSourceConfig({
      m0ClusterOpt,
      enabledClusterOpts,
      enabledDataLakeOpts,
      enabledOnlineArchiveOpts,
      enabledServerlessInstanceOpts,
      setSelectedDataSource,
      dataSourceLinkMethod,
      setDataSourceLinkMethod,
    });

    // createAppError is included in the dep array to avoid having stale info after app creation fails and
    // we refetch the data sources in withCreateApp
  }, [clusterOpts, dataLakeOpts, serverlessInstanceOpts, createAppError, templateId]);

  const createAppButton = (
    <Button
      data-testid={TestSelector.CreateAppButton}
      data-cy="create-app-submit-button"
      disabled={!isProviderRegionConfirmed || !!appNameError || isCreatingApp}
      variant={Variant.Primary}
      onClick={() => handleCreateApp().then(() => setIsFirstGroupApp(false))}
    >
      Create App Service
    </Button>
  );

  const footerProps: FooterPropsStates = {
    [CreateAppFormState.Templates]: {
      showRightButtonSpinner: isCreatingApp,
      rightButton: (
        <Button
          data-testid={TestSelector.NextButton}
          data-cy="create-app-next-button"
          variant={Variant.Primary}
          onClick={() => {
            if (isFirstGroupApp) {
              // Display the Link Data Sources Section if the user has multiple clusters, OR if the user builds
              // their own app and has more than 1 of any data source (template apps are not compatible with
              // federated database and serverless instances)
              if (
                enabledClusterOpts.length > 1 ||
                (templateId === TemplateIdentifier.Default && numEnabledDataSources > 1)
              ) {
                setCreateAppFormState(CreateAppFormState.LinkDataSource);
                track('APPLICATION.TEMPLATE_MODAL_NEXT_CLICKED', {
                  templateId,
                  numClusters: enabledClusterOpts.length,
                });
              } else {
                handleCreateApp().then(() => setIsFirstGroupApp(false));
              }
            } else {
              setCreateAppFormState(CreateAppFormState.Config);
              track('APPLICATION.TEMPLATE_MODAL_NEXT_CLICKED', { templateId });
            }
          }}
        >
          Next
        </Button>
      ),
    },
    [CreateAppFormState.LinkDataSource]: {
      showRightButtonSpinner: isCreatingApp,
      leftButton: (
        <Button
          className={`${createAppClassName}-back-button`}
          onClick={() => setCreateAppFormState(CreateAppFormState.Templates)}
        >
          Back
        </Button>
      ),
      rightButton: (
        <Button
          data-testid={TestSelector.LinkAndGetStartedButton}
          data-cy="save-data-source-button"
          variant={Variant.Primary}
          onClick={() => handleCreateApp().then(() => setIsFirstGroupApp(false))}
        >
          Link & Get Started
        </Button>
      ),
    },
    [CreateAppFormState.Config]: {
      showRightButtonSpinner: isCreatingApp,
      leftButton: (
        <Button data-cy="create-app-back-button" onClick={() => setCreateAppFormState(CreateAppFormState.Templates)}>
          Back
        </Button>
      ),
      rightButton: createAppButton,
    },
  };

  return (
    <div className={`${baseClassName}-container`}>
      <img
        className={`${baseClassName}-image`}
        src={darkMode ? newAppDarkPNG : newAppLightPNG}
        alt="new app background"
      />
      <Card className={`${baseClassName}-content`} data-cy="activate-app-card">
        <div className={createAppClassName}>
          {showUserPermissionsBanner && (
            <AppsUserPermissionsBanner className={`${createAppClassName}-permissions-banner`} />
          )}
          <H3 data-testid={TestSelector.ActivateAppCardTitle} className={`${createAppClassName}-title`}>
            Build better apps faster with App Services
          </H3>
          <div data-testid={TestSelector.ActivateAppCardSubtitle} className={`${createAppClassName}-subtitle`}>
            <Body>
              Seamlessly connect your data to mobile apps, websites, and other services with our fully managed services
              and APIs.{' '}
              <DocLink href={docLinks.General.AppServices} showExternalIcon>
                Learn more about App Services
              </DocLink>
            </Body>
          </div>
          <div
            data-testid={TestSelector.ActivateAppCardForm}
            className={classNames(`${createAppClassName}-form`, {
              [`${createAppClassName}-form-templates`]: createAppFormState === CreateAppFormState.Config,
            })}
          >
            {createAppError && (
              <Banner
                className="banner-margin-bottom"
                data-testid={TestSelector.CreateAppErrorBanner}
                data-cy="create-app-error-banner"
                variant={Variant.Danger}
              >
                {createAppError === CreateAppErrors.Forbidden ? (
                  <>
                    {`${createAppError} Creating an App Service requires the `}
                    <DocLink href={docLinks.Atlas.UserRoles}>Project Owner</DocLink>
                    {` role.`}
                  </>
                ) : (
                  createAppError
                )}
              </Banner>
            )}
            {!!dataSourceError && (
              <Banner className={`${createAppClassName}-error-banner`} variant="danger">
                {dataSourceError}
              </Banner>
            )}
            <CreateAppForm
              {...createAppProps}
              formState={createAppFormState}
              onChangeDeploymentModel={() => setIsProviderRegionConfirmed(true)}
              onCancelProviderRegion={() => setIsProviderRegionConfirmed(true)}
              onConfirmProviderRegion={() => {
                setIsProviderRegionConfirmed(true);
                setIsUserSelectedProviderRegion(true);
              }}
              onChangeProviderRegion={() => setIsProviderRegionConfirmed(false)}
            />
          </div>
          <CreateAppFooter
            className={`${createAppClassName}-footer`}
            {...(footerProps[createAppFormState!] || {
              showRightButtonSpinner: isCreatingApp,
              rightButton: createAppButton,
            })}
          />
        </div>
      </Card>
    </div>
  );
};

const { wrappedComponent: WrappedActivateAppCard } = withCreateApp(ActivateAppCardComponent);
export default WrappedActivateAppCard;
