import { Any, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';

import createStringMapSerializers from 'admin-sdk/api/common/createStringMapSerializers';

@JsonObject('Relationship')
export class Relationship {
  @JsonProperty('ref')
  public ref = '';

  @JsonProperty('foreign_key')
  public foreignKey = '';

  @JsonProperty('is_list')
  public isList = false;

  constructor(partial?: Partial<Relationship>) {
    Object.assign(this, partial);
  }
}

const relationshipMapSerializers = createStringMapSerializers(Relationship);
@JsonConverter
export class RelationshipMapConverter implements JsonCustomConvert<Record<string, Relationship>> {
  public serialize = relationshipMapSerializers.serialize;

  public deserialize = relationshipMapSerializers.deserialize;
}

@JsonObject('Schema')
export class Schema {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('schema', Any, true)
  public schema?: Record<string, any> = {};

  @JsonProperty('metadata', Any, true)
  public metadata?: Record<string, any> = {};

  @JsonProperty('relationships', RelationshipMapConverter, true)
  public relationships?: Record<string, Relationship> = undefined;

  constructor(partial?: Partial<Schema>) {
    Object.assign(this, partial);
  }
}

@JsonObject('PartialSchema')
export class PartialSchema {
  @JsonProperty('_id')
  public id = '';

  @JsonProperty('metadata', Any, true)
  public metadata?: Record<string, any> = {};

  constructor(partial?: Partial<Schema>) {
    Object.assign(this, partial);
  }
}

@JsonObject('GenerateSchemasRequest')
export class GenerateSchemasRequest {
  @JsonProperty('data_source_id', String)
  public dataSourceId = '';

  @JsonProperty('generate_empty_schemas', Boolean)
  public generateEmptySchemas = false;

  @JsonProperty('all_schemas', Boolean)
  public allSchemas = false;

  @JsonProperty('all_unconfigured_schemas', Boolean)
  public allUnconfiguredSchemas = false;

  @JsonProperty('limit', Number, true)
  public limit?: number = undefined;

  constructor(partial?: Partial<GenerateSchemasRequest>) {
    Object.assign(this, partial);
  }
}

@JsonObject('SyncSchemaError')
export class SyncSchemaError {
  @JsonProperty('service_id', String)
  public serviceId = '';

  @JsonProperty('service_name', String)
  public serviceName = '';

  @JsonProperty('schema_id', String)
  public schemaId = '';

  @JsonProperty('database', String)
  public database = '';

  @JsonProperty('collection', String)
  public collection = '';

  @JsonProperty('message', String)
  public message = '';

  constructor(partial?: Partial<SyncSchemaError>) {
    Object.assign(this, partial);
  }
}

@JsonObject('GetSyncSchemasErrorResponse')
export class GetSyncSchemasErrorResponse {
  @JsonProperty('errors', [SyncSchemaError], true)
  public errors?: SyncSchemaError[] = [];

  @JsonProperty('warnings', [SyncSchemaError], true)
  public warnings?: SyncSchemaError[] = [];

  constructor(partial?: Partial<GetSyncSchemasErrorResponse>) {
    Object.assign(this, partial);
  }
}
