var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Any, JsonObject, JsonProperty } from 'json2typescript';
let ValuesByEnvironment = class ValuesByEnvironment {
    none = undefined;
    development = undefined;
    testing = undefined;
    staging = undefined;
    qa = undefined;
    production = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('none', Any, true)
], ValuesByEnvironment.prototype, "none", void 0);
__decorate([
    JsonProperty('development', Any, true)
], ValuesByEnvironment.prototype, "development", void 0);
__decorate([
    JsonProperty('testing', Any, true)
], ValuesByEnvironment.prototype, "testing", void 0);
__decorate([
    JsonProperty('staging', Any, true)
], ValuesByEnvironment.prototype, "staging", void 0);
__decorate([
    JsonProperty('qa', Any, true)
], ValuesByEnvironment.prototype, "qa", void 0);
__decorate([
    JsonProperty('production', Any, true)
], ValuesByEnvironment.prototype, "production", void 0);
ValuesByEnvironment = __decorate([
    JsonObject('ValuesByEnvironment')
], ValuesByEnvironment);
export { ValuesByEnvironment };
let EnvironmentValue = class EnvironmentValue {
    id = '';
    name = '';
    values = new ValuesByEnvironment();
    lastModified = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('_id')
], EnvironmentValue.prototype, "id", void 0);
__decorate([
    JsonProperty('name')
], EnvironmentValue.prototype, "name", void 0);
__decorate([
    JsonProperty('values', ValuesByEnvironment, {})
], EnvironmentValue.prototype, "values", void 0);
__decorate([
    JsonProperty('last_modified', Number, true)
], EnvironmentValue.prototype, "lastModified", void 0);
EnvironmentValue = __decorate([
    JsonObject('EnvironmentValue')
], EnvironmentValue);
export { EnvironmentValue };
let PartialEnvironmentValue = class PartialEnvironmentValue {
    id = '';
    name = '';
    lastModified = undefined;
    constructor(partial) {
        Object.assign(this, partial);
    }
};
__decorate([
    JsonProperty('_id')
], PartialEnvironmentValue.prototype, "id", void 0);
__decorate([
    JsonProperty('name')
], PartialEnvironmentValue.prototype, "name", void 0);
__decorate([
    JsonProperty('last_modified', Number, true)
], PartialEnvironmentValue.prototype, "lastModified", void 0);
PartialEnvironmentValue = __decorate([
    JsonObject('PartialEnvironmentValue')
], PartialEnvironmentValue);
export { PartialEnvironmentValue };
