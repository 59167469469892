import { JsonObject, JsonProperty } from 'json2typescript';

import { ProviderRegion } from 'admin-sdk/api/common/App';

@JsonObject('ProviderPrivateEndpointConfig')
export class ProviderPrivateEndpointConfig {
  @JsonProperty('_id', String, true)
  public id?: string = undefined;

  @JsonProperty('endpoint_type', String)
  // Empty endpointType indicates a legacy endpoint
  // TODO(BAAS-29271): Make endpointType required and of type PrivateEndpointType
  public endpointType?: string = '';

  @JsonProperty('cloud_provider_endpoint_id', String)
  public cloudProviderEndpointID = '';

  @JsonProperty('cloud_provider_region', String)
  public cloudProviderRegion: ProviderRegion = ProviderRegion.AWSProviderRegionUSEast1;

  @JsonProperty('comment', String, true)
  public comment? = '';

  constructor(partial?: Partial<ProviderPrivateEndpointConfig>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AllowNonVPCClientRequests')
export class AllowNonVPCClientRequests {
  @JsonProperty('allow_non_vpc_client_requests', Boolean)
  public allowNonVPCRequests = false;

  constructor(partial?: Partial<AllowNonVPCClientRequests>) {
    Object.assign(this, partial);
  }
}
