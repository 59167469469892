import { createAction } from 'redux-act';
import { createActionsAndExecutor } from 'baas-ui/redux_util';
import { SendNotificationRequest } from 'admin-sdk';
const NAME = 'push/';
export const setNewMessageError = createAction(`${NAME}set msg error`);
export const [sendPushNotificationActions, sendPushNotification] = createActionsAndExecutor(`${NAME}send push notification`, (client, { groupId, appId, messageId }) => () => client.apps(groupId).app(appId).pushNotifications().pushNotification(messageId).send());
export const [createMessageActions, createMessage] = createActionsAndExecutor(`${NAME}create message`, (client, { groupId, appId, message }) => () => client.apps(groupId).app(appId).pushNotifications().create(new SendNotificationRequest(message)));
export const [saveMessageActions, saveMessage] = createActionsAndExecutor(`${NAME}save message`, (client, { groupId, appId, messageId, message }) => () => client
    .apps(groupId)
    .app(appId)
    .pushNotifications()
    .pushNotification(messageId)
    .update(new SendNotificationRequest(message)));
export const [deleteMessageActions, deleteMessage] = createActionsAndExecutor(`${NAME}delete message`, (client, { groupId, appId, messageId }) => () => client.apps(groupId).app(appId).pushNotifications().pushNotification(messageId).remove());
export const [loadMessagesActions, loadMessages] = createActionsAndExecutor(`${NAME}load messages`, (client, { groupId, appId, state }) => () => client.apps(groupId).app(appId).pushNotifications().list(state));
export const asyncEditRcvActions = [createMessageActions.rcv, saveMessageActions.rcv, deleteMessageActions.rcv];
