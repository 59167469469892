import { createReducer } from 'redux-act';

import { CreateAppErrors } from 'baas-ui/home/constants';
import { ClusterState } from 'baas-ui/home/types';

import * as actions from './actions';
import { AppsStoreState, ClusterProvisionToastState, RequestError } from './types';

export const defaultState: AppsStoreState = {
  groupId: '',
  apps: [],
  loading: false,
  showNewAppModal: false,
  createAppError: CreateAppErrors.None,
  dataSourceBuildError: RequestError.NoError,
  error: RequestError.NoError,
  clusterProvisionToastState: ClusterProvisionToastState.None,
};

const reducer = createReducer<AppsStoreState>({}, defaultState);
// TODO just use local state, not redux, for modal open/close state.
reducer.on(actions.showNewAppModal, (state) => ({
  ...state,
  showNewAppModal: true,
}));

reducer.on(actions.hideNewAppModal, (state) => ({
  ...state,
  showNewAppModal: false,
  createAppError: '',
}));

reducer.on(actions.loadAppsActions.req, (state) => ({
  ...state,
  loading: true,
}));

reducer.on(actions.loadAppsActions.rcv, (state, { payload = [] }) => ({
  ...state,
  loading: false,
  apps: payload,
  error: RequestError.NoError,
}));

reducer.on(actions.loadAppsActions.fail, (state, { error }) => ({
  ...state,
  loading: false,
  error,
}));

reducer.on(actions.createAppActions.req, (state) => ({
  ...state,
  creatingApp: true,
}));

reducer.on(actions.createAppActions.rcv, (state) => ({
  ...state,
  creatingApp: false,
  createAppError: CreateAppErrors.None,
}));

reducer.on(actions.createAppActions.fail, (state, { error }) => ({
  ...state,
  creatingApp: false,
  createAppError: error,
}));

reducer.on(actions.setCreateAppError, (state, createAppError) => ({
  ...state,
  createAppError,
}));

reducer.on(actions.linkClusterForNewAppActions.fail, (state, { error }) => ({
  ...state,
  dataSourceBuildError: `Error linking cluster: ${error}`,
  clusterProvisionToastState: ClusterProvisionToastState.Error,
}));

reducer.on(actions.linkAtlasDataLakeForNewAppActions.fail, (state, { error }) => ({
  ...state,
  dataSourceBuildError: error,
}));

reducer.on(actions.getNewAppClusterStateActions.rcv, (state, { payload }) => {
  if (state.clusterProvisionToastState === ClusterProvisionToastState.Error && state.dataSourceBuildError !== '') {
    // We want to continue showing the error toast even if other successful responses come in.
    return state;
  }

  if (payload.state) {
    let newToastState = state.clusterProvisionToastState;
    let newDataSourceBuildError = state.dataSourceBuildError;
    switch (payload.state as ClusterState) {
      case ClusterState.Creating:
        newToastState = ClusterProvisionToastState.CreatingCluster;
        break;
      case ClusterState.Deleted:
      case ClusterState.Deleting:
        newToastState = ClusterProvisionToastState.Error;
        newDataSourceBuildError = 'Cluster was deleted unexpectedly.';
        break;
      default:
        // Cluster was created successfully
        newToastState = ClusterProvisionToastState.CreatingSucceeded;
    }

    return {
      ...state,
      clusterProvisionToastState: newToastState,
      dataSourceBuildError: newDataSourceBuildError,
    };
  }

  return state;
});

reducer.on(actions.getNewAppClusterStateActions.fail, (state, { error }) => ({
  ...state,
  dataSourceBuildError: `Error fetching cluster state: ${error}`,
  clusterProvisionToastState: ClusterProvisionToastState.Error,
}));

reducer.on(actions.verifyRecaptchaActions.fail, (state, { error }) => ({
  ...state,
  clusterProvisionToastState: ClusterProvisionToastState.Error,
  dataSourceBuildError: `Error creating cluster: ${error}`,
}));

reducer.on(actions.clearDataSourceErrors, (state, _) => ({
  ...state,
  dataSourceBuildError: RequestError.NoError,
}));

reducer.on(actions.clearClusterProvisionToastState, (state, _) => ({
  ...state,
  clusterProvisionToastState: ClusterProvisionToastState.None,
}));

reducer.on(actions.deleteAppActions.req, (state) => ({ ...state, deletingApp: true }));

reducer.on(actions.deleteAppActions.rcv, (state) => ({ ...state, deletingApp: false }));

reducer.on(actions.deleteAppActions.fail, (state, { error }) => ({
  ...state,
  deletingApp: false,
  error,
}));

reducer.on(actions.setGroupId, (state, groupId) => ({
  ...state,
  groupId,
}));

export default reducer;
