export const MAX_RESOURCE_NAME_LENGTH = 64;
export const MAX_FUNCTION_NAME_LENGTH = 128;

export const STATUS_TYPE_SERVICE = 'SERVICE';
export const STATUS_TYPE_USER_PERMISSIONS = 'USER_PERMISSIONS';
export const STATUS_TYPE_LOCAL_STORAGE = 'LOCAL_STORAGE';

export const GROUP_OWNER = 'GROUP_OWNER';

export enum ErrorCode {
  DestructiveChangeError = 'DestructiveChangeNotAllowed',
  InvalidSyncSchemaError = 'InvalidSyncSchema',
  SyncProtocolIncreaseError = 'SyncProtocolVersionIncrease',
  DestructiveSyncProtocolIncreaseError = 'DestructiveSyncProtocolVersionIncrease',
  SyncIncompatibleRoleError = 'SyncIncompatibleRole',
  SyncSchemaVersionIncreaseError = 'SyncSchemaVersionIncrease',
  SyncAdditiveSchemaChange = 'SyncAdditiveSchemaChange',
}

export const MONGODB_SYNC_MIN_VERSION = '4.4.0';
export const MONGODB_FLEXIBLE_SYNC_MIN_VERSION = '5.0.0';

export const SERVERLESS_INSTANCE_SIZE = 'SERVERLESS_V2';
