import { Any, JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';

import createStringMapSerializers from 'admin-sdk/api/common/createStringMapSerializers';
import { DateConverter } from 'admin-sdk/Converter';

export enum ResourceType {
  Database = 'DATABASE',
  AuthEvent = 'AUTHENTICATION',
  Scheduled = 'SCHEDULED',
  SyncTranslator = 'SYNCTRANSLATOR',
  SyncPublisher = 'SYNCPUBLISHER',
}

export enum EventProcessorType {
  AWSEventBridge = 'AWS_EVENTBRIDGE',
  Function = 'FUNCTION',
}

export enum AuthActionType {
  Create = 'CREATE',
  Login = 'LOGIN',
  Delete = 'DELETE',
}

export enum OperationType {
  Insert = 'INSERT',
  Update = 'UPDATE',
  Replace = 'REPLACE',
  Delete = 'DELETE',
  CreateCollection = 'CREATE_COLLECTION',
  ModifyCollection = 'MODIFY_COLLECTION',
  RenameCollection = 'RENAME_COLLECTION',
  ShardCollection = 'SHARD_COLLECTION',
  DropCollection = 'DROP_COLLECTION',
  CreateIndexes = 'CREATE_INDEXES',
  ReshardCollection = 'RESHARD_COLLECTION',
  RefineCollectionShardKey = 'REFINE_COLLECTION_SHARD_KEY',
  DropIndexes = 'DROP_INDEXES',
  DropDatabase = 'DROP_DATABASE',
}

export type EventProcessorConfig = FunctionEventProcessorConfig | AWSEventBridgeEventProcessorConfig | {};

@JsonObject('FunctionEventProcessorConfig')
export class FunctionEventProcessorConfig {
  @JsonProperty('function_id', String, true)
  public functionId = '';

  @JsonProperty('function_name', String, true)
  public functionName = '';

  constructor(partial?: Partial<FunctionEventProcessorConfig>) {
    Object.assign(this, partial);
  }
}

const jsonConvert: JsonConvert = new JsonConvert();

@JsonObject('AWSEventBridgeEventProcessorConfig')
export class AWSEventBridgeEventProcessorConfig {
  @JsonProperty('account_id', String, true)
  public accountID = '';

  @JsonProperty('region', String, true)
  public region = '';

  @JsonProperty('extended_json_enabled', Boolean, true)
  public useExtendedJSON = false;

  constructor(partial?: Partial<AWSEventBridgeEventProcessorConfig>) {
    Object.assign(this, partial);
  }
}

@JsonConverter
class EventProcessorConfigConverter implements JsonCustomConvert<EventProcessorConfig> {
  // eslint-disable-next-line class-methods-use-this
  public serialize = (config: EventProcessorConfig): Record<string, any> => {
    if ('functionId' in config) {
      return jsonConvert.serialize(config, FunctionEventProcessorConfig);
    }
    if ('region' in config) {
      return jsonConvert.serialize(config, AWSEventBridgeEventProcessorConfig);
    }
    return {};
  };

  // eslint-disable-next-line class-methods-use-this
  public deserialize = (doc: Record<string, any>): EventProcessorConfig => {
    if ('function_id' in doc) {
      return jsonConvert.deserialize(doc, FunctionEventProcessorConfig) as FunctionEventProcessorConfig;
    }
    if ('region' in doc) {
      return jsonConvert.deserialize(doc, AWSEventBridgeEventProcessorConfig) as AWSEventBridgeEventProcessorConfig;
    }
    return new FunctionEventProcessorConfig();
  };
}

@JsonObject('EventProcessor')
export class EventProcessor {
  @JsonProperty('config', EventProcessorConfigConverter)
  public config: EventProcessorConfig = {};

  constructor(partial?: Partial<EventProcessor>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AWSEventBridgeEventProcessor')
export class AWSEventBridgeEventProcessor extends EventProcessor {
  @JsonProperty('config')
  public config: AWSEventBridgeEventProcessorConfig = new AWSEventBridgeEventProcessorConfig();

  constructor(partial?: Partial<AWSEventBridgeEventProcessor>) {
    super();
    Object.assign(this, partial);
  }
}

@JsonObject('FunctionEventProcessor')
export class FunctionEventProcessor extends EventProcessor {
  @JsonProperty('config', FunctionEventProcessorConfig)
  public config: FunctionEventProcessorConfig = new FunctionEventProcessorConfig();

  constructor(partial?: Partial<FunctionEventProcessor>) {
    super();
    Object.assign(this, partial);
  }
}

const eventProcessorMapSerializers = createStringMapSerializers(EventProcessor);
@JsonConverter
class EventProcessorMapConverter implements JsonCustomConvert<Record<string, EventProcessor>> {
  public serialize = eventProcessorMapSerializers.serialize;

  public deserialize = eventProcessorMapSerializers.deserialize;
}

@JsonObject('EventSubscriptionResumeOptions')
export class EventSubscriptionResumeOptions {
  @JsonProperty('disable_token')
  public disableToken = false;

  constructor(partial?: Partial<EventSubscriptionResumeOptions>) {
    Object.assign(this, partial);
  }
}

@JsonObject('AuthEventSubscriptionConfig')
export class AuthEventSubscriptionConfig {
  @JsonProperty('operation_type')
  public actionType = '';

  @JsonProperty('providers')
  public providers: string[] = [];

  constructor(partial?: Partial<AuthEventSubscriptionConfig>) {
    Object.assign(this, partial);
  }
}

@JsonObject('DatabaseEventSubscriptionConfig')
export class DatabaseEventSubscriptionConfig {
  @JsonProperty('operation_types')
  public operationTypes: Array<OperationType> = [];

  @JsonProperty('database')
  public database = '';

  @JsonProperty('collection')
  public collection = '';

  @JsonProperty('service_id')
  public serviceId = '';

  @JsonProperty('match')
  public match = {};

  @JsonProperty('project')
  public project = {};

  @JsonProperty('full_document')
  public fullDocument = false;

  @JsonProperty('full_document_before_change')
  public fullDocumentBeforeChange = false;

  @JsonProperty('unordered')
  public unordered = false;

  @JsonProperty('skip_catchup_events')
  public skipCatchUpEvents = false;

  @JsonProperty('tolerate_resume_errors')
  public autoResume = false;

  @JsonProperty('maximum_throughput', Boolean, true)
  public maximumThroughput = false;

  constructor(partial?: Partial<DatabaseEventSubscriptionConfig>) {
    Object.assign(this, partial);
  }
}

@JsonObject('ScheduledEventSubscriptionConfig')
export class ScheduledEventSubscriptionConfig {
  @JsonProperty('schedule')
  public schedule = '';

  @JsonProperty('schedule_type')
  public scheduleType = '';

  @JsonProperty('skip_catchup_events')
  public skipCatchUpEvents = false;

  constructor(partial?: Partial<ScheduledEventSubscriptionConfig>) {
    Object.assign(this, partial);
  }
}

@JsonObject('TranslatorEventSubscriptionConfig')
export class TranslatorEventSubscriptionConfig {
  @JsonProperty('service_id')
  public serviceId = '';

  constructor(partial?: Partial<TranslatorEventSubscriptionConfig>) {
    Object.assign(this, partial);
  }
}

export type EventSubscriptionConfig =
  | AuthEventSubscriptionConfig
  | DatabaseEventSubscriptionConfig
  | ScheduledEventSubscriptionConfig
  | TranslatorEventSubscriptionConfig
  | {}
  | null;

@JsonConverter
class EventSubscriptionConfigConverter implements JsonCustomConvert<EventSubscriptionConfig> {
  // eslint-disable-next-line class-methods-use-this
  public serialize = (config: EventSubscriptionConfig): Record<string, any> => {
    if (!config) {
      return {};
    }
    if ('providers' in config) {
      return jsonConvert.serialize(config, AuthEventSubscriptionConfig);
    }
    if ('database' in config) {
      return jsonConvert.serialize(config, DatabaseEventSubscriptionConfig);
    }
    if ('schedule' in config) {
      return jsonConvert.serialize(config, ScheduledEventSubscriptionConfig);
    }
    if ('serviceId' in config) {
      return jsonConvert.serialize(config, TranslatorEventSubscriptionConfig);
    }
    return {};
  };

  // eslint-disable-next-line class-methods-use-this
  public deserialize = (doc: Record<string, any>): EventSubscriptionConfig => {
    if (!doc) {
      return null;
    }
    if ('providers' in doc) {
      return jsonConvert.deserialize(doc, AuthEventSubscriptionConfig) as AuthEventSubscriptionConfig;
    }
    if ('database' in doc) {
      return jsonConvert.deserialize(doc, DatabaseEventSubscriptionConfig) as DatabaseEventSubscriptionConfig;
    }
    if ('schedule' in doc) {
      return jsonConvert.deserialize(doc, ScheduledEventSubscriptionConfig) as ScheduledEventSubscriptionConfig;
    }
    if ('service_id' in doc) {
      return jsonConvert.deserialize(doc, TranslatorEventSubscriptionConfig) as TranslatorEventSubscriptionConfig;
    }
    return new AuthEventSubscriptionConfig();
  };
}

@JsonObject('ErrorHandlerConfig')
export class ErrorHandlerConfig {
  @JsonProperty('enabled', Boolean)
  public enabled = false;

  @JsonProperty('function_id', String)
  public functionId = '';

  constructor(partial?: Partial<ErrorHandlerConfig>) {
    Object.assign(this, partial);
  }
}

@JsonObject('ErrorHandler')
export class ErrorHandler {
  @JsonProperty('config', ErrorHandlerConfig, true)
  public config?: ErrorHandlerConfig = undefined;

  constructor(partial?: Partial<ErrorHandler>) {
    Object.assign(this, partial);
  }
}

@JsonObject('BaseEventSubscription')
export class BaseEventSubscription {
  @JsonProperty('_id', String, true)
  public id?: string = undefined;

  @JsonProperty('name')
  public name = '';

  @JsonProperty('type')
  public type: ResourceType = ResourceType.Database;

  @JsonProperty('function_id', String, true)
  public functionId?: string = undefined;

  @JsonProperty('function_name', String, true)
  public functionName?: string = undefined;

  @JsonProperty('disabled')
  public disabled = false;

  @JsonProperty('config', EventSubscriptionConfigConverter)
  public config: EventSubscriptionConfig = {};

  @JsonProperty('error', String, true)
  public error?: string = undefined;

  @JsonProperty('error_co_id', String, true)
  public errorCoId?: string = undefined;

  @JsonProperty('last_modified', Number, true)
  public lastModified?: number = undefined;

  @JsonProperty('event_processors', EventProcessorMapConverter, true)
  public eventProcessors?: { [K in EventProcessorType]?: EventProcessor } = undefined;

  @JsonProperty('error_handler', ErrorHandler, true)
  public errorHandler?: ErrorHandler = undefined;

  constructor(partial?: Partial<BaseEventSubscription>) {
    Object.assign(this, partial);
  }
}

@JsonObject('DatabaseEventSubscription')
export class DatabaseEventSubscription extends BaseEventSubscription {
  @JsonProperty('type')
  public type: ResourceType = ResourceType.Database;

  @JsonProperty('config', DatabaseEventSubscriptionConfig)
  public config: DatabaseEventSubscriptionConfig = new DatabaseEventSubscriptionConfig();

  constructor(partial?: Partial<DatabaseEventSubscription>) {
    super();
    Object.assign(this, partial);
  }
}

@JsonObject('AuthEventSubscription')
export class AuthEventSubscription extends BaseEventSubscription {
  @JsonProperty('type')
  public type: ResourceType = ResourceType.AuthEvent;

  @JsonProperty('config', AuthEventSubscriptionConfig)
  public config: AuthEventSubscriptionConfig = new AuthEventSubscriptionConfig();

  constructor(partial?: Partial<AuthEventSubscription>) {
    super();
    Object.assign(this, partial);
  }
}

@JsonObject('TranslatorEventSubscription')
export class TranslatorEventSubscription extends BaseEventSubscription {
  @JsonProperty('type')
  public type: ResourceType = ResourceType.SyncTranslator;

  @JsonProperty('config', TranslatorEventSubscriptionConfig)
  public config: TranslatorEventSubscriptionConfig = new TranslatorEventSubscriptionConfig();

  constructor(partial?: Partial<TranslatorEventSubscription>) {
    super();
    this.type = ResourceType.SyncTranslator;
    Object.assign(this, partial);
  }
}

@JsonObject('ScheduledEventSubscription')
export class ScheduledEventSubscription extends BaseEventSubscription {
  @JsonProperty('type')
  public type: ResourceType = ResourceType.Scheduled;

  @JsonProperty('config', ScheduledEventSubscriptionConfig)
  public config: ScheduledEventSubscriptionConfig = new ScheduledEventSubscriptionConfig();

  constructor(partial?: Partial<ScheduledEventSubscription>) {
    super();
    this.type = ResourceType.Scheduled;
    Object.assign(this, partial);
  }
}

export type EventSubscription = AuthEventSubscription | DatabaseEventSubscription | ScheduledEventSubscription;

@JsonObject('EventSubscriptionExecution')
export class EventSubscriptionExecution {
  @JsonProperty('data', Any, true)
  public data?: Record<string, any> = undefined;

  @JsonProperty('resource_id')
  public resourceId = '';

  @JsonProperty('completed_at', Number, true)
  public completedAt?: number = undefined;

  @JsonProperty('cluster_time', DateConverter, true)
  public clusterTime?: Date = undefined;

  constructor(partial?: Partial<EventSubscriptionExecution>) {
    Object.assign(this, partial);
  }
}
