import * as coreActions from 'baas-ui/actions';
import { addAlert } from 'baas-ui/alert';
import * as appActions from 'baas-ui/app/actions';
import * as homeActions from 'baas-ui/home/actions';
import urls from 'baas-ui/urls';
import { PartialServiceDesc, ServiceDesc } from 'admin-sdk';

import * as actions from './actions';
import { isDataLakeServiceType, isSelfHostedOrAtlasMongoService } from './registry';
import { Config } from './types';

export const createService = (groupId: string, appId: string, serviceDesc: ServiceDesc) => (dispatch) => {
  const loadAndReturnService = (service: PartialServiceDesc) =>
    dispatch(actions.loadServices({ groupId, appId })).then(() => service);

  return dispatch(actions.createService({ groupId, appId, serviceDesc }))
    .then(loadAndReturnService)
    .then((service: PartialServiceDesc) => {
      const baseUrl = urls.groups().group(groupId).apps().app(appId);
      if (isSelfHostedOrAtlasMongoService(service.type)) {
        dispatch(coreActions.redirectTo(baseUrl.clusters().cluster(service.id).rules().list()));
      } else if (service.type === 'gcm') {
        dispatch(coreActions.redirectTo(baseUrl.push().config()));
      } else if (isDataLakeServiceType(service.type)) {
        dispatch(coreActions.redirectTo(baseUrl.clusters().list()));
      } else {
        dispatch(coreActions.redirectTo(baseUrl.services().service(service.id).get()));
      }
      return service;
    });
};

export const updateServiceConfig = (groupId: string, appId: string, svcId: string, config: Config) => (dispatch) =>
  dispatch(actions.saveSvcConfig({ groupId, appId, svcId, config }))
    .then(() => dispatch(actions.loadSvcConfig({ groupId, appId, svcId })))
    .then(() => dispatch(addAlert(`${svcId}edit_config`, 'Saved')))
    .catch(() => {});

export const loadSvcWithConfig = (groupId: string, appId: string, svcId: string) => (dispatch) =>
  Promise.all([
    dispatch(actions.loadSvcConfig({ groupId, appId, svcId })),
    dispatch(actions.loadSvc({ groupId, appId, svcId })),
  ]);

export const loadMongoServiceConfigs = (groupId: string, appId: string) => (dispatch) => {
  const loadServices = () => dispatch(actions.loadServices({ groupId, appId }));
  const loadSvcConfig = (svcId: string) => dispatch(actions.loadSvcConfig({ groupId, appId, svcId }));

  return loadServices().then((svcs: PartialServiceDesc[]) =>
    Promise.all(
      svcs.reduce(
        (acc, svc) =>
          isSelfHostedOrAtlasMongoService(svc.type) || isDataLakeServiceType(svc.type)
            ? acc.concat(loadSvcConfig(svc.id))
            : acc,
        []
      )
    )
  );
};

export const loadLinkedDataSources = (groupId: string, appId: string) => (dispatch) => {
  return Promise.all([
    dispatch(homeActions.getAtlasClusters({ groupId })),
    dispatch(homeActions.getAtlasServerlessInstances({ groupId })),
    dispatch(loadMongoServiceConfigs(groupId, appId)),
  ]).then(([clusters, serverlessInstances, configs]) => {
    return [...clusters, ...serverlessInstances].filter((cluster) =>
      configs.some(({ config }) => config.clusterName === cluster.name)
    );
  });
};

export const deleteServiceAndRedirect = (groupId: string, appId: string, svcId: string) => (dispatch) =>
  dispatch(actions.deleteSvc({ groupId, appId, svcId }))
    .then(() => dispatch(appActions.loadApp({ groupId, appId })))
    .then(() => dispatch(actions.loadServices({ groupId, appId })))
    .then(() =>
      dispatch(
        coreActions.redirectTo(urls.groups().group(groupId).apps().app(appId).services().list(), { replace: true })
      )
    );
