import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { Body, Subtitle } from '@leafygreen-ui/typography';

import DocLink from 'baas-ui/common/components/doc-link';
import { LoadingWrapper } from 'baas-ui/common/components/loading-wrapper';
import { FeatureFlag } from 'baas-ui/common/featureSettings';
import { docLinks } from 'baas-ui/common/links';
import { useTimeZone } from 'baas-ui/common/timezone/timezone';
import * as measurementsActions from 'baas-ui/measurements/actions';
import { DEFAULT_MEASUREMENTS, MeasurementsByName, UsageByMeasurement } from 'baas-ui/measurements/types';
import useLegacyBillingPeriod from 'baas-ui/measurements/use-billing-period';
import { legacyMapMeasurementsByName } from 'baas-ui/measurements/utils';
import { BilledMetric, MetricUsagesByName } from 'baas-ui/metrics/types';
import useMetricBillingPeriod from 'baas-ui/metrics/useBillingPeriod';
import { BILLED_METRICS_DETAILS } from 'baas-ui/metrics/utils';
import { AsyncDispatch, AsyncExecutorPayload } from 'baas-ui/redux_util';
import { getHomeState } from 'baas-ui/selectors';
import { featureSettings } from 'baas-ui/stitch_ui';
import { RootState } from 'baas-ui/types';
import { MeasurementGroupGranularity, MeasurementName, MeasurementRequest, PartialApp } from 'admin-sdk';

import ContributionsModal, { TabOption } from './ContributionsModal';
import PricingChangeBanner from './PricingChangeBanner';
import UsageMetric from './UsageMetric';

import './apps-usage.less';

interface ReduxStateProps {
  groupId: string;
}

interface PublicProps {
  usageByMeasurement: UsageByMeasurement;
  apps: PartialApp[];
  groupTotalMetrics: MetricUsagesByName;
  appTotalMetrics: Record<string, MetricUsagesByName>;
  isLoadingMetrics: boolean;
}

interface ReduxDispatchProps {
  getGroupMeasurements: (
    filter?: MeasurementRequest
  ) => AsyncExecutorPayload<typeof measurementsActions.getGroupMeasurements>;
}

export type Props = ReduxStateProps & ReduxDispatchProps & PublicProps;

const StyledUsageHeader = styled.div`
  margin-bottom: 21px;
  display: flex;
  align-items: baseline;
`;

const StyledUsageText = styled(Body)`
  padding-left: 9px;
`;

const StyledLastUpdated = styled(Body)`
  padding-left: 9px;
`;

const StyledMetricsOuter = styled.div`
  margin-bottom: 32px;
  width: 760px;
  height: 110px;
`;

const StyledMetricsInner = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
`;

export const datetimeFormat = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'long' });

function AppsUsage({
  getGroupMeasurements,
  usageByMeasurement,
  groupId,
  groupTotalMetrics,
  appTotalMetrics,
  apps,
  isLoadingMetrics,
}: Props) {
  const isBillingMigrationEnabled = featureSettings.useFeatureSetting(FeatureFlag.BillingMigration);
  const isPricingChangeEnabled = featureSettings.useFeatureSetting(FeatureFlag.PricingChange);

  const { start, end, daysUntil } = useLegacyBillingPeriod(isPricingChangeEnabled);

  const { timeZoneId } = useTimeZone(groupId);
  const { today, lastUpdated, timeUntilEndOfDay } = useMetricBillingPeriod(timeZoneId);

  const [isLoadingLegacyMetrics, setIsLoadingLegacyMetrics] = React.useState(false);
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(TabOption.Requests);
  const [groupMetrics, setGroupMetrics] = React.useState<MeasurementsByName>();

  React.useEffect(() => {
    if (!isBillingMigrationEnabled) {
      setIsLoadingLegacyMetrics(true);
      getGroupMeasurements({ start: start.date, end: end.date, granularity: MeasurementGroupGranularity.Monthly })
        .then((res) => {
          if (res) {
            setGroupMetrics(legacyMapMeasurementsByName(res.measurements));
          }
        })
        .finally(() => setIsLoadingLegacyMetrics(false));
    }
  }, []);

  const openModalToTab = (tabOption: TabOption) => {
    setActiveTab(tabOption);
    setIsOpenModal(true);
  };

  const groupMeasurementsByName = groupMetrics || DEFAULT_MEASUREMENTS;

  const isLoadingUsage = isBillingMigrationEnabled ? isLoadingMetrics : isLoadingLegacyMetrics;
  const usageText = isPricingChangeEnabled
    ? `${today}. Free Tier resets ${timeUntilEndOfDay}. `
    : `${start.short} - ${end.short}. Free Tier resets in ${daysUntil.nextBillingPeriod} days. `;

  return (
    <>
      {groupId && <PricingChangeBanner groupId={groupId} />}
      <StyledUsageHeader>
        <Subtitle>Total App Usage</Subtitle>
        <StyledUsageText>
          {usageText}
          <DocLink href={docLinks.General.Billing}>Learn more</DocLink>
        </StyledUsageText>
        <StyledLastUpdated>Last Updated: {lastUpdated}</StyledLastUpdated>
      </StyledUsageHeader>
      <StyledMetricsOuter>
        <LoadingWrapper isLoading={isLoadingUsage}>
          <StyledMetricsInner>
            <UsageMetric
              measurement={groupMeasurementsByName[MeasurementName.RequestCount]}
              usage={groupTotalMetrics[BilledMetric.RequestCount]}
              details={BILLED_METRICS_DETAILS[BilledMetric.RequestCount]}
              setModalOpen={() => openModalToTab(TabOption.Requests)}
            />
            <UsageMetric
              measurement={groupMeasurementsByName[MeasurementName.DataOut]}
              usage={groupTotalMetrics[BilledMetric.DataOut]}
              details={BILLED_METRICS_DETAILS[BilledMetric.DataOut]}
              setModalOpen={() => openModalToTab(TabOption.DataTransfer)}
            />
            <UsageMetric
              measurement={groupMeasurementsByName[MeasurementName.ComputeTime]}
              usage={groupTotalMetrics[BilledMetric.ComputeTime]}
              details={BILLED_METRICS_DETAILS[BilledMetric.ComputeTime]}
              setModalOpen={() => openModalToTab(TabOption.ComputeRuntime)}
            />
            <UsageMetric
              measurement={groupMeasurementsByName[MeasurementName.SyncTime]}
              usage={groupTotalMetrics[BilledMetric.SyncTime]}
              details={BILLED_METRICS_DETAILS[BilledMetric.SyncTime]}
              setModalOpen={() => openModalToTab(TabOption.SyncRuntime)}
            />
          </StyledMetricsInner>
        </LoadingWrapper>
      </StyledMetricsOuter>
      <ContributionsModal
        open={isOpenModal}
        lastUpdated={lastUpdated}
        initialTab={activeTab}
        setOpen={setIsOpenModal}
        usageByMeasurement={usageByMeasurement}
        groupMeasurementsByName={groupMeasurementsByName}
        apps={apps}
        groupTotalMetrics={groupTotalMetrics}
        appTotalMetrics={appTotalMetrics}
      />
    </>
  );
}

function mapStateToProps(state: RootState) {
  const { groupId } = getHomeState(state);
  return { groupId };
}

function mapDispatchToProps(dispatch: AsyncDispatch) {
  return {
    getGroupMeasurements: (groupId: string) => (filter?: MeasurementRequest) =>
      dispatch(
        measurementsActions.getGroupMeasurements({
          groupId,
          filter,
        })
      ),
  };
}

function mergeProps(
  stateProps: ReturnType<typeof mapStateToProps>,
  { getGroupMeasurements }: ReturnType<typeof mapDispatchToProps>,
  publicProps: PublicProps
) {
  return {
    ...stateProps,
    ...publicProps,
    getGroupMeasurements: getGroupMeasurements(stateProps.groupId),
  };
}

export { AppsUsage as AppsUsageComponent };
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AppsUsage);
